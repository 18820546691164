// ChatFooter.js
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {Box} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';

const ChatFooter = ({contact, websocket}) => {
    const { t } = useTranslation();
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {
        setNewMessage('');
    }, [contact]);


    const sendMessage = () => {
        if (!contact || !contact.id || !newMessage.trim()) {
            alert('Please enter a message');
            return;
        }

        if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
            const messageData = {
                action: 'send_message',
                content: newMessage.trim(),
                recipient: contact.id
            };
            websocket.current.send(JSON.stringify(messageData));
            setNewMessage('');
        } else {
            alert('Connection is not available');
        }
    };


    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (websocket.current) {
                sendMessage();
            }
        }
    };


    return (
        <Box className="card-footer" sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            border: 0,
            pt: 3,
            pb: 3,
            px: 4,
            alignItems: 'end',

        }}>
            <Box sx={{
                position: 'relative',
                width: '100%',
                mb: { xs: 3, sm: 0 },
            }}>
                <TextareaAutosize
                    value={newMessage}
                    className="form-control form-control-lg"
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                    style={{ paddingRight: '85px', resize: 'none' }}
                    placeholder={t('Type a message...')}
                    minRows={0}
                    maxRows={4}
                />
                {/* Иконка вложения
                <IconButton
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: '50%',
                        transform: 'translateY(-50%)'
                    }}
                    aria-label={t('Attachment')}
                >
                    <AttachFileIcon />
                </IconButton>*/}
            </Box>
            <IconButton
                color="primary"
                sx={{
                    ml: 2,
                    display: { xs: 'none', sm: 'flex'},
                    height: 'fit-content',
                    backgroundColor: 'transparent',
                }}
                onClick={sendMessage}
            >
                <SendIcon />
            </IconButton>
        </Box>
    );
};

export default ChatFooter;
