// ContactList.js

import React from 'react';
import {Link} from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import noAvatar from 'static/images/noavatar.jpeg';
import {useTranslation} from 'react-i18next';
import moment from "moment-timezone";
import {useAuth} from "../../../../contexts/AuthContext";

function ContactItem({contact, active, setSelectedContact}) {
    const {t} = useTranslation();
    const {timeZone, localeCode} = useAuth();

    const formatMessageTime = (datetime, timeZone, localeCode) => {
        // Установка локали и часового пояса
        moment.locale(localeCode);

        const messageTime = moment(datetime);
        const now = moment().tz(timeZone);

        // Проверка, является ли дата сообщения текущим днем
        if (messageTime.isSame(now, 'day')) {
            // Форматирование времени как 'HH:mm' если сегодня
            return messageTime.format('HH:mm');
        } else {
            // Форматирование как день недели (например, 'Пн', 'Вт') если другой день
            return messageTime.format('dd');
        }
    };

    function handleClick() {
        setSelectedContact(contact.dialogue_with);
        console.log(contact.dialogue_with);
    }

    return (

        <>
            <Link
                className={`d-flex align-items-start border-bottom text-decoration-none ${active ? 'position-relative bg-light pe-none py-3 px-4' : 'bg-faded-primary-hover py-3 px-4'} `}
                id={contact?.dialogue_with.id}
                onClick={handleClick}
            >

                {active && (<div className="position-absolute top-0 start-0 bg-primary w-2 h-100"></div>)}

                <Image
                    src={contact?.dialogue_with.profile_photo || noAvatar}
                    className="rounded-circle"
                    style={{
                        width: '48px', height: '48px', objectFit: 'cover'
                    }}
                    alt={contact?.dialogue_with.username}
                />

                <div className="w-100 ps-2 ms-1">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                        <h6 className="mb-0 me-2">{contact?.dialogue_with.username}</h6>
                        <span className="fs-xs text-muted">
                            {formatMessageTime(contact?.last_message_time, timeZone, localeCode)}
                        </span>
                    </div>
                    <p className="fs-sm text-body mb-0">
                        {contact?.last_message_content}
                    </p>
                </div>
            </Link>


        </>

    );
}

export default ContactItem;