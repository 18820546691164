// PageWrapper.js
import React from 'react';
import Navbar from "../navbar/Navbar";
import {Container} from '@mui/material';
import Footer from "../footer/footer";

const PageWrapper = ({children, showMenu = true, showFooter = true, bgLight, padding = 2, fluid = false }) => {

    return (
        <>
            {showMenu && <Navbar/>}

            <Container
                maxWidth={fluid ? false : "xl"}
                sx={{
                    flexGrow: 1,
                    padding: `${padding*8}px !important`,
                    bgcolor: bgLight ? 'background.paper' : 'inherit'
                }}
            >
                {children}
            </Container>

            {showFooter && <Footer/>}

        </>
    );
};

export default PageWrapper;
