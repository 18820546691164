// Navigation.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';

const Navigation = ({currentStep, totalSteps, nextStep, prevStep, questionType, answers, handleSubmit}) => {
    const isCheckboxQuestion = questionType === 'checkbox';
    const isAnyCheckboxSelected = isCheckboxQuestion && answers?.answer?.length > 0;
    const { t } = useTranslation();

    return (
        <div className="navigation-buttons d-flex justify-content-between mt-2">
            <div>
                {currentStep > 1 && (
                    <button className="btn btn-secondary" onClick={prevStep}>
                        {t('Back')}
                    </button>
                )}
            </div>

            <div>
                {currentStep < totalSteps && (
                    <>
                        {isCheckboxQuestion ? (
                            <button
                                className='btn btn-primary'
                                onClick={nextStep}
                                disabled={!isAnyCheckboxSelected}
                            >
                                {t('Next question')}
                            </button>
                        ) : (
                            <button className='btn btn-primary' onClick={nextStep}>
                                {t('Next question')}
                            </button>
                        )}
                    </>
                )}
                {currentStep === totalSteps && (
                    <button className="btn btn-primary" onClick={handleSubmit}>
                        {t('Get results')}
                    </button>
                )}
            </div>
        </div>
    );
};

export default Navigation;