import React, { useEffect, useState } from 'react';
import AccountWrap from 'components/wrap/AccountWrap.js';
import FaqList from './FaqList';
import {useTranslation} from 'react-i18next';

import customAxios from 'configs/axiosConfig';
import Loading from 'components/wrap/Loading';


const Faq = () => {
    const [faqs, setfaqs] = useState([]);
    const [loading, setloading] = useState(false);
    const {t} = useTranslation();


    useEffect(() => {
        const fetchConsultations = async () => {
            setloading(true);
            try {
                const response = await customAxios.get('https://api.method.do/api/main/faq/');
                setfaqs(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Ошибка при получении faq:', error);
            } finally {
                setloading(false);
            }
        };
        fetchConsultations();
    }, []);

    return (
        <AccountWrap>
            <div>
                <h3>{t('FAQs')}</h3>
                {loading ? (
                    <Loading message={t('Loading...')} />
                ) : (
                <FaqList faqs={faqs}/>
                )}
            </div>
        </AccountWrap>
    );
};

export default Faq;

