// index.js
import React from 'react';
import {createRoot} from "react-dom/client";

import {BrowserRouter as Router} from 'react-router-dom';
import {AuthProvider} from './contexts/AuthContext.js';
import {FunctionsProvider} from "./contexts/FunctionContext";
import {WebSocketProvider} from "./contexts/WebSocketContext";

import {ThemeProvider} from '@mui/material/styles';
import theme from './configs/theme';
import './configs/i18n';
import App from './app';



const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <Router>
            <AuthProvider>
                <ThemeProvider theme={theme}>
                        <WebSocketProvider>
                            <FunctionsProvider>
                                <App/>
                            </FunctionsProvider>
                        </WebSocketProvider>
                </ThemeProvider>
            </AuthProvider>
        </Router>
    </React.StrictMode>
);
