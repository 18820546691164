import React from 'react';
import {useAuth} from 'contexts/AuthContext';
import {Container, Typography} from '@mui/material';
import QuizViews from 'app/pages/tests/views/quizViews';
import MyPrograms from "../courses/Programs/MyPrograms";


const Home = () => {
    const {user} = useAuth();

    return (
        <>
            <Container maxWidth="xl" sx={{mt: 2, mb: 4}}>
                <Typography variant="h4" gutterBottom>
                    Привет, {user?.first_name}
                </Typography>
            </Container>

            <QuizViews/>

            <MyPrograms />
        </>
    );
}

export default Home;