import React, {useRef, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {useAuth} from 'contexts/AuthContext';
import customAxios from 'configs/axiosConfig';
import Loading from 'components/wrap/Loading';
import {Col} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import {useTheme} from '@mui/material/styles';
import {useMediaQuery} from "@mui/material";


const Sidebar = () => {
    const location = useLocation();
    const {user, updateUser} = useAuth();
    const theme = useTheme();
    const fileInputRef = useRef(null);
    const [loading, setloading] = useState(false);
    const {t} = useTranslation();
    const isXs = useMediaQuery(theme.breakpoints.down('md'));


    const handleImageUpload = (e) => {
        setloading(true);
        const file = e.target.files[0];
        if (file) {
            if (file.size > 10 * 1024 * 1024) {
                setloading(false);
                alert(t('File is too large. File size should not exceed 10 MB.'))
                return;
            }
            const formData = new FormData();
            formData.append('profile_photo', file);

            updateUserProfilePhoto(formData).then((updatedUser) => {
                updateUser(updatedUser);
            }).catch((error) => {
                console.error(error);
            });
        }
    };
    const updateUserProfilePhoto = async (formData) => {
        try {
            const response = await customAxios.post('https://api.method.do/api/user/upload_profile_photo/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;

        } catch (error) {
            throw error;
        } finally {
            setloading(false);
        }
    };

    return (
        <Col lg={3} md={4} className={'border-end pb-md-5 h-100'}>
            <div className="position-sticky top-0">
                <div className="text-center">

                    {!isXs && (
                        <>
                            <div className="d-table position-relative mx-auto mt-2 mt-lg-4 pt-5 mb-3">
                                {loading ? (
                                    <Loading/>
                                ) : (
                                    <>
                                        <img
                                            src={user.profile_photo ? `${user.profile_photo}` : '/images/noavatar.jpeg'}
                                            className="d-block rounded-circle"
                                            style={{
                                                width: '120px',
                                                height: '120px',
                                                objectFit: 'cover'
                                            }}
                                            alt={`${user.first_name} ${user.last_name}`}
                                        />
                                        <input type="file"
                                               ref={fileInputRef}
                                               style={{display: 'none'}}
                                               onChange={handleImageUpload}/>
                                        <button
                                            type="button"
                                            className="btn btn-icon btn-light bg-white btn-sm border rounded-circle shadow-sm position-absolute bottom-0 end-0 mt-4"
                                            title="Update profile photos"
                                            onClick={() => fileInputRef.current.click()}
                                        >
                                            <i className="bx bx-refresh"></i>
                                        </button>
                                    </>
                                )}
                            </div>

                            <h2 className="h5 mb-1">{`${user.first_name} ${user.last_name}`}</h2>
                            <p className="mb-3 pb-3">{user.email}</p>
                        </>
                    )}

                    <button type="button" className="btn btn-secondary w-100 d-md-none mt-n2 mb-3"
                            data-bs-toggle="collapse" data-bs-target="#account-menu">
                        <i className="bx bxs-user-detail fs-xl me-2"></i>
                        {t('Account menu')}
                        <i className="bx bx-chevron-down fs-lg ms-1"></i>
                    </button>
                    <div id="account-menu" className="list-group list-group-flush collapse d-md-block">
                        <NavLink to="/consultations"
                                 className={`list-group-item list-group-item-action d-flex align-items-center ${location.pathname === '/consultations' ? 'active' : ''}`}>
                            <i className="bx bx-chat fs-xl opacity-60 me-2"></i>
                            {t('Consultations')}
                        </NavLink>
                        <NavLink to="/history"
                                 className={`list-group-item list-group-item-action d-flex align-items-center ${location.pathname === '/history' ? 'active' : ''}`}>
                            <i className="bx bx-history fs-xl opacity-60 me-2"></i>
                            {t('Consultation history')}
                        </NavLink>
                        <NavLink to="/faq"
                                 className={`list-group-item list-group-item-action d-flex align-items-center ${location.pathname === '/faq' ? 'active' : ''}`}>
                            <i className="bx bx-purchase-tag fs-xl opacity-60 me-2"></i>
                            {t('FAQ')}
                        </NavLink>
                        <NavLink to="/orders"
                                 className={`list-group-item list-group-item-action d-flex align-items-center ${location.pathname === '/orders' ? 'active' : ''}`}>
                            <i className="bx bx-credit-card-front fs-xl opacity-60 me-2"></i>
                            {t('My orders')}
                        </NavLink>

                        <NavLink to="/historypayment"
                                 className={`list-group-item list-group-item-action d-flex align-items-center ${location.pathname === '/historypayment' ? 'active' : ''}`}>
                            <i className="bx bx-credit-card-front fs-xl opacity-60 me-2"></i>
                            {t('Payment history')}
                        </NavLink>
                        <NavLink to="/accountpayment"
                                 className={`list-group-item list-group-item-action d-flex align-items-center ${location.pathname === '/accountpayment' ? 'active' : ''}`}>
                            <i className="bx bx-credit-card-front fs-xl opacity-60 me-2"></i>
                            {t('Payment settings')}
                        </NavLink>
                        <NavLink to="/profile"
                                 className={`list-group-item list-group-item-action d-flex align-items-center ${location.pathname === '/profile' ? 'active' : ''}`}>
                            <i className="bx bx-cog fs-xl opacity-60 me-2"></i>
                            {t('Profile')}
                        </NavLink>
                    </div>
                </div>
            </div>
        </Col>)
        ;
};

export default Sidebar;
