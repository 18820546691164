// translations.js

const languages_dict = {
    'RU': 'Русский',
    'EN': 'English',
    'ES': 'Español',
    'FR': 'Français',
    'DE': 'Deutsch',
    'IT': 'Italiano',
    'PT': 'Português',
    'ZH': '中文',
    'JA': '日本語',
    'AR': 'العربية',
    'UK': 'Українська',
    'KK': 'Қазақ тілі'
}

 const marital_status_dict = {
    '': 'Выберите семейное положение',
    'single': 'Холост/Не замужем',
    'married': 'Женат/Замужем',
    'divorced': 'Разведен(а)',
    'widowed': 'Вдовец/Вдова',
    'civil_union': 'Гражданский брак',
    'complicated': 'В отношениях',
}

 const industry_dict = {
    '': 'Выберите сферу',
    'Blogging': 'Блогинг',
    'Freelance': 'Фриланс',
    'Infobusiness': 'Инфобизнес',
    'IT Business': 'IT бизнес',
    'Medicine': 'Медицина',
    'Finance': 'Финансы',
    'Construction': 'Строительство',
    'Education': 'Образование',
    'Tourism': 'Туризм',
    'Production': 'Производство',
    'Retail Trade': 'Розничная торговля',
    'Transport': 'Транспорт',
    'Energy': 'Энергетика',
    'Agriculture': 'Сельское хозяйство',
    'Real Estate': 'Недвижимость',
    'Marketing': 'Маркетинг',
    'Law': 'Юриспруденция',
    'Science': 'Наука',
    'Art': 'Искусство',
    'Media': 'СМИ',
    'Telecommunications': 'Телекоммуникации',
    'Hospitality': 'Гостеприимство',
    'Sports': 'Спорт',
    'Charity': 'Благотворительность',
    'Politics': 'Политика',
    'Security': 'Безопасность',
    'Logistics': 'Логистика',
    'Beauty and Cosmetics': 'Косметика и красота',
    'Entertainment': 'Развлечения',
    'Automotive Industry': 'Автомобильная промышленность',
    'Ecology': 'Экология',
    'Food Industry': 'Пищевая промышленность',
    'Fashion': 'Мода',
}

 const coachType_dict = {
    '': 'Выберите направление',
    'businessCoaching': 'Бизнес коучинг',
    'leadershipCoaching': 'Коучинг лидерства',
    'teamCoaching': 'Коучинг команды',
    'careerCoaching': 'Карьерный коучинг',
    'executiveCoaching': 'Коучинг топ-менеджеров',
    'performanceCoaching': 'Коучинг производительности',
    'lifeCoaching': 'Лайф коучинг',
    'personalGrowthCoaching': 'Коучинг личностного роста',
    'relationshipCoaching': 'Коучинг отношений',
    'healthCoaching': 'Коучинг здоровья',
    'financialCoaching': 'Финансовый коучинг',
    'parentingCoaching': 'Коучинг по родительству',
    'spiritualCoaching': 'Духовный коучинг'
}

 const coachQualification_dict = {
    '': 'Выберите квалификацию',
    'no': 'Нет квалификации',
    'acc': 'ASSOCIATE CERTIFIED COACH (ACC)',
    'pcc': 'PROFESSIONAL CERTIFIED COACH (PCC)',
    'mcc': 'MASTER CERTIFIED COACH (MCC)',
    'other': 'OTHER'
}

 const directions_dict = {
    '': 'Выберите методы работы',
    'Arttherapy': 'Арт-терапия',
    'Gestalttherapy': 'Гештальт-терапия',
    'Ericksonianhypnosis': 'Гипноз Эриксона',
    'DialecticalBehaviorTherapy': 'Диалектико-поведенческая терапия',
    'DynamicPsychotherapy': 'Динамическая психотерапия',
    'IntegrativePsychotherapy': 'Интегративная психотерапия',
    'CognitiveBehavioralTherapy': 'Когнитивно-поведенческая терапия',
    'Logotherapy': 'Логотерапия',
    'RogersMethod': 'Метод Роджерса',
    'MetaphoricalAssociativeCards': 'Метафорические ассоциативные карты',
    'MusicTherapy': 'Музыкотерапия',
    'NeurolinguisticProgramming': 'Нейролингвистическое программирование',
    'PositivePsychotherapy': 'Позитивная психотерапия',
    'Psychoanalysis': 'Психоанализ',
    'RationalEmotiveBehaviorTherapy': 'Рационально-эмоциональная терапия',
    'SystemicFamilyTherapy': 'Системная семейная терапия',
    'SchemaTherapy': 'Схематическая терапия',
    'Body-OrientedPsychotherapy': 'Телесно-ориентированная психотерапия',
    'TransactionalAnalysis': 'Транзактный анализ',
    'ExistentialPsychotherapy': 'Экзистенциальная психотерапия',
    'EmotionallyFocusedTherapy': 'Эмоционально-фокусированная терапия'
}


const status_dict = {
    'pending': 'Ожидает оплаты',
    'scheduled': 'Назначена',
    'conducted': 'Проведена',
    'moved': 'Перенесена',
    'waiting': 'Ожидает подтверждения',
    'cancelled_by_client': 'Отменена клиентом',
    'cancelled_by_specialist': 'Отменена специалистом',
    'cancelled_by_automatically': 'Отменена автоматически',
    'cancelled_by_admin': 'Отменена администратором',
    'unknown': 'Неизвестный статус'

}

const order_status_dict = {
    'created': 'Создан',
    'pending': 'Ожидает оплаты',
    'processing': 'Обрабатывается',
    'paid': 'Оплачено',
    'successful': 'Завершен',
    'failed': 'Оплата не прошла',
    'canceled': 'Отменен',
    'expired': 'Истек срок',
    'unknown': 'Неизвестный статус'

}

const transaction_status_dict = {
    'created': 'Создан',
    'pending': 'Ожидает оплаты',
    'processing': 'Обрабатывается',
    'paid': 'Оплачено',
    'successful': 'Завершен',
    'failed': 'Оплата не прошла',
    'canceled': 'Отменен',
    'expired': 'Истек срок',
    'unknown': 'Неизвестный статус'
}



// Создаем объект, который содержит все словари
export const dictionaries = {
    languages_dict,
    marital_status_dict,
    industry_dict,
    coachType_dict,
    coachQualification_dict,
    directions_dict,
    status_dict,
    order_status_dict,
    transaction_status_dict
};

export const getTranslation = (key, dictionary) => {
    return dictionary[key] || key;
};

export const TranslationClass = (selector) => {
    const elements = document.querySelectorAll(selector);

    elements.forEach(element => {
        const dataType = element.getAttribute('data-type');
        const dictionaryName = `${dataType}_dict`;
        const dictionary = dictionaries[dictionaryName];

        if (dictionary) {
            const key = element.textContent.trim();
            const translation = getTranslation(key, dictionary);
            element.textContent = translation;
        }
    });
};

export const
    Translate = ({ type, children }) => {
    const dictionaryName = `${type}_dict`;
    const dictionary = dictionaries[dictionaryName];

    if (dictionary && children) {
        return dictionary[children.trim()] || children;
    }

    return children;
};
