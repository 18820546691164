import React, { useState, useEffect } from 'react';
import AccountWrap from 'components/wrap/AccountWrap.js';
import customAxios from 'configs/axiosConfig';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment-timezone';
import { Box, Typography } from '@mui/material';
import { useAuth } from 'contexts/AuthContext';
import Skeleton from '@mui/material/Skeleton';
import {formatCurrency} from "utils/Currency";

const HistoryPayment = () => {
    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const { t } = useTranslation();
    const { timeZone } = useAuth();

    useEffect(() => {
        const fetchTransactions = async () => {
            setLoading(true);
            try {
                const response = await customAxios.get(`https://api.method.do/api/transactions/getall/`);
                setTransactions(response.data);
            } catch (error) {
                console.error(t('Failed to load history') + ':', error);
            } finally {
                setLoading(false);
            }
        };
        fetchTransactions();
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'transaction_id', headerName: t('Transaction ID'), width: 200 },
        { field: 'amount', headerName: t('Amount'), width: 110 },
        { field: 'currency', headerName: t('Currency'), width: 110, valueFormatter: ({ value }) => `${formatCurrency(value)}`  },
        { field: 'status', headerName: t('Status'), width: 150, valueFormatter: ({ value }) => t(value) },
        { field: 'created_at', headerName: t('Created At'), width: 180, valueFormatter: ({ value }) => moment(value).tz(timeZone).format('YYYY-MM-DD HH:mm:ss') },
        { field: 'description', headerName: t('Description'), width: 300, renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.5em', maxHeight: '4.5em' }}>
                    {params.value}
                </div>
            )},
        { field: 'payment_system', headerName: t('Payment System'), width: 150 },
        { field: 'order_id', headerName: t('Order ID'), width: 150 },
    ];

    return (
        <AccountWrap>
            <Box sx={{ height: 600, width: '100%' }}>
                {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={400} />
                ) : (
                    <>
                        <Typography variant="h4" gutterBottom>
                            {t('Payment history')}
                        </Typography>
                        <DataGrid
                            rows={transactions}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            checkboxSelection
                            disableSelectionOnClick
                        />
                    </>
                )}
            </Box>
        </AccountWrap>
    );
}

export default HistoryPayment;