//PaymentOrder.js
import React, {useEffect, useState, useContext} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import customAxios from 'configs/axiosConfig';
import Loading from 'components/wrap/Loading';
import {formatCurrency} from "utils/Currency";
import {Alert, Card, Col, Form, Row, Table} from 'react-bootstrap';
import {FunctionContext} from 'contexts/FunctionContext';
import {useTranslation} from "react-i18next";
import 'moment-timezone';
import moment from 'moment';
import {useAuth} from "contexts/AuthContext";
import PromoCodeInput from "../../../components/Orders/PromoCodeInput";
import CardBrand from "./components/CardBrand";

const PaymentOrder = () => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const {timeZone, localeCode} = useAuth();
    const [order, setOrder] = useState(null);
    const orderId = useParams().id;
    const [cards, setCards] = useState([]);
    const [cardId, setCardId] = useState('');
    const [Discount, setDiscount] = useState(null);
    const [OrderAmount, setOrderAmount] = useState(null);
    const [OrderAmountCurrency, setOrderAmountCurrency] = useState(null);
    const [promoCode, setPromoCode] = useState(null);
    const [convertedCost, setConvertedCost] = useState(OrderAmount);
    const [selectedCardCurrency, setSelectedCardCurrency] = useState(OrderAmountCurrency);
    const [totalCost, setTotalCost] = useState(OrderAmount);
    const navigate = useNavigate();
    const {currencyRateUSDRUB, currencyRateRUBUSD} = useContext(FunctionContext);
    const [error, setError] = useState(null);
    const [cardError, setCardError] = useState(null);


    const fetchOrder = async () => {
        setLoading(true);
        try {
            const response = await customAxios.get(`https://api.method.do/api/orders/getorder/${orderId}/`);
            setOrder(response.data.order);
            setOrderAmount(response.data.order.amount);
            setOrderAmountCurrency(response.data.order.currency);
            setSelectedCardCurrency(response.data.order.currency);
        } catch (error) {
            console.error('Ошибка при получении заказа:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCards = async () => {
        setLoading(true);
        try {
            const response = await customAxios.get("https://api.method.do/api/cards/getcards/");
            setCards(response.data);
            console.log('response.data.cards', response.data);
            console.log('cards', cards);
        } catch (error) {
            console.error('Ошибка при получении карт:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrder();
    }, [orderId]);

    useEffect(() => {
        fetchCards();
    }, []);

    const calculateCost = () => {
        let initialCost = OrderAmount;
        if (OrderAmountCurrency === "USD" && selectedCardCurrency === "RUB") {
            initialCost = parseFloat((OrderAmount * currencyRateRUBUSD).toFixed(2));
        } else if (OrderAmountCurrency === "RUB" && selectedCardCurrency === "USD") {
            initialCost = parseFloat((OrderAmount / currencyRateRUBUSD).toFixed(2));
        }
        setConvertedCost(initialCost);
        setTotalCost(initialCost - (Discount || 0));
    };

    useEffect(() => {
        calculateCost();
    }, [OrderAmount, Discount, OrderAmountCurrency, selectedCardCurrency]);

    const handleCardChange = (cardId, cardCurrency) => {
        setCardError(null);
        setSelectedCardCurrency(cardCurrency);
        setCardId(cardId);
        calculateCost();
    };


    const submitPayment = async () => {
        setLoading(true);

        if (!cardId) {
            setError(t('Select a card'));
            setCardError(true);
            setLoading(false);
            return;
        }

        try {
            const response = await customAxios.post(`https://api.method.do/api/orders/pay/`, {
                card_id: cardId,
                promo_code: promoCode,
                order_id: orderId,
                payment_currency: selectedCardCurrency,
            });
            if (response.data.error) {
                setError(response.data.error.message || "Неизвестная ошибка");
            } else if (response.data.session_url) {
                window.location.href = response.data.session_url;
            } else {
                navigate(`/consultations?order={orderId}&status=success`);
                console.log('Appointment created successfully');
            }
        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError(error.response?.data?.message || error.message);
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className={'col-12 col-md-5 mx-auto'}>
            <Card className={'mb-3'}>
                {order && (
                    <>
                        <Card.Body>
                            {loading && <Loading/>}

                            <Card.Title>
                                {t('Order')} №{order?.id}
                            </Card.Title>

                            {error && (<Alert variant={'danger'}>{t(error)}</Alert>)}

                            <Table className={'fs-sm'}>
                                <tbody>
                                <tr>
                                    <td>{t('Date')}</td>
                                    <td>{moment(order.created_at).tz(timeZone).locale(localeCode).format('LLL')}</td>
                                </tr>
                                <tr>
                                    <td>{t('Cost')}</td>
                                    <td>{parseFloat(order.amount).toFixed(0)} {formatCurrency(order.currency)}</td>
                                </tr>
                                <tr>
                                    <td>{t('Status')}</td>
                                    <td>{t(order.status)}</td>
                                </tr>
                                </tbody>
                            </Table>

                            {order?.status === 'paid' ? (
                                <Alert variant={'success'}>{t('Order paid')}</Alert>
                            ) : order?.status === 'cancelled' || order?.status === 'canceled' ||
                            order?.status === 'waiting_for_refund' || order?.status === 'refunded' ||
                            order?.status === 'cancelled_by_client' || order?.status === 'cancelled_by_specialist' ||
                            order?.status === 'cancelled_by_automatically' || order?.status === 'cancelled_by_admin' ? (
                                <Alert variant={'danger'}>{t('Order cancelled')}</Alert>
                            ) : order?.status === 'expired' ? (
                                <Alert variant={'warning'}>{t('Order expired')}</Alert>
                            ) : (
                            <>
                                {/* Выбор карты */}
                                {/* Радиокнопки для выбора карты */}
                                <Form.Group className={`mb-3`}>
                                    <Form.Label className={'mb-3'}>{t('Payment card')}</Form.Label>
                                    <div className={`${cardError ? "alert alert-danger" : ""}`}>
                                        {cards && cards.map(card => (

                                            <Form.Group key={card.card_id} controlId={`formCardRadio${card.card_id}`}
                                                        className={'position-relative align-items-center'}>
                                                <div
                                                    className="end-0 position-absolute top-50"
                                                    style={{transform: 'translateY(-50%)'}}
                                                >
                                                    <Form.Check
                                                        type="radio"
                                                        id={`formCardRadio${card.card_id}`}
                                                        name="card_id"
                                                        value={card.card_id}
                                                        data-currency="RUB"
                                                        onChange={() => handleCardChange(card.card_id, card.currency)}
                                                        isInvalid={cardError}
                                                    />
                                                </div>
                                                <label htmlFor={`formCardRadio${card.card_id}`}
                                                       className="d-flex align-items-center w-100">
                                                    <Row className="align-items-center g-0 w-100">
                                                        <Col style={{flex: '0 0 auto', width: '50px'}}
                                                             className={'pe-3'}>
                                                            <CardBrand type={card.card_type} width={50}/>
                                                        </Col>
                                                        <Col style={{flex: '1 1 auto'}} className={'w-auto'}>
                                                            <div className="fw-bold">{card.masked_number} <span
                                                                className={'text-secondary'}>{formatCurrency(card.currency)}</span>
                                                            </div>
                                                            <div
                                                                className="fs-sm text-body">{t('Expiration date')} {card.expiration_date}</div>
                                                        </Col>
                                                    </Row>
                                                </label>
                                                <hr className={'my-2'}/>
                                            </Form.Group>

                                        ))}
                                        <Form.Group controlId="newRUB"
                                                    className={'position-relative align-items-center'}>
                                            <div
                                                className="end-0 position-absolute top-50"
                                                style={{transform: 'translateY(-50%)'}}
                                            >
                                                <Form.Check
                                                    type="radio"
                                                    id="newRUB"
                                                    name="card_id"
                                                    value="newRUB"
                                                    data-currency="RUB"
                                                    onChange={() => handleCardChange('newRUB', 'RUB')}
                                                    isInvalid={cardError}
                                                />
                                            </div>
                                            <label htmlFor="newRUB" className="d-flex align-items-center w-100">
                                                <Row className="align-items-center g-0 w-100">
                                                    <Col style={{flex: '0 0 auto', width: '50px'}} className={'pe-3'}>
                                                        <CardBrand type="MIR ADVANCED" width={50}/>
                                                    </Col>
                                                    <Col style={{flex: '1 1 auto'}} className={'w-auto'}>
                                                        <div
                                                            className="fw-bold">{t('Russian Federation or MIR card')}</div>
                                                        <div className="fs-sm text-body">{t('Add a new card')}</div>
                                                    </Col>
                                                </Row>
                                            </label>
                                            <hr className={'my-2'}/>
                                        </Form.Group>

                                        <Form.Group controlId="newUSD"
                                                    className={'position-relative align-items-center'}>
                                            <div className="end-0 position-absolute top-50"
                                                 style={{transform: 'translateY(-50%)'}}>
                                                <Form.Check
                                                    type="radio"
                                                    id="newUSD"
                                                    name="card_id"
                                                    value="newUSD"
                                                    data-currency="USD"
                                                    onChange={() => handleCardChange('newUSD', 'USD')}
                                                    isInvalid={cardError}
                                                />
                                            </div>
                                            <label htmlFor="newUSD" className="d-flex align-items-center w-100">
                                                <Row className="align-items-center g-0 w-100">
                                                    <Col style={{flex: '0 0 auto', width: '50px'}} className={'pe-3'}>
                                                        <CardBrand width={50}/>
                                                    </Col>
                                                    <Col style={{flex: '1 1 auto'}} className={'w-auto'}>
                                                        <div
                                                            className="fw-bold">{t('Other countries or USD card')}</div>
                                                        <div className="fs-sm text-body">{t('Add a new card')}</div>
                                                    </Col>
                                                </Row>
                                            </label>
                                        </Form.Group>
                                    </div>
                                </Form.Group>


                                {/* Промокод */}
                                <PromoCodeInput OrderAmount={OrderAmount}
                                                Currency={OrderAmountCurrency}
                                                setDiscount={setDiscount}
                                                setPromoCode={setPromoCode}
                                />

                                {/* Итоговая стоимость */}
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>{t('Total')}</div>
                                    <div className="fw-bold">{totalCost} {formatCurrency(selectedCardCurrency)}</div>
                                </div>
                            </>
                        )}

                        </Card.Body>
                        <Card.Footer>
                            <button className={'btn btn-primary w-100'} onClick={submitPayment} disabled={loading}>
                                {t('Pay')}
                            </button>
                        </Card.Footer>
                    </>
                )}
            </Card>
        </div>
    );
}

export default PaymentOrder;