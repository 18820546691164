import React, {useEffect, useState} from 'react';
import {
    Box, Menu, MenuItem, Typography, FormControl, Select, InputLabel, TextField, Button
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from "@mui/material/Autocomplete";
import {useAuth} from "../contexts/AuthContext";
import moment from "moment/moment";

const Settings = () => {
    const {t, i18n} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const {timeZone = '', setTimeZone} = useAuth();
    const [newTimeZone, setNewTimeZone] = useState(timeZone);
    const {getTimezoneData, formatTimeZone, currency, setCurrency} = useAuth();
    const [currentTime, setCurrentTime] = useState(() => {
        if (moment.tz.zone(timeZone)) {
            return moment().tz(timeZone).format('HH:mm:ss');
        } else {
            return moment().format('HH:mm:ss');
        }
    });

    const saveTimeZone = () => {
        setTimeZone(newTimeZone);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (moment.tz.zone(timeZone)) {
                setCurrentTime(moment().tz(timeZone).format('HH:mm:ss'));
            } else {
                setCurrentTime(moment().format('HH:mm:ss'));
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [timeZone]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (event) => {
        const language = event.target.value;
        i18n.changeLanguage(language);
        handleClose();
    };

    const handleCurrencyChange = (event) => {
        setCurrency(event.target.value);
    };

    const getLanguageText = () => {
        switch (i18n.language) {
            case 'en':
                return t('EN');
            case 'ru':
                return t('RU');
            // Добавьте другие языки по мере необходимости
            default:
                return t('EN');
        }
    };

    return (
        <>
            <Typography
                variant="body1"
                onClick={handleClick}
                sx={{
                    padding: 1,
                    cursor: 'pointer',
                    flexGrow: 0,
                    marginRight: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {`${getLanguageText()}`} <ExpandMoreIcon/>
            </Typography>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <Box sx={{p: 2, width: '350px'}}>
                    <FormControl fullWidth variant="outlined" sx={{mt: 1, mb: 2}}>
                        <InputLabel>{t('Language')}</InputLabel>
                        <Select
                            value={i18n.language || 'en'}
                            onChange={handleLanguageChange}
                            label={t('Language')}
                        >
                            <MenuItem value="en">{t('English')}</MenuItem>
                            <MenuItem value="ru">{t('Russian')}</MenuItem>
                            {/* Добавьте другие языки по мере необходимости */}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth variant="outlined" sx={{mt: 1, mb: 2}}>
                        <InputLabel>{t('Currency')}</InputLabel>
                        <Select
                            value={currency}
                            onChange={handleCurrencyChange}
                            label={t('Currency')}
                        >
                            <MenuItem value="USD">{t('USD')}</MenuItem>
                            <MenuItem value="EUR">{t('RUB')}</MenuItem>
                        </Select>
                    </FormControl>

                    <Box sx={{mt: 1, mb: 2}}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>{t('Time Zone')}</InputLabel>
                            <Autocomplete
                                options={getTimezoneData()}
                                autoHighlight
                                getOptionLabel={(option) => option.label}
                                value={getTimezoneData().find((option) => option.tz === newTimeZone)}
                                onChange={(event, newValue) => {
                                    setNewTimeZone(newValue?.tz);
                                }}
                                sx={{pb: 2}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                        {newTimeZone !== timeZone && (
                            <Button variant="contained" onClick={saveTimeZone}>{t('Save')}</Button>)}
                    </Box>

                    <Box>
                        <Typography variant="body2">{t("Current Time")}:</Typography>
                        <Typography variant="h6" sx={{mb: 1}}>{currentTime} </Typography>

                        <Typography variant="body2">{t("Current Time Zone")}:</Typography>
                        <Typography variant="h6" sx={{mb: 1}}>{formatTimeZone(timeZone)}</Typography>
                    </Box>

                </Box>
            </Menu>
        </>
    );
};

export default Settings;
