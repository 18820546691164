import React from 'react';
import {Container, Image} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import layer01 from './images/layer01.png';
import layer02 from './images/layer02.png';
import layer03 from './images/layer03.png';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
    const { t } = useTranslation();
    return (
        <main>
            {/* Page content */}
            <Container className="d-flex flex-column h-100 align-items-center position-relative zindex-5 pt-5">
                <div className="text-center pt-5 pb-3 mt-auto">
                    {/* Parallax gfx (Light version) */}
                    <div className="parallax mx-auto d-dark-mode-none" style={{ maxWidth: '574px' }}>
                        <div className="parallax-layer" data-depth="-0.15">
                            <Image src={layer01} alt="Layer" />
                        </div>
                        <div className="parallax-layer" data-depth="0.12">
                            <Image src={layer02} alt="Layer" />
                        </div>
                        <div className="parallax-layer zindex-5" data-depth="-0.12">
                            <Image src={layer03} alt="Layer" />
                        </div>
                    </div>

                    <h1 className="visually-hidden">404</h1>
                    <h2 className="display-5">{t('Ooops!')}</h2>
                    <p className="fs-xl pb-3 pb-md-0 mb-md-5">
                        {t('The page you are looking for is not available.')}
                    </p>
                    <Link to="/" className="btn btn-lg btn-primary shadow-primary w-sm-auto w-100">
                        <i className="bx bx-home-alt me-2 ms-n1 lead"></i>
                        {t('Go to homepage')}
                    </Link>
                </div>
            </Container>
        </main>
    );
};

export default NotFoundPage;
