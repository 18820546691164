import CardBrand from "./CardBrand";
import React from "react";
import { useTranslation } from "react-i18next";
import {formatCurrency} from "utils/Currency";

const CardItem = ({ key, card, defaultCardId, makeDefaultCard, deleteCard }) => {
    const { t } = useTranslation();

return (
        <div key={card.id}
             className="card d-sm-flex flex-sm-row align-items-sm-center justify-content-between border-0 shadow-sm p-3 p-md-4 mb-4">
            <div className="d-flex align-items-center pe-sm-3">
                <CardBrand type={card.card_type} width={84} />
                <div className="ps-3 ps-sm-4">
                    <h6 className="mb-2">{card.masked_number} <span className={'text-secondary'}>{formatCurrency(card.currency)}</span></h6>
                    <div className="fs-sm">{t('Expiration date')} {card.expiration_date}</div>
                </div>
            </div>
            <div className="d-flex justify-content-end pt-3 pt-sm-0">
                {/* <button type="button"
                        className="btn btn-outline-secondary px-3 px-xl-4 me-3 set-default-card"
                        onClick={() => makeDefaultCard(card.id)}>
                    <i className="bx bx-edit fs-xl me-lg-1 me-xl-2"></i>
                    <span className="d-none d-lg-inline">{t('Make primary')}</span>
                </button> */}
                <button type="button" className="btn btn-outline-danger px-3 px-xl-4 card-delete"
                        onClick={() => deleteCard(card.id)}>
                    <i className="bx bx-trash-alt fs-xl"></i>
                </button>
            </div>
        </div>
    );
}

export default CardItem;

