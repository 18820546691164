import React, {useEffect, useState} from 'react';
import customAxios from 'configs/axiosConfig';
import Loading from 'components/wrap/Loading';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import ZoomVideoSDK from './components/ZoomVideoSDK';
import ZoomMeetingNew from './components/ZoomMeetingSDK';
import {Col, Row} from 'react-bootstrap';


const ConsultationSession = () => {
    const {t} = useTranslation();
    const {sessionID} = useParams();
    const [loading, setLoading] = useState(true);
    const [coach, setCoach] = useState({});
    const [appointment, setAppointment] = useState({});


    const fetchData = async () => {
        setLoading(true)
        try {
            const sessionData = await customAxios.get(`https://api.method.do/api/appointment/sessions/${sessionID}/`);
            setCoach(sessionData.data.coach);
            setAppointment(sessionData.data.appointment);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [sessionID]);

    return (
        <div>
            {loading && <Loading />}
            <h2>{t('Consultation with')} {coach?.expert ? `${coach.expert.first_name} ${coach.expert.last_name}` : ''}</h2>
            <Row>
                <Col xs={12} md={2}>
                </Col>
                <Col xs={12} md={10}>
                    <ZoomVideoSDK sessionID={sessionID} appointment={appointment} />
                    {/*<ZoomMeetingNew sessionID={sessionID} appointment={appointment} /> */}
                </Col>
            </Row>
        </div>
    );
}

export default ConsultationSession;
