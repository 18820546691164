import React, { useState } from 'react';

const FaqItem = ({ faq }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
            <h2 className="accordion-header">
                <button
                    className={`accordion-button shadow-none rounded-3 ${isOpen ? '' : 'collapsed'}`}
                    type="button"
                    onClick={toggleAccordion}
                >
                    {faq.question}
                </button>
            </h2>
            <div className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}>
                <div className="accordion-body fs-sm pt-0">
                    <p>{faq.answer}</p>
                </div>
            </div>
        </div>
    );
};

export default FaqItem;
