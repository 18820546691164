import React from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';

const SEO = ({ title, description, keywords, image }) => {
    const {t} = useTranslation();
    const url = typeof window !== 'undefined' ? window.location.href : '';

    const titleData = t(title);
    const descriptionData = t(description);

    return (
        <Helmet>
            <title>{titleData} – {t('Method.do platform')}</title>
            <meta name="description" content={descriptionData}/>
            <meta property="og:title" content={titleData}/>
            <meta property="og:description" content={descriptionData}/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={url}/>
            <meta property="og:image" content={image}/>
            <meta name="keywords" content={keywords}/>
            <meta name="author" content="Method.do"/>
            <meta name="copyright" content="Method.do"/>
        </Helmet>
    );
};

export default SEO;
