import React, {Suspense, useContext} from 'react';
import {useAuth} from 'contexts/AuthContext';
import Loading from 'components/wrap/Loading';

const QuizViews = React.lazy(() => import('app/pages/tests/views/quizViews'));

const Tests = () => {
    const {user} = useAuth();

    return (
        <div>
            <Suspense fallback={<Loading/>}>
                <QuizViews />
            </Suspense>
        </div>
    );
}

export default Tests;