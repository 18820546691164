// getappoinment.js
import React, {useState, useEffect} from 'react';
import customAxios from 'configs/axiosConfig';
import 'moment/locale/ru';

import {useTranslation} from 'react-i18next';
import AvailabilityCalendar from "app/pages/matching/coachView/components/AvaliabilityCalendar";

function Appoinment({coach}) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);

    const [availability, setAvailability] = useState([]);
    const [packages, setPackages] = useState([]);
    const [appointments, setAppointments] = useState([]);

    const getDataPackages = async () => {
        setLoading(true);
        try {
            const response = await customAxios.get(`https://api.method.do/api/${coach.id}/package/get/`);
            setPackages(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            if (error.response.status === 404) {
                setPackages([]);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        getDataPackages();
    }, []);

    const getDataAvailability = async () => {
        setLoading(true);
        try {
            const response = await customAxios.get(`https://api.method.do/api/${coach.id}/matching/getfreetimeslots/`);
            setAvailability(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            if (error.response.status === 404) {
                setAvailability([]);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        getDataAvailability();
    }, []);


    return (
        <>
                <AvailabilityCalendar
                    availabilities={availability}
                    packages={packages}
                    appointments={appointments}
                    coach={coach}
                />

        </>
    );
}

export default Appoinment;
