import React, {useState} from 'react';
import {Box, IconButton} from '@mui/material';
import ReactPlayer from 'react-player/wistia';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {useTranslation} from 'react-i18next';

const VideoPlayer = ({videoUrl, timer = 10, thumbnailUrl, showButtonAndLessons, setShowButtonAndLessons }) => {
    const {t} = useTranslation();
    const [playing, setPlaying] = useState(false);

    const handleProgress = (state) => {
        const currentTime = Math.floor(state.playedSeconds); // Время проигрывания в секундах
        //console.log('Время проигрывания:', currentTime);
        //console.log('Видео достигло времени появления кнопки', showButtonAndLessons);

        if (!showButtonAndLessons && currentTime >= timer) {
            console.log('Видео достигло времени появления кнопки');
            setShowButtonAndLessons(true);
        }
    };

    const playVideo = (event) => {
        setPlaying(true);
    };

    return (
        <Box sx={{
            borderRadius: 4,
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            paddingBottom: '56.25%',
            height: 0,
        }}>
            <ReactPlayer
                url={videoUrl}
                controls
                playing={playing}
                width="100%"
                height="100%"
                style={{position: 'absolute', top: 0, left: 0}}
                config={{
                    wistia: {
                        options: {
                            videoFoam: true,
                            controlsVisibleOnLoad: true,
                            fullscreenButton: true,
                            playbar: false,
                            volumeControl: true,
                            qualityControl: true,
                            captionsButton: true,
                            autoplay: true,
                        }
                    }
                }}
                light={
                    <Box
                        sx={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#000',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={playVideo}
                    >
                        <video
                            src={thumbnailUrl}
                            poster={thumbnailUrl}
                            width="100%"
                            height="100%"
                            style={{ position: 'absolute', top: 0, left: 0 }}
                            muted
                            loop
                            autoPlay
                            playsInline
                        />
                        <IconButton
                            onClick={playVideo}
                            sx={{
                                position: 'absolute',
                                color: 'white',
                                backgroundColor: 'rgba(0, 124, 203, 0.8)',
                                borderRadius: '12px',
                                padding: '10px 20px',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 89, 145, 0.8)',
                                }
                            }}
                        >
                            <PlayArrowIcon
                                sx={{ fontSize: '48px' }}
                            />
                        </IconButton>
                    </Box>
                }
                onProgress={handleProgress}
                onReady={() => {
                    console.log('Player is ready');
                }}
                onEnded={() => {
                    console.log('Video ended');
                }}
            />
        </Box>
    );
};

export default VideoPlayer;