// Test1.jsx
import React from 'react';
import QuizContainer from './quiz/QuizContainer';
import SEO from "../../../configs/SEO";

const Quiz = () => {
    const Questions = [
        {
            questionText: 'Для чего вам нужен коуч?',
            answerType: 'radio',
            answerOptions: ['Для личных целей', 'Для бизнес целей', 'Для сотрудников компании'],
            field: 'purpose',
        }, {
            questionText: 'Выберите актуальные для вас темы',
            answerType: 'checkbox-category',
            answerOptions: [
                {
                    id: 1,
                    title: "Вопросы личного характера",
                    items: [
                        "Пройти сложный этап жизни",
                        "Повысить эффективность работы",
                        "Постановить и достигнуть личных целей",
                        "Развить новые способности, навыки",
                        "Восстановиться после сложностей",
                        "Отношения внутри семьи",
                        "Отношения с другими людьми",
                        "Развитие в карьере",
                        "Здоровый образ жизни",
                        "Духовное развитие",
                        "Поиск себя, предназначения"
                    ]
                },
                {
                    id: 2,
                    title: "Вопросы бизнес характера",
                    items: [
                        "Понять цели, сформировать стратегию развития",
                        "Развить лидерские навыки",
                        "Развить навыки управления",
                        "Сопровождение первых лиц компании",
                        "Выстроить систему продаж",
                        "Выстроить маркетинговую стратегию",
                        "Сделать работу более эффективной",
                        "Улучшить товар или услугу",
                        "Управлять деньгами",
                        "Управлять командой на расстоянии",
                        "Найти и удержать хороших сотрудников"
                    ]
                },
                {
                    id: 3,
                    title: "Корпоративный коучинг",
                    items: [
                        "Сплотить коллектив",
                        "Улучшить общение в команде",
                        "Повысить уровень удовлетворенности и мотивации сотрудников",
                        "Правильно распределить задачи",
                        "Сделать сервис лучше для клиента",
                        "Быстро адаптировать новых сотрудников"
                    ]
                }
            ],
            field: 'topics',
        }, {
            questionText: 'Важен ли пол коуча?',
            answerType: 'radio',
            answerOptions: ['Пол коуча не имеет значения', 'Мне важно, чтобы коуч был женщиной', 'Мне важно, чтобы коуч был мужчиной',],
            field: 'coachGender',
        }, {
            questionText: 'Как долго планируете работать с коучем?',
            answerType: 'radio',
            answerOptions: ['1-2 консультации', '3-5 консультаций', 'Дольше', 'Решу после первой консультации',],
            field: 'duration',
        }, {
            questionText: 'Сколько комфортно платить за сессию?',
            answerType: 'radio',
            answerOptions: ['2 000 ₽ - 5 000 ₽', '5 000 ₽ - 10 000 ₽', '10 000 ₽ - 20 000 ₽', 'от 20 000 ₽',],
            field: 'price',
        },
    ];
    const ResultSteps = [
        {title: 'Сбор необходимых данных', percentage: 25},
        {title: 'Анализ полученных данных', percentage: 50},
        {title: 'Поиск подходящих коучей', percentage: 75},
        {title: 'Персонализация подборки', percentage: 100}
    ];

    return (
        <>
            <SEO
                title="Discover your ideal coach with our matching test"
                description="Find the perfect coach for your needs with our comprehensive matching test. Answer a few questions and get matched with the coach who can best help you achieve your goals."
            />

            <QuizContainer questions={Questions} resultSteps={ResultSteps} finishURL={'https://app.method.do/matching'} />
        </>
    );
}

export default Quiz;
