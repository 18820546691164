//WelcomePage.js
import React, {useState, useEffect} from 'react';
import {useAuth} from 'contexts/AuthContext';
import {useTranslation} from 'react-i18next';
import {Container} from '@mui/material';
import BackgroundCircles from 'utils/BackgroundCircles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import Grid from '@mui/material/Grid';
import customAxios from "configs/axiosConfig";
import LoadingButton from '@mui/lab/LoadingButton';
import {useNavigate} from 'react-router-dom';
import Alert from "@mui/material/Alert";
import LanguageSwitcher from "utils/LanguageSwitcher";


function WelcomePage() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const {user, updateUser} = useAuth();
    const [accountType, setAccountType] = useState(user?.account_type || '');

    useEffect(() => {
        if (user?.account_type) {
            if (!user.first_name || !user.last_name || !user.country) {
                navigate('/aboutyou');
                return;
            }
            navigate('/home');
        }
    }, [user]);

    const fetchData = async () => {
        setLoading(true)
        setError(null);
        if (!accountType) {
            setError(t('Please select an account type'));
            setLoading(false);
            return;
        }
        try {
            const sessionData = await customAxios.patch(`https://api.method.do/api/user/updateuser/`, {
                account_type: accountType
            });
            if (sessionData.status === 200) {
                updateUser(sessionData.data);
                if (!user.first_name || !user.last_name || !user.country) {
                    navigate('/aboutyou');
                } else {
                    navigate('/home');
                }
            } else {
                console.log('Error fetching data:', sessionData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Container maxWidth={false} disableGutters className="" sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <BackgroundCircles/>
            <Container maxWidth="md" disableGutters
                       sx={{
                           backgroundColor: '#fff',
                           borderRadius: '10px',
                           paddingY: {
                               xs: 4,
                               md: 4
                           },
                           paddingX: {
                               xs: 2,
                               md: 5
                           },
                       }}>

                <Typography variant="h4" pb={1}>{t('Welcome to') + ' Method do'}</Typography>
                <Typography variant="body1">
                    {t('To ensure we provide you with the best service, please specify')}:<br/>
                    {t('are you here for yourself or representing a company?')}
                </Typography>
                {error && <Alert severity="error" sx={{mb: 2}}>{t(error)}</Alert>}

                <Grid container spacing={1} py={2} sx={{width: '100%', flexGrow: 1}} alignItems="stretch">

                    <Grid item>
                        <Box border={3} sx={{
                            maxWidth: {xs: '100%', md: 250},
                            borderColor: (accountType === 'personal' ? 'primary.main' : 'transparent'),
                            borderRadius: '10px',
                            cursor: 'pointer',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }} onClick={() => setAccountType('personal')}
                        >
                            <Box border={1} p={2} sx={{borderColor: 'border.main', borderRadius: '10px', flexGrow: 1}}>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <PersonIcon sx={{color: 'primary.main'}}/>
                                    <Typography variant="subtitle1" gutterBottom>
                                        {t('Personal')}
                                    </Typography>
                                </Box>
                                <Typography variant="body1">
                                    {t('To understand yourself and achieve your personal and career goals.')}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item sx={{height: '100%'}}>
                        <Box border={3} sx={{
                            maxWidth: {xs: '100%', md: 250},
                            borderColor: (accountType === 'team' ? 'primary.main' : 'transparent'),
                            borderRadius: '10px',
                            cursor: 'pointer',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }} onClick={() => setAccountType('team')}
                        >
                            <Box border={1} p={2} sx={{borderColor: 'border.main', borderRadius: '10px', flexGrow: 1}}>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <GroupIcon sx={{color: 'primary.main'}}/>
                                    <Typography variant="subtitle1" gutterBottom>
                                        {t('Team')}
                                    </Typography>
                                </Box>
                                <Typography variant="body1">
                                    {t('To understand your team\'s unique superpowers and opportunities to grow.')}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Box display="flex" width="100%"
                     sx={{justifyContent: {xs: 'flex-end', md: 'flex-start'}, pt: {xs: 1, md: 0}}}>
                    <LoadingButton variant="contained" color="primary" size="large" onClick={fetchData}
                                   loading={loading}>
                        {t('Next')}
                    </LoadingButton>
                </Box>
            </Container>
            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <LanguageSwitcher color="white"/>
            </Box>
        </Container>

    )
}

export default WelcomePage;