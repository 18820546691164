// GoogleLogin.jsx
import React from 'react';

const MailLogin = () => {
    const mainLoginUrl = 'https://api.method.do/api/mail/login/';


    return (
        <div className="col mb-3">
            <a
                href={mainLoginUrl}
                className="btn btn-icon btn-secondary btn-facebook btn-lg w-100"
            >
                <i className="bx bxs-envelope fs-xl me-2"></i>
                Mail.ru
            </a>
        </div>
    );
}

export default MailLogin;