import React from 'react';
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

function CoachPhoto(props) {
    const { coach, loading, setLoading } = props;
    const { t } = useTranslation();

    return (
        <div>
            <div className="bg-white rounded overflow-hidden">
                <div className="pb-1">
                    <Link to={`/coach/${coach.id}/`}>
                        <img
                            src={
                                coach.profile_photo && !coach.profile_photo.includes('noavatar.jpeg')
                                    ? coach.profile_photo.startsWith('https://method.do/images')
                                        ? `https://api.${coach.profile_photo.slice(8)}`
                                        : `https://api.method.do/images/${coach.profile_photo}`
                                    : coach.profile_photo
                            }
                            alt=""
                            className="img-fluid" />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default CoachPhoto;