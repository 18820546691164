import React, {createContext, useContext, useState, useEffect} from 'react';
import customAxios from "../configs/axiosConfig";
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Loading from 'components/wrap/Loading';
import allLocales from "@fullcalendar/core/locales-all";
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import AuthModal from 'components/auth/AuthModal';

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(false);
    const cookieOptions = { path: '/', sameSite: 'strict', secure: false };
    // const cookieOptions = { domain: '.method.do', path: '/' };
    const [currency, setCurrency] = useState('USD');
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState(() => {
        const rawUserData = Cookies.get('userData');
        if (rawUserData) {
            try {
                return JSON.parse(rawUserData);
            } catch (error) {
                console.error("Ошибка при разборе пользовательских данных:", error);
                Cookies.remove('userData');
                return null;
            }
        }
        return null;
    });
    const [token, setToken] = useState(() => {
        const storedToken = Cookies.get('access_token');
        return storedToken ? storedToken : null;
    });
    const [refreshToken, setRefreshToken] = useState(() => {
        const storedRefreshToken = Cookies.get('refresh_token');
        return storedRefreshToken ? storedRefreshToken : null;
    });
    const [isAuthenticated, setIsAuthenticated] = useState(Cookies.get('isAuthenticated') === 'true' || false);
    const [timeZone, setTimeZone] = useState(Cookies.get('userTimeZone') || user?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone);
    const locale = allLocales.find(l => l.code === i18n.language) || allLocales.find(l => l.code === 'en');
    const localeCode = locale ? locale.code : 'en';
    const isAppSubdomain = window.location.hostname.startsWith('app.');
    const isLeadSubdomain = window.location.hostname.startsWith('lead.');
    const [popupAuthModal, setPopupAuthModal] = useState(false);

    const getTimezoneData = () => {
        return moment.tz.names().map(zone => {
            const offset = moment.tz(zone).utcOffset();
            const hours = Math.floor(Math.abs(offset) / 60);
            const minutes = Math.abs(offset) % 60;
            const sign = offset >= 0 ? '+' : '-';
            const formattedOffset = `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
            return {
                tz: zone,
                label: `${t(zone)} (UTC ${formattedOffset})` // Пример: "Europe/Moscow (UTC +3:00)"
            };
        });
    };

    const formatTimeZone = (timeZone) => {
        // Получение смещения в минутах для заданной временной зоны
        const offset = moment.tz(timeZone).utcOffset();
        const hours = Math.floor(Math.abs(offset) / 60);
        const minutes = Math.abs(offset) % 60;
        const sign = offset >= 0 ? '+' : '-';
        const formattedOffset = `GMT ${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        return `${t(timeZone)} (${formattedOffset})`;
    };

    // Если user.timezone изменится и timeZone еще не установлен, обновляем timeZone
    useEffect(() => {
        if (!Cookies.get('refresh_token') && user?.timezone) {
            setTimeZone(user.timezone);
        }
    }, [user]);

    useEffect(() => {
        Cookies.set('userTimeZone', timeZone, cookieOptions);
    }, [timeZone]);

    useEffect(() => {
        if (token) {
            fetchUser();
        }
    }, [token]);

    const fetchUser = async () => {
        if (!token) {
            return;
        }

        setLoading(true);
        try {
            const response = await customAxios.get('https://api.method.do/api/user/checkAuthenticate/');

            if (response.data.is_authenticated) {
                setUser(response.data.user);
                //console.log('User data:', user);
                setIsAuthenticated(true);

                Cookies.set('isAuthenticated', true, cookieOptions);
                Cookies.set('userData', JSON.stringify(response.data.user), cookieOptions);

                if (!response.data.user.account_type) {
                    navigate('/welcomepage');
                    console.log('Account type is not set');
                    return;
                }

                if (!response.data.user.first_name || !response.data.user.last_name || !response.data.user.country) {
                    navigate('/aboutyou');
                    console.log('User data is not set');
                    return;
                }

            } else {
                alert(t('Session has expired, please log in to your account again'));
                logoutFunc();
            }
        } catch (error) {
            console.error("Ошибка при получении данных пользователя:", error);
            logoutFunc();
        } finally {
            setLoading(false);
        }
    };

    const logout = async () => {
        setLoading(true);
        try {
            const response = await customAxios.post('https://api.method.do/api/user/logout/');
            if (response.status === 200) {
                logoutFunc();
            }

        } catch (error) {
            logoutFunc();
        } finally {
            setLoading(false);
        }
    };

    const logoutFunc = () => {
        setUser(null);
        setToken(null);
        setRefreshToken(null);
        setIsAuthenticated(false);

        // Удаление cookies с доступностью на всех поддоменах .method.do
        Cookies.remove('access_token', cookieOptions);
        Cookies.remove('refresh_token', cookieOptions);
        Cookies.remove('isAuthenticated', cookieOptions);
        Cookies.remove('userData', cookieOptions);

        navigate("/login", { replace: true });
    }

    const login = ({userData, access_token, refresh_token, is_authenticated}) => {
        setUser(userData);
        setToken(access_token);
        setRefreshToken(refresh_token);
        setIsAuthenticated(is_authenticated);

        Cookies.set('access_token', access_token, {...cookieOptions, expires: 60});
        Cookies.set('refresh_token', refresh_token, {...cookieOptions, expires: 60});
        Cookies.set('isAuthenticated', is_authenticated, {...cookieOptions, expires: 60});
        Cookies.set('userData', JSON.stringify(userData), {...cookieOptions, expires: 60});
    };

    const updateUser = (updates) => {
        const updatedUser = {...user, ...updates};
        setUser(updatedUser);

        if (updates.url_image) {
            updatedUser.profile_photo = updates.url_image;
        }

        Cookies.set('userData', JSON.stringify(updatedUser), {...cookieOptions, expires: 60});
    };

    const AuthModalComponent = ({coach = null, nextPage = null}) => {

        return (
            <AuthModal
                open={popupAuthModal}
                handleClose={() => setPopupAuthModal(false)}
                coach={coach}
                nextPage={nextPage}
            />
        );
    };

    const contextValue = {
        user,
        isAuthenticated,
        login,
        logout,
        updateUser,
        timeZone,
        setTimeZone,
        locale,
        localeCode,
        getTimezoneData,
        formatTimeZone,
        isAppSubdomain,
        isLeadSubdomain,
        currency,
        setCurrency,
        AuthModalComponent,
        setPopupAuthModal

    };

    return (
        <AuthContext.Provider value={contextValue}>
            {loading && <Loading zIndex={200} />}
            {!isAuthenticated && <AuthModalComponent />}
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
