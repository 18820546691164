//WelcomePage.js
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Container} from '@mui/material';
import BackgroundCircles from 'utils/BackgroundCircles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import LanguageSwitcher from "utils/LanguageSwitcher";
import logo from "static/logo/logo-white.svg";


function Popup({title, text, children, next = 'Next', prev, error, onPrev, onNext, loading = false}) {
    const {t} = useTranslation();

    return (
        <Container maxWidth={false} disableGutters className="" sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <BackgroundCircles/>
            <Box display="flex" justifyContent="center" alignItems="center" mb={2} >
                <img src={logo} width="170" alt="method.do logo"/>
            </Box>
            <Container maxWidth="md" disableGutters
                       sx={{
                           backgroundColor: '#fff',
                           borderRadius: '10px',
                           paddingY: {
                               xs: 4,
                               md: 4
                           },
                           paddingX: {
                               xs: 2,
                               md: 5
                           },
                       }}>
                {title && <Typography variant="h5" pb={1}>{t(title)}</Typography>}
                {text && <Typography variant="body1" pb={2}>{t(text)}</Typography>}
                {error && <Alert severity="error" sx={{mb: 2}}>{t(error)}</Alert>}

                {children && (
                    <Box pb={2}>
                        {children}
                    </Box>
                )}

                <Box display="flex" width="100%" sx={{justifyContent: {xs: 'flex-end', md: 'flex-start'}, pt: { xs: 1, md: 0 }}}>
                    {prev && (
                        <Button variant="contained" color="white" size="large" sx={{mr: 1}} onClick={onPrev}>
                            {t(prev)}
                        </Button>
                    )}
                    {next && (
                        <LoadingButton variant="contained" color="primary" size="large" onClick={onNext} loading={loading}>
                            {t(next)}
                        </LoadingButton>
                    )}
                </Box>
            </Container>

            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <LanguageSwitcher color="white"/>
            </Box>

        </Container>
    )
}

export default Popup;