// OwnMessage.js
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useAuth } from 'contexts/AuthContext';
import noAvatar from "../../../../static/images/noavatar.jpeg";
import Image from "react-bootstrap/Image";

const OwnMessage = ({message, websocket}) => {
    const { t } = useTranslation();
    const { timeZone } = useAuth();


    return (
        <div className="d-flex align-items-start justify-content-end mb-3">
            <div className="pe-2 me-1" style={{ maxWidth: '348px' }}>
                <div className="bg-primary text-light p-3 mb-1" style={{ borderTopLeftRadius: '.5rem', borderBottomRightRadius: '.5rem', borderBottomLeftRadius: '.5rem' }}>
                    {message.content}
                </div>
                <div className="d-flex justify-content-end align-items-center fs-sm text-muted">
                    {moment(message.timestamp).tz(timeZone).format('HH:mm a')}
                    <i className={`bx bx-check-double fs-xl ${message.is_read ? 'text-primary' : 'text-secondary'} ms-2`}></i>
                </div>
            </div>
            <Image
                src={message?.sender.profile_photo || noAvatar}
                className="rounded-circle"
                style={{
                    width: '48px',
                    height: '48px',
                    objectFit: 'cover'
                }}
                alt={t("Profile Photo")}
            />
        </div>
    );
};

export default OwnMessage;
