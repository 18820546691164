import React, {useEffect, useState, useContext, useRef} from 'react';
import ZoomVideo from '@zoom/videosdk';
import customAxios from "../../../../configs/axiosConfig";
import uitoolkit from '@zoom/videosdk-ui-toolkit'
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css'
import {useTranslation} from 'react-i18next';
import {Box, Typography, Button} from '@mui/material';
import {FunctionContext} from 'contexts/FunctionContext';
import {Link} from 'react-router-dom'
import moment from 'moment';


const ZoomVideoSDK = ({sessionID, appointment}) => {
    const [zoomCredentials, setZoomCredentials] = useState(null);
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();
    const [sessionStarted, setSessionStarted] = useState(false);
    const [isPreviewActive, setIsPreviewActive] = useState(false);
    const {formatDateTime} = useContext(FunctionContext);
    const [isFullscreen, setIsFullscreen] = useState(false);


    const fetchData = async () => {
        setLoading(true);
        try {
            const tokenData = await customAxios.get(`https://api.method.do/api/appointment/sessions/${sessionID}/generate-zoom-token-new/`);
            setZoomCredentials({
                sdkKey: tokenData.data.sdkKey,
                token: tokenData.data.token,
                MeetingNumber: tokenData.data.meeting_number,
                userName: tokenData.data.user_name,
                userEmail: tokenData.data.user_email,
            });
            console.log('Zoom credentials fetched:', tokenData.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [sessionID]);

    const initZoom = async () => {
        if (!zoomCredentials) {
            console.error('Zoom credentials are not loaded');
            return;
        }

        try {
            const zmClient = ZoomVideo.createClient();
            zmClient.init('en-US', 'CDN');
            console.log('Zoom client initialized:', zmClient);

            var sessionContainer = document.getElementById('sessionContainer')

            var config = {
                videoSDKJWT: zoomCredentials.token,
                sessionName: zoomCredentials.MeetingNumber,
                userName: zoomCredentials.userName,
                sessionPasscode: '',
                features: ['video', 'audio', 'share', 'chat', 'users', 'settings']
            }

            uitoolkit.joinSession(sessionContainer, config);

            setSessionStarted(true);
            console.log('Zoom session started:', sessionStarted);

        } catch (error) {
            console.error('Error initializing Zoom SDK:', error);
            setSessionStarted(false);
        }
    };

    return (
        <div>

            {!sessionStarted ? (
                <>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mx: 'auto',
                        bgcolor: 'background.body',
                        border: 1,
                        borderColor: 'divider',
                        borderRadius: 'md',
                        p: 3,
                        minHeight: 600,
                    }}>

                        <Box sx={{display: 'flex', justifyContent: 'center', mb: 2}}>
                            <Typography level="h1">🎥</Typography>
                        </Box>

                        <Typography level="h4" component="h1" sx={{textAlign: 'center', mb: 1}}>
                            {t('Scheduled session')}
                        </Typography>

                        <Typography level="body1" sx={{textAlign: 'center', mb: 2}}>
                            {formatDateTime(appointment.appointment_datetime)}
                        </Typography>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            'alignItems': 'center',
                            flexDirection: 'column'
                        }}>

                            {moment(appointment.appointment_datetime).subtract(12, 'hours').isBefore(moment()) ? (
                                <Button
                                    size="lg"
                                    variant="contained"
                                    onClick={initZoom}
                                    disabled={!zoomCredentials}
                                    loading={loading}
                                    sx={{'mb': 2}}
                                >
                                    {t('Join Zoom meeting')}
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        size="lg"
                                        variant="contained"
                                        disabled
                                        sx={{'mb': 2}}
                                    >
                                        {t('The session is not yet started')}
                                    </Button>
                                </>
                            )}

                            <Link to={`/test/zoom`} target={'_blank_'}>
                                {t('Test video call')}
                            </Link>

                        </Box>
                    </Box>
                </>
            ) : null}

            <div id='sessionContainer'></div>

        </div>
    );
};

export default ZoomVideoSDK;
