import React, {useState, useEffect} from 'react';
import customAxios from 'configs/axiosConfig';
import {useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Loading from 'components/wrap/Loading';
import {Col, Row} from 'react-bootstrap';
import {Container} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import Appointment from './components/appointment';
import CoachInfo from './components/coachinfo';
import CoachPhoto from './components/coachphoto';
import SEO from "../../../../configs/SEO";

const Coach = ({match}) => {
    const {t} = useTranslation();
    const [coach, setCoach] = useState(null);
    const {id} = useParams();
    const coachId = id;
    const [loading, setLoading] = useState(false);

    const fetchCoach = async () => {
        setLoading(true);
        try {
            const response = await customAxios.get(`https://api.method.do/api/coach/${coachId}/`);
            setCoach(response.data);
        } catch (error) {
            console.error(t("Error loading coach data:"), error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCoach();
    }, [coachId]);

    useEffect(() => {
        Fancybox.bind("[data-fancybox]", {});

        return () => {
            Fancybox.destroy();
        };
    }, []);

    if (!coach) {
        return <Loading message={t("Loading coach data...")}/>;
    }

    return (
        <>
            <SEO
                title={`${t("Coach")} ${coach?.expert.first_name} ${coach?.expert.last_name}`}
                description={coach?.about_me}
                image={
                    coach.profile_photo && !coach.profile_photo.includes('noavatar.jpeg')
                        ? coach.profile_photo.startsWith('https://method.do/images')
                            ? `https://api.${coach.profile_photo.slice(8)}`
                            : `https://api.method.do/images/${coach.profile_photo}`
                        : coach.profile_photo
                }
            />
            <Container maxWidth="xl" sx={{my: 2}}>
                <div className="bg-white rounded p-3 px-4 w-100 mb-3">
                    <i className='bx bx-arrow-back'></i>
                    <Link to="/matching" className="text-decoration-none text-reset px-2 link-hover">
                        {t("Return to specialist selection")}
                    </Link>
                </div>

                <Row>
                    <Col xs={12} md={3}>
                        <div className={'position-sticky top-0'}>
                            <CoachPhoto coach={coach} loading={loading} setLoading={setLoading}/>
                            <Appointment coach={coach}/>
                        </div>
                    </Col>
                    <Col xs={12} md={9}>
                        <CoachInfo coach={coach} loading={loading} setLoading={setLoading}/>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Coach;