import React, {useEffect, useState} from 'react';
import customAxios from 'configs/axiosConfig';
import {Link, useLocation} from "react-router-dom";
import {Dropdown, Badge, Form} from "react-bootstrap";
import moment from "moment-timezone";
import AccountWrap from 'components/wrap/AccountWrap';
import Loading from 'components/wrap/Loading';
import {useTranslation} from 'react-i18next';
import {formatCurrency} from "utils/Currency";
import {useAuth} from 'contexts/AuthContext';

const Consultations = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [appointments, setAppointments] = useState([]);
    const [application, setApplication] = useState([]);
    const [loading, setloading] = useState(false);
    const {timeZone, locale, localeCode} = useAuth();
    const {t, i18n} = useTranslation();
    const [queryParams, setQueryParams] = useState({});
    const location = useLocation();

    useEffect(() => {
        const getQueryParams = () => {
            const searchParams = new URLSearchParams(location.search);
            const params = {};
            for (let param of searchParams) {  // param это массив [key, value]
                params[param[0]] = param[1];
            }
            return params;
        };
        setQueryParams(getQueryParams());

        if (queryParams.status === 'success') {
            setSuccessMessage(queryParams.message);
        } else if (queryParams.status === 'error') {
            setErrorMessage(queryParams.message);
        }

    }, [location]);

    useEffect(() => {
        // Установить локаль moment согласно текущему языку интерфейса
        moment.locale(i18n.language);
    }, [i18n.language]);

    const fetchConsultations = async () => {
        setloading(true);
        try {
            const response = await customAxios.get('https://api.method.do/api/user/consultations/');
            setAppointments(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Ошибка при получении консультаций:', error);
        } finally {
            setloading(false);
        }
    };

    const fetchApplication = async () => {
        setloading(true);
        try {
            const response = await customAxios.get('https://api.method.do/api/application/getall/', {params: {status: 'pending,waiting,scheduled,conducted'}});
            setApplication(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Ошибка при получении консультаций:', error);
        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        fetchConsultations();
    }, []);

    useEffect(() => {
        fetchApplication();
    }, []);

    const TimerDisplay = ({createdAt}) => {
        const [timeLeft, setTimeLeft] = useState('');

        useEffect(() => {
            // Объявление timerId перед использованием в setInterval и clearInterval
            let timerId;

            const updateTimer = () => {
                const endTime = moment(createdAt).add(20, 'minutes');
                const diffTime = endTime.diff(moment());
                const duration = moment.duration(diffTime);

                if (duration.asSeconds() <= 0) {
                    setTimeLeft('0:00');
                    clearInterval(timerId); // Очистка используя переменную timerId
                } else {
                    const minutes = duration.minutes();
                    const seconds = duration.seconds();
                    setTimeLeft(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
                }
            };

            updateTimer();  // Вызов при монтировании
            timerId = setInterval(updateTimer, 1000);  // Инициализация таймера

            return () => clearInterval(timerId);  // Очистка таймера при размонтировании компонента
        }, [createdAt]);

        return <span className={'ps-1'}>{timeLeft}</span>;
    };

    const handleCancelAppointment = async (appointmentId) => {
        if (!window.confirm(t('Are you sure you want to cancel this consultation?'))) {
            return;
        }
        setloading(true);
        try {
            const response = await customAxios.post(`https://api.method.do/api/matching/cancelappointment/${appointmentId}/`);

            if (response.data.status === "error") {
                setErrorMessage(response.data.message);
            } else {
                setAppointments(prevAppointments => prevAppointments.filter(appointment => appointment.id !== appointmentId));
                setSuccessMessage(t('Consultation successfully canceled'));
            }
        } catch (error) {
            console.error('Ошибка при отмене консультации:', error.response ? error.response.data : error.message);
            setErrorMessage(t('Error canceling consultation'));
        }
        setloading(false);
    };

    const handleCancelApplication = async (applicationId) => {
        if (!window.confirm(t('Would you like to cancel your consultation request?'))) {
            return;
        }
        setloading(true);
        try {
            const response = await customAxios.delete(`https://api.method.do/api/application/${applicationId}/`);

            if (response.data.status === "error") {
                setErrorMessage(response.data.message);
            } else {
                setApplication(prevApplication => prevApplication.filter(application => application.id !== applicationId));
                setSuccessMessage(t('Request for consultation successfully canceled'));
            }
        } catch (error) {
            console.error('Ошибка при отмене консультации:', error.response ? error.response.data : error.message);
            setErrorMessage(t('Error canceling consultation request'));
        }
        setloading(false);
    };


    return (
        <AccountWrap>
            <div>
                <h3 className="">{t('Appointment counseling')}</h3>
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                <>
                    {loading && <Loading/>}
                    {appointments.length === 0 ? (
                        <p>{t('You don\'t have an appointment for counseling')}</p>
                    ) : (appointments.length > 0 ? (
                        <>
                            {appointments.map((appointment) => (
                                <div key={appointment.id}
                                     className="card d-sm-flex flex-sm-row align-items-sm-center justify-content-between border-0 shadow-sm p-3 p-md-4 mb-4">
                                    <div className="d-flex align-items-center pe-sm-3">
                                        <div className="ps-3 ps-sm-4">

                                            <h6 className="mb-2">
                                                {moment(appointment.appointment_datetime).tz(timeZone).format("D MMMM,  HH:mm")}

                                                <span className="text-secondary fw-normal ps-1">
                                                        {moment(appointment.appointment_datetime).tz(timeZone).format("dddd")}
                                                    </span>
                                                <Badge variant="secondary" className="ms-2">
                                                    {t(appointment.status)}
                                                </Badge>
                                            </h6>

                                            <div className="fs-sm pb-2">
                                                <span className={'me-2'}>
                                                    {t('Cost')}: {Math.floor(appointment?.order.amount)}{' '}{formatCurrency(appointment?.order.currency)}
                                                </span>

                                                <Badge variant="info">
                                                    {t(appointment?.order.status)}
                                                </Badge>

                                                {appointment.order.status === 'pending' ? (
                                                    <TimerDisplay createdAt={appointment?.order.created_at}/>
                                                ) : null}

                                                <span className="ms-2">
                                                        <Link
                                                            to={`/order/${appointment?.order.id}/`}>{t('Open order')}</Link>
                                                    {appointment.order.status === 'pending' ? (
                                                        <Link to={`/payment/${appointment.order.id}/`}>
                                                            {t('Pay')}
                                                        </Link>
                                                    ) : null}
                                                    </span>
                                            </div>

                                            <div className="fs-sm pb-2">
                                                {t('Duration')}: {appointment.duration} {t('minutes')}<br/>
                                            </div>

                                            <div className="fs-sm">
                                                {t('Specialist')}: {' '}
                                                <Link to={`/coach/${appointment.expert_coach_id}/`}>
                                                    {`${appointment.expert.first_name} ${appointment.expert.last_name}`}
                                                </Link>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end pt-3 pt-sm-0">

                                        <Link className="btn btn-primary me-2"
                                              to={`/consultationsession/${appointment.id}`}>
                                            {t('Join')}
                                        </Link>

                                        <Form.Group>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                    {t('Actions')}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#/action-1">
                                                        {t('Add to Google Calendar')}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#/action-2" id="downloadIcsButton"
                                                                   data-appointment-id={appointment.id}>
                                                        {t('Add to iCal Calendar')}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => handleCancelAppointment(appointment.id)}
                                                        className="card-delete cancel-appointment"
                                                        data-id={appointment.id}>
                                                        {t('Cancel Appointment')}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>

                                    </div>
                                </div>
                            ))}
                        </>
                    ) : null)}

                </>

                {(application.length > 0 &&
                    <>
                        <h3 className="">{t('Requests for consultation')}</h3>
                        {application.map((application) => (
                            <div key={application.id}
                                 className="card d-sm-flex flex-sm-row align-items-sm-center justify-content-between border-0 shadow-sm p-3 p-md-4 mb-4">
                                <div className="d-flex align-items-center pe-sm-3">
                                    <div className="ps-3 ps-sm-4">

                                        <h6 className="mb-2">
                                            {t('Consultation request')}
                                            <Badge variant="secondary" className="ms-2">
                                                {t(application.status)}
                                            </Badge>
                                        </h6>

                                        <div className="fs-sm pb-2">
                                                <span className={'me-2'}>
                                                    {t('Cost')}: {Math.floor(application.order.amount)}{' '}{formatCurrency(application.order.currency)}
                                                </span>

                                            <Badge variant="info">
                                                {t(application.order.status)}
                                            </Badge>

                                            {application.order.status === 'pending' ? (
                                                <TimerDisplay createdAt={application.order.created_at}/>
                                            ) : null}

                                            <span className="ms-2">
                                                <Link to={`/order/${application.order.id}/`}>{t('Open order')}</Link>
                                                {application.order.status === 'pending' ? (
                                                    <Link className={'ms-2'} to={`/payment/${application.order.id}/`}>
                                                        {t('Pay')}
                                                    </Link>
                                                ) : null}
                                            </span>
                                        </div>

                                        <div className="fs-sm">
                                            {t('Specialist')}: {' '}
                                            <Link to={`/coach/${application.expert_coach_id}/`}>
                                                {`${application.expert.first_name} ${application.expert.last_name}`}
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                                <div className="d-flex justify-content-end pt-3 pt-sm-0">

                                    <Form.Group>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                {t('Actions')}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() => handleCancelApplication(application.id)}
                                                    className="card-delete cancel-appointment"
                                                    data-id={application.id}>
                                                    {t('Cancel')}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Form.Group>

                                </div>
                            </div>
                        ))}
                    </>
                )}

                <Link to={'/matching'} className={'btn btn-primary'}>{t('Find a Specialist')}</Link>
            </div>
        </AccountWrap>
    );
}

export default Consultations;
