import React, { useState } from "react";
import customAxios from 'configs/axiosConfig';
import Loading from 'components/wrap/Loading';
import { useAuth } from 'contexts/AuthContext.js';
import { useTranslation } from 'react-i18next';

const Profile = ({ user }) => {
    const { logout } = useAuth();
    const { t } = useTranslation();

    const deleteAccount = async () => {
        if (document.getElementById('delete-account').checked) {
            if (window.confirm(t("Are you sure you want to delete your account?"))) {
                try {
                    const response = await customAxios.post('https://api.method.do/api/user/delete_account/');

                    if (response.data.status === 'success') {
                        alert(t("Account successfully deleted."));
                        logout();
                    }
                } catch (error) {
                    alert(t("An error occurred while deleting the account."));
                    console.error(error);
                }
            }
        } else {
            alert(t("Please check the checkbox to confirm account deletion."));
        }
    };

    return (
        <>
            <h2 className="h5 text-primary pt-1 pt-lg-3 mt-4">{t("Delete account")}</h2>
            <p>{t("When you delete your account, your public profile is immediately deactivated. If you change your mind within 14 days, log in using your email address and password, and we will send you a link to reactivate your account.")}</p>
            <div className="form-check mb-4">
                <input type="checkbox" id="delete-account" className="form-check-input" />
                <label htmlFor="delete-account" className="form-check-label fs-base">{t("Yes, I want to delete my account")}</label>
            </div>
            <button type="button" className="btn btn-danger" onClick={deleteAccount}>{t("Delete account")}</button>
        </>
    );
}

export default Profile;
