import React, {useEffect, useRef} from 'react';
import OwnMessage from './OwnMessage';
import UserMessage from './UserMessage';
import moment from 'moment';
import {useWebSocket} from "../../../../contexts/WebSocketContext";
import {useTranslation} from "react-i18next";

const ChatMessages = ({messages, setMessages, currentUserId}) => {
    const messagesEndRef = useRef(null);
    const { websocket, currentMessageWS } = useWebSocket();
    const { t } = useTranslation();


    const groupedMessages = messages.reduce((acc, message) => {
        const date = moment(message.timestamp).format('D MMMM, YYYY');
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(message);
        return acc;
    }, {});


    return (
        <div className="card-body overflow-auto w-100 pb-0" style={{display: 'flex', flexDirection: 'column-reverse', height: '600px'}} ref={messagesEndRef}>
            {Object.entries(groupedMessages).sort((a, b) => new Date(b[0]) - new Date(a[0])).map(([date, messagesOfDay]) => (
                <div key={date} style={{marginTop: '20px'}}>
                    <div className="fs-sm text-muted text-center mb-3">{date}</div>
                    {messagesOfDay.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)).map(message => {
                        const isOwnMessage = message.sender.id === currentUserId;
                        const MessageComponent = isOwnMessage ? OwnMessage : UserMessage;
                        return <MessageComponent message={message} websocket={websocket}/>;
                    })}
                </div>
            ))}
        </div>
    );
};

export default ChatMessages;
