// axiosConfig.js

import axios from 'axios';
import Cookies from "js-cookie";
const cookieOptions = { domain: 'localhost', path: '/', secure: false };


function getAuthToken() {
    return Cookies.get('access_token')
}

// Функция для получения csrftoken из cookies
function getCSRFToken() {
    let csrftoken = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, 'csrftoken='.length) === 'csrftoken=') {
                csrftoken = decodeURIComponent(cookie.substring('csrftoken='.length));
                break;
            }
        }
    }
    return csrftoken;
}

// Настройка интерсептора запроса
axios.interceptors.request.use(config => {
    const csrftoken = getCSRFToken();
    const authToken = getAuthToken();
    if (csrftoken) {
        config.headers['X-CSRFToken'] = csrftoken;
    }
    if (authToken) {
        config.headers['Authorization'] = `Bearer ${authToken}`;
    }
    config.withCredentials = true;
    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    return response;
}, async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
            const response = await axios.post('https://api.method.do/api/token/refresh/', {
                refresh: Cookies.get('refresh_token')
            });

            Cookies.set('access_token', response.data.access, cookieOptions);
            Cookies.set('isAuthenticated', true, cookieOptions);

            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
            return axios(originalRequest);

        } catch (refreshError) {
            console.error("Ошибка обновления токена:", refreshError);
        }
    }
    return Promise.reject(error);
});


export default axios;
