import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Box, Typography, Button } from '@mui/material';

const QuizViews = () => {
    const { t } = useTranslation();

    return (
        <Container maxWidth="xl" sx={{overflow: 'hidden', borderRadius: { xs: 0, md: 4 } }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0.1,
                }}
                className="bg-gradient-primary"
            />
            <Box sx={{ position: 'relative', textAlign: 'center', p: 4 }}>

                <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                    {t("Get a personal coach")}
                </Typography>

                <Typography sx={{ mb: 3 }}>
                    {t("Take the assessment (a short survey), and we will recommend coaches based on your needs")}
                </Typography>

                <Button
                    component={RouterLink}
                    to="/quiz"
                    variant="contained"
                >
                    {t("Take the assessment")}
                </Button>


            </Box>
        </Container>
    );
}

export default QuizViews;
