import React, { useRef, useEffect, useState } from 'react';
import customAxios from "../../../../configs/axiosConfig";
import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded";
import { Button } from '@mui/material';

const ZoomMeetingNew = ({ sessionID }) => {
    const meetingSDKElementRef = useRef(null);
    const [zoomCredentials, setZoomCredentials] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (!zoomCredentials) {
            setLoading(true);
            customAxios.get(`https://api.method.do/api/appointment/sessions/${sessionID}/generate-zoom-token-meeting/`)
                .then(response => {
                    setZoomCredentials({
                        sdkKey: response.data.sdkKey,
                        jwtToken: response.data.jwt,
                        MeetingNumber: response.data.meeting_number,
                        userName: response.data.user_name,
                        userEmail: 'info@method.do',
                        zakToken: response.data.zak,
                    });
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setError('Failed to fetch Zoom credentials.');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [sessionID, zoomCredentials]);

    const initZoom = () => {
        if (zoomCredentials) {
            const client = ZoomMtgEmbedded.createClient();
            client.init({
                zoomAppRoot: meetingSDKElementRef.current,
                language: 'en-US',
                patchJsMedia: true
            }).then(() => {
                client.join({
                    sdkKey: zoomCredentials.sdkKey,
                    signature: zoomCredentials.jwtToken,
                    meetingNumber: zoomCredentials.MeetingNumber,
                    password: '',
                    userName: zoomCredentials.userName,
                    userEmail: zoomCredentials.userEmail,
                }).then(() => {
                    console.log('joined successfully');
                }).catch(error => {
                    console.error('Error joining Zoom meeting:', error);
                    setError('Failed to join Zoom meeting.');
                });
            }).catch(error => {
                console.error('Error initializing Zoom meeting:', error);
                setError('Failed to initialize Zoom meeting.');
            });
        }
    };

    return (
        <div>
            <Button variant="contained" onClick={initZoom} disabled={loading || !zoomCredentials}>
                Start Zoom Meeting
            </Button>
            {error && <div style={{ color: 'red' }}>{error}</div>}
            <div ref={meetingSDKElementRef} id="meetingSDKElement"></div>
        </div>
    );
};

export default ZoomMeetingNew;
