import React, {useState, useEffect} from 'react';
import customAxios from 'configs/axiosConfig';
import Loading from 'components/wrap/Loading';
import {useParams, Link} from 'react-router-dom';
import AccountWrap from 'components/wrap/AccountWrap.js';
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {formatCurrency} from "utils/Currency";

const Order = () => {
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const orderId = useParams().id;
    const { t } = useTranslation();

    useEffect(() => {
        const fetchOrder = async () => {
            setLoading(true);
            try {
                const response = await customAxios.get(`https://api.method.do/api/orders/getorder/${orderId}/`);
                setOrder(response.data.order);
                setTransactions(response.data.transactions);
            } catch (error) {
                console.error(t('Error fetching order:'), error);
            } finally {
                setLoading(false);
            }
        };
        fetchOrder();
    }, [orderId]);

    if (loading) {
        return <Loading message={t("Loading order...")}/>;
    }

    if (!order) {
        return <div>{t("Order not found.")}</div>;
    }

    return (
        <AccountWrap>
            <div>
                <h1>{t("Order details")}# {order.id}</h1>
                <p>{t("Order status")}: {t(order.status)}</p>
                <p>{t("Order date")}: {format(new Date(order.created_at), "d MMMM HH:mm", {locale: ru})}</p>
                <p>{t("Cost")}: {Math.floor(order.amount)} {formatCurrency(order.currency)}</p>

                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("Transaction ID")}</TableCell>
                                <TableCell>{t("Transaction date")}</TableCell>
                                <TableCell>{t("Amount")}</TableCell>
                                <TableCell>{t("Currency")}</TableCell>
                                <TableCell>{t("Status")}</TableCell>
                                <TableCell>{t("Payment system transaction ID")}</TableCell>
                                <TableCell>{t("Payment system")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions.length > 0 ? transactions.map((transaction) => (
                                <TableRow key={transaction.id}>
                                    <TableCell component="th" scope="row">
                                        {transaction.id}
                                    </TableCell>
                                    <TableCell>
                                        {format(new Date(transaction.created_at), "d MMMM 'at' HH:mm", {locale: ru})}
                                    </TableCell>
                                    <TableCell>{Math.floor(transaction.amount)}</TableCell>
                                    <TableCell>{formatCurrency(transaction.currency)}</TableCell>
                                    <TableCell>{t(transaction.status)}</TableCell>
                                    <TableCell>{transaction.transaction_id}</TableCell>
                                    <TableCell>{transaction.payment_system}</TableCell>
                                </TableRow>
                            )) : (
                                <TableRow>
                                    <TableCell colSpan={7}>{t("No transactions")}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {order.status === 'pending' && (
                    <div className="mt-3">
                        <Link to={`/payment/${order.id}`} className={'btn btn-primary'}>
                            {t("Pay order")}
                        </Link>
                    </div>
                )}
            </div>
        </AccountWrap>
    );
};

export default Order;
