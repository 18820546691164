import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from "../../contexts/AuthContext";
import Loading from "components/wrap/Loading";
import customAxios from "../../configs/axiosConfig";

const AuthRedirect = () => {
    const navigate = useNavigate();
    const {login} = useAuth();
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const hash = window.location.hash.substr(1);
        const params = new URLSearchParams(hash);
        const accessToken = params.get('access_token');
        const refreshToken = params.get('refresh_token');

        if (accessToken && refreshToken) {
            // Сохраняем токены в localStorage
            login({
                access_token: accessToken,
                refresh_token: refreshToken,
                is_authenticated: true,
            });

            // Очищаем фрагмент URL, чтобы токены не оставались в адресной строке
            window.location.hash = '';

            console.log('Login successful');
            // Перенаправляем на главную страницу
            navigate('/home');
            return;

        } else {
            // Если токены не найдены, перенаправляем на страницу входа
            console.log('Login failed');
            navigate('/login');
            return;

        }
    }, [navigate]);

    return (
        <div>
            {loading && <Loading message={'Redirecting...'}/>}
        </div>
    );
};

export default AuthRedirect;
