import React, {useState} from 'react';
import {Form, InputGroup, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import CustomAxios from 'configs/axiosConfig';
import Skeleton from '@mui/material/Skeleton';


const PhoneNumberForm = ({userPhone, setUserPhone}) => {
    const {t} = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleButtonClick = async () => {
        if (isEditing) {
            setLoading(true);
            setError(null);
            setSuccess(false);
            // Отправляем данные на сервер
            try {
                await CustomAxios.post('https://api.method.do/api/user/edit/', {phone: userPhone});
                // После успешного обновления отключаем режим редактирования
                setIsEditing(false);
                setSuccess(true);
            } catch (error) {
                console.error('Ошибка при обновлении номера телефона:', error);
                // Обработка ошибки
            } finally {
                setLoading(false);
            }
        } else {
            // Включаем режим редактирования
            setIsEditing(true);
        }
    };

    return (
        <Form.Group className={`mb-3`}>
            <Form.Label>{t('Is this phone number up to date?')}</Form.Label>
            {success && <div className="text-success mb-2">{t('Phone number updated successfully')}</div>}
            <InputGroup>
                {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={38}/>
                ) : (
                    <>
                        <Form.Control
                            type="text"
                            placeholder={t('Enter your phone number')}
                            value={userPhone}
                            onChange={(e) => setUserPhone(e.target.value)}
                            className={'border-end-0'}
                            readOnly={!isEditing}
                        />
                        <Button variant="outline-secondary" className={'rounded-end-2 border-start-0'}
                                onClick={handleButtonClick} disabled={loading}>
                            {t(isEditing ? 'Save' : 'Change')}
                        </Button>
                    </>
                )}

            </InputGroup>
            <p className={'fs-sm mt-1 text-body'}>
                {t('A specialist will contact you on this number via WhatsApp to arrange a time for the session')}
            </p>
            {
                error && <div className="text-danger mt-2">{error}</div>
            }
        </Form.Group>
    )
        ;
};

export default PhoneNumberForm;
