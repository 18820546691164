import React from 'react';
import DOMPurify from 'dompurify';
import { Box } from '@mui/material';

const SafeHtmlDisplay = ({ htmlContent }) => {
    const cleanHtmlContent = DOMPurify.sanitize(htmlContent);

    return (
        <Box dangerouslySetInnerHTML={{ __html: cleanHtmlContent }} />
    );
};

export default SafeHtmlDisplay;
