// MyPrograms.js страница с программами
import React, {useEffect, useState} from 'react';
import {Box, Typography, Grid, Container} from '@mui/material';
import {useTranslation} from 'react-i18next';
import CourseCard from './components/CourseCard';
import customAxios from "../../../../configs/axiosConfig";
import Skeleton from '@mui/material/Skeleton';


const MyPrograms = ({mt= 2, mb = 2}) => {
    const {t} = useTranslation();
    const [userCourseProgress, setUserCourseProgress] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchUserCourseProgress = async () => {
        setLoading(true)
        try {
            const sessionData = await customAxios.get(`https://api.method.do/api/UserCourseProgress/`);
            setUserCourseProgress(sessionData.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchUserCourseProgress();
    }, []);

    return (
        <>
            {/* My programs */}
            {userCourseProgress && userCourseProgress.length > 0 && (
                <Container maxWidth="fluid" sx={{backgroundColor: 'background.paper', py: 3, mt, mb}} disableGutters>
                    <Container maxWidth="xl">
                        <Typography variant="h4" gutterBottom>
                            {t('My programs')}
                        </Typography>

                        <Grid container spacing={2}>
                            {loading ? (
                                Array.from(new Array(4)).map((item, index) => (
                                    <Grid item xs={12} md={3} key={index}>
                                        <Skeleton variant="rectangular" height={240} sx={{borderRadius: 4}}/>
                                        <Box sx={{pt: 0.5}}>
                                            <Skeleton/>
                                            <Skeleton width="60%"/>
                                        </Box>
                                    </Grid>
                                ))
                            ) : (
                                userCourseProgress.length > 0 && userCourseProgress.map(userCourseProgress => (
                                    userCourseProgress.course && userCourseProgress.course.id && (
                                        <Grid item xs={12} md={3} key={userCourseProgress.course.id}>
                                            <CourseCard
                                                course={userCourseProgress.course}
                                                url={userCourseProgress.has_access ? `/study/${userCourseProgress.course.id}` : `/program/${userCourseProgress.course.id}`}
                                            />
                                        </Grid>
                                    )
                                ))
                            )}
                        </Grid>
                    </Container>
                </Container>
            )}
        </>
    );
}

export default MyPrograms;

