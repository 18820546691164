import React, { useState, useEffect } from 'react';
import { CardActionArea, CardContent, CardMedia, Typography, Box, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';

const CourseCard = ({ course, url }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const img = new Image();
        img.src = course.imagePreview;
        img.onload = () => setLoading(false);
    }, [course.imagePreview]);

    return (
        <>
            <CardActionArea
                component={Link}
                to={url}
                sx={{
                    '& .MuiCardActionArea-focusHighlight': {
                        display: 'none'
                    }
                }}
            >
                <Box sx={{ position: 'relative' }}>
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={140} sx={{ borderRadius: 4 }} />
                    ) : (
                        <CardMedia
                            component="img"
                            height="140"
                            image={course.imagePreview}
                            alt={course.title}
                            sx={{
                                borderRadius: 4,
                            }}
                        />
                    )}
                </Box>
                <CardContent sx={{ padding: 1 }}>
                    <Typography variant="h6" component="h2">
                        {course.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {course.expert.first_name} {course.expert.last_name}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </>
    );
}

export default CourseCard;
