import React, {useState, useEffect} from 'react';
import AccountWrap from 'components/wrap/AccountWrap.js';
import {useAuth} from 'contexts/AuthContext';
import customAxios from 'configs/axiosConfig';
import Loading from 'components/wrap/Loading';
import { useTranslation } from 'react-i18next';

import DeleteAccount from "./deleteAccount";
import Notifications from "./notifications";

const Profile = () => {
    const {t} = useTranslation();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('userData')) || {});
    const [formData, setFormData] = useState({
        email: user.email || '',
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        phone: user.phone || '',
        date_of_birth: user.date_of_birth || '',
    });
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    const updateProfileForm = async () => {
        try {
            setLoading(true);
            const response = await customAxios.get('https://api.method.do/api/user/details/');
            if (response.data.status === 'success') {
                const updatedUser = response.data.user;
                setFormData({
                    email: updatedUser.email || '',
                    first_name: updatedUser.first_name || '',
                    last_name: updatedUser.last_name || '',
                    phone: updatedUser.phone || '',
                    date_of_birth: updatedUser.date_of_birth || '',
                });
                setUser(updatedUser);
            } else {
                setErrorMessage(t('Error:') + ' ' + response.data.message);
            }
        } catch (error) {
            console.error('Ошибка при получении профиля:', error);
            setErrorMessage(t('Failed to load profile') + ': ' + error.message);
        }
        setLoading(false);
    }

    useEffect(() => {
        updateProfileForm();
    }, []);


    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleEdit = () => {
        setEditMode(true);
    }

    const handleCancel = () => {
        setEditMode(false);
        setFormData({
            email: user.email || '',
            first_name: user.first_name || '',
            last_name: user.last_name || '',
            phone: user.phone || '',
            date_of_birth: user.date_of_birth || '',
        });
    }

    const handleSave = async () => {
        setEditMode(false);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            setLoading(true);
            const response = await customAxios.post('https://api.method.do/api/user/editprofile/', formData);
            if (response.data.status === 'success') {
                const updatedUser = response.data.user;
            } else {
                setErrorMessage(t('Error:') + ' ' + response.data.message);
            }
        } catch (error) {
            console.error('Ошибка при сохранении профиля:', error);
            setErrorMessage(t('Failed to save profile') + ': ' + error.message);
        }
        setLoading(false);
    }


    return (
        <AccountWrap>
            <div>
                <h1 className="h2 pt-xl-1 pb-3">{t("User Profile")}</h1>
                <h2 className="h5 text-primary mb-4">{t("Account Data")}</h2>
                <div className="col-sm-6 mb-4">
                    <label htmlFor="email" className="form-label fs-base">{t("Email for login")}</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control form-control-lg"
                        value={user.email}
                        readOnly
                    />
                    <div className="invalid-feedback">{t("Please provide a valid email address!")}</div>
                </div>
                <hr />
                <h2 className="h5 text-primary my-4">{t("Basic Information")}</h2>

                {loading ? (<Loading/>) : (
                    <form className="needs-validation pb-3 pb-lg-4" id="profileForm" noValidate>
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        <div className="row pb-2">
                            <div className="col-sm-6 mb-4">
                                <label htmlFor="fn" className="form-label fs-base">{t("First Name")}</label>
                                <input
                                    type="text"
                                    id="fn"
                                    name="first_name"
                                    className="form-control form-control-lg"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                    readOnly={!editMode}
                                />
                                <div className="invalid-feedback">{t("Please enter your first name!")}</div>
                            </div>
                            <div className="col-sm-6 mb-4">
                                <label htmlFor="sn" className="form-label fs-base">{t("Last Name")}</label>
                                <input
                                    type="text"
                                    id="sn"
                                    name="last_name"
                                    className="form-control form-control-lg"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                    readOnly={!editMode}
                                />
                                <div className="invalid-feedback">{t("Please enter your last name!")}</div>
                            </div>
                            <div className="col-sm-6 mb-4">
                                <label htmlFor="phone" className="form-label fs-base">{t("Phone Number")} <small
                                    className="text-muted">*</small></label>
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    className="form-control form-control-lg"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    readOnly={!editMode}
                                />
                            </div>
                            <div className="col-sm-6 mb-4">
                                <label htmlFor="phone" className="form-label fs-base">{t("Date of Birth")} <small
                                    className="text-muted">*</small></label>
                                <input
                                    type="date"
                                    id="date_of_birth"
                                    name="date_of_birth"
                                    className="form-control form-control-lg"
                                    value={formData.date_of_birth}
                                    onChange={handleChange}
                                    readOnly={!editMode}
                                />
                            </div>
                        </div>

                        {editMode ? (
                            <div className="p-b" id="saveCancelButtons">
                                <button type="button" id="cancelButton" className="btn btn-secondary me-2"
                                        onClick={handleCancel}>{t("Cancel")}
                                </button>
                                <button type="button" id="saveButton" className="btn btn-primary"
                                        onClick={handleSave}>{t("Save Changes")}
                                </button>
                            </div>
                        ) : (
                            <div className="btn-group p-b" id="editButtonGroup">
                                <button type="button" id="editButton" className="btn btn-secondary me-3"
                                        onClick={handleEdit}>{t("Edit Profile")}
                                </button>
                            </div>
                        )}
                    </form>
                )}

                <hr />

                <Notifications user={user} setUser={setUser} />
                <DeleteAccount user={user} />
            </div>
        </AccountWrap>
    );
}

export default Profile;
