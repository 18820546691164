import React, { useState } from 'react';
import { Box, Menu, MenuItem, Typography, FormControl, Select, InputLabel
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const LanguageCurrencySelector = () => {
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currency, setCurrency] = useState('USD');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (event) => {
        const language = event.target.value;
        i18n.changeLanguage(language);
        handleClose();
    };

    const handleCurrencyChange = (event) => {
        setCurrency(event.target.value);
    };

    // Функция для отображения текущего языка в виде текста
    const getLanguageText = () => {
        switch (i18n.language) {
            case 'en':
                return t('EN');
            case 'ru':
                return t('RU');
            // Добавьте другие языки по мере необходимости
            default:
                return t('EN');
        }
    };

    return (
        <>
            <Typography
                variant="body1"
                onClick={handleClick}
                sx={{
                    padding: 1,
                    cursor: 'pointer',
                    flexGrow: 0,
                    marginRight: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 3
                }}
            >
                {`${getLanguageText()}`} <ExpandMoreIcon />
            </Typography>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{ minWidth: '200px' }}
            >
                <Box sx={{ p: 2 }}>
                    <FormControl fullWidth variant="outlined" sx={{ mt: 1, mb: 2 }}>
                        <InputLabel>{t('Language')}</InputLabel>
                        <Select
                            value={i18n.language || 'en'}
                            onChange={handleLanguageChange}
                            label={t('Language')}
                        >
                            <MenuItem value="en">{t('English')}</MenuItem>
                            <MenuItem value="ru">{t('Russian')}</MenuItem>
                            {/* Добавьте другие языки по мере необходимости */}
                        </Select>
                    </FormControl>
                    {/*
                    <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
                        <InputLabel>{t('Currency')}</InputLabel>
                        <Select
                            value={currency}
                            onChange={handleCurrencyChange}
                            label={t('Currency')}
                        >
                            <MenuItem value="USD">{t('USD')}</MenuItem>
                            <MenuItem value="EUR">{t('EUR')}</MenuItem>
                        </Select>
                    </FormControl>*/}

                </Box>
            </Menu>
        </>
    );
};

export default LanguageCurrencySelector;
