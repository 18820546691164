import {createTheme} from '@mui/material/styles';

const baseTheme = createTheme({
    typography: {
        fontFamily: 'Manrope, Arial, sans-serif',
    },
    palette: {
        primary: {
            main: '#007ccb',
            dark: '#005bb5',
        },
        secondary: {
            main: '#898989'
        },
        border: {
            main: '#e2e5f1',
            secondary: '#d4d7e5',
            input: '#E0E3E7',
        },
        light: {
            white: '#ffffff',
            main: '#eff2fc'
        },
        text: {
            main: '#343a34',
            primary: '#212529',
            secondary: '#898989'
        },
        background: {
            default: '#ffffff',
            paper: '#f8f9fa',
            light: '#eff2fc',
            surface: '#fbfefc',
            primary: '#ebf2f9',
        },
    },
});

const theme = createTheme(baseTheme, {
    typography: {
        fontFamily: 'Manrope, Arial, sans-serif',

        h1: {
            fontWeight: 600,
            color: baseTheme.palette.text.primary,
        },
        h2: {
            fontWeight: 600,
            color: baseTheme.palette.text.primary,
        },
        h3: {
            fontWeight: 600,
            color: baseTheme.palette.text.primary,
        },
        h4: {
            fontWeight: 600,
            color: baseTheme.palette.text.primary,
        },
        h5: {
            fontWeight: 600,
            color: baseTheme.palette.text.primary,
        },
        h6: {
            fontWeight: 600,
            color: baseTheme.palette.text.primary,
        },
        subtitle1: {
            fontWeight: 600,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({theme}) => ({
                    borderRadius: '999px',
                    padding: '0.625rem 2rem',
                    fontWeight: 600,
                    boxShadow: 'none',
                    textTransform: 'none',
                }),
                containedPrimary: {
                    color: 'white',
                    '&:hover': {
                        backgroundColor: baseTheme.palette.primary.dark,
                        color: 'white',
                        boxShadow: 'none',
                    },
                    '&:active': {
                        backgroundColor: baseTheme.palette.primary.dark,
                    },
                },
                sizeLarge: {
                    padding: '1.25rem 3rem',
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderColor: baseTheme.palette.border.input,
                    '&.MuiInput-root': {
                        marginTop: 0,
                    },
                },
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: '#898989',
                    },
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&::before': {
                        borderBottom: 'none',
                        transition: 'none',
                    },
                    '&::after': {
                        borderBottom: 'none',
                    },
                    '&:hover:not(.Mui-disabled, .Mui-error)::before': {
                        borderBottom: 'none',
                    },
                },
                input: {
                    borderRadius: 4,
                    position: 'relative',
                    backgroundColor: baseTheme.palette.background.white,
                    border: '1px solid',
                    borderColor: baseTheme.palette.border.input,
                    fontSize: 16,
                    width: '100%',
                    padding: '10px 12px',
                    transition: ({transitions}) => transitions.create([
                        'border-color', 'background-color', 'box-shadow'
                    ], {
                        duration: transitions.duration.short
                    }),
                    '&:focus': {
                        boxShadow: ({palette, alpha}) => `${alpha(palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                        '&::after': {
                            content: 'none',
                        },
                        '&::before': {
                            content: 'none',
                        }
                    },
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    position: 'relative',
                    backgroundColor: baseTheme.palette.background.white,
                    border: '1px solid',
                    borderColor: '#d4d7e5',
                    fontSize: 16,
                    width: 'auto',
                    '&:hover': {
                        border: '1px solid',
                        borderColor: '#d4d7e5',
                    },
                    '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#d4d7e5'
                    },
                    // Состояние фокуса
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#d4d7e5'
                    }
                },
                notchedOutline: {
                    border: 0,
                },
                input: {
                    padding: '10px 12px',
                    '&::placeholder': {
                        opacity: 1,
                        color: '#898989',
                    },
                },
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    borderColor: '#d4d7e5',
                    borderRadius: '0.375rem',
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#565973',
                    fontSize: '1rem',
                    fontWeight: 600,
                    position: 'relative',
                    transform: 'none',
                    paddingBottom: '0.5rem',
                    '&.Mui-focused': {
                        color: '#565973',
                    }
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
                        padding: 0,
                    },
                },
                inputRoot: {
                    padding: '10px 12px',
                    '& .MuiAutocomplete-input': {
                        padding: '10px 12px',
                    }
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
          font-family: 'Manrope';
          font-style: normal;
          font-display: swap;
          src: local('Manrope'), local('Manrope-Regular'), url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700&display=swap') format('woff2');
        }
      `
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(50, 56, 52, 0.25)',
                    backdropFilter: 'blur(8px)',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: baseTheme.palette.background.default,
                    borderRadius: 0,
                    boxShadow: 'none',
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: baseTheme.palette.background.default,
                    borderRadius: '0.5rem',
                    boxShadow: 'none',
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: baseTheme.palette.background.default,
                }
            }
        }
    }
});

theme.typography.h2 = {
    ...theme.typography.h2,
    [theme.breakpoints.up('xs')]: {
        fontSize: '2.25rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '3.75rem',
    },
};

theme.typography.h3 = {
    ...theme.typography.h3,
    [theme.breakpoints.up('xs')]: {
        fontSize: '1.68rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
    },
};

theme.typography.h4 = {
    ...theme.typography.h4,
    [theme.breakpoints.up('xs')]: {
        fontSize: '1.435rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
    },
}

theme.typography.h5 = {
    ...theme.typography.h5,
    [theme.breakpoints.up('xs')]: {
        fontSize: '1.25rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.25rem',
    },
};

theme.typography.h6 = {
    ...theme.typography.h6,
    [theme.breakpoints.up('xs')]: {
        fontSize: '1rem',
        lineHeight: '1.5',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
    },
};


export default theme;
