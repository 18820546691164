import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from 'react-router-dom';
import customAxios from "../../../../configs/axiosConfig";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid, Drawer } from '@mui/material';
import Lesson from './components/Lesson';
import LessonsList from './components/LessonsList';

const Study = () => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [course, setCourse] = useState(null);
    const [currentLesson, setCurrentLesson] = useState(null);
    const {id: courseId} = useParams();
    const [menuOpen, setMenuOpen] = useState(false);
    const [userCourseProgress, setUserCourseProgress] = useState(null);
    const [userLessonsProgress, setUserLessonsProgress] = useState([]);
    const [hasAccess, setHasAccess] = useState(false);
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    const fetchCourseData = async () => {
        setLoading(true);
        try {
            const response = await customAxios.get(`https://api.method.do/api/course/${courseId}/access/`);
            const {course, user_course_progress, user_lessons_progress, has_access} = response.data;

            setCourse(course);
            setHasAccess(has_access);

            if (has_access) {
                setUserCourseProgress(user_course_progress);
                setUserLessonsProgress(user_lessons_progress);
                currentLesson ? setCurrentLesson(user_lessons_progress.find(lesson => lesson.lesson.id === currentLesson.lesson.id)) : setCurrentLesson(user_lessons_progress[0]);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchCourseData();
    }, [courseId]);


    return (
        <Grid container>
            <Grid item xs={12} md={menuOpen ? 8 : 12} sx={{
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                })
            }}>
                <Lesson
                    loading={loading}
                    fetchCourseData={fetchCourseData}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    currentLesson={currentLesson}
                    setCurrentLesson={setCurrentLesson}
                    userCourseProgress={userCourseProgress}
                    userLessonsProgress={userLessonsProgress}
                />
            </Grid>
            <Drawer
                variant={isMdUp ? "persistent" : "temporary"}
                anchor="right"
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                sx={{
                    width: {md: menuOpen ? '33.33%' : 0},
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: {xs: '100%', sm: 400, md: '33.33%'},
                        boxSizing: 'border-box',
                    },
                }}
            >
                <LessonsList
                    loading={loading}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    hasAccess={hasAccess}
                    currentLesson={currentLesson}
                    setCurrentLesson={setCurrentLesson}
                    userCourseProgress={userCourseProgress}
                    userLessonsProgress={userLessonsProgress}
                />

            </Drawer>
        </Grid>
    );
};

export default Study;
