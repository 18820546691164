import React, { useState, useEffect, useRef } from 'react';

const TextBlock = ({ text }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const contentRef = useRef(null);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const removeInlineStyles = (parentElement) => {
        if (parentElement) {
            // Удаление inline-стилей
            const elementsWithStyle = parentElement.querySelectorAll('[style]');
            elementsWithStyle.forEach(element => {
                element.removeAttribute('style');
            });

            // Удаление пустых <p> элементов
            const pElements = parentElement.querySelectorAll('p');
            pElements.forEach(p => {
                if (p.innerHTML.trim() === '' || p.innerHTML === '&nbsp;') {
                    p.remove();
                }
            });
            // Удаление пустых <div> элементов
            const divElements = parentElement.querySelectorAll('div');
            divElements.forEach(div => {
                if (div.innerHTML.trim() === '' || div.innerHTML === '&nbsp;') {
                    div.remove();
                }
            });
        }
    };

    useEffect(() => {
        if (contentRef.current) {
            removeInlineStyles(contentRef.current);
        }
    }, [text, isExpanded]);

    return (
        <div>
            <div
                ref={contentRef}
                style={{ maxHeight: isExpanded ? 'none' : '4.8em', overflow: 'hidden' }}
                dangerouslySetInnerHTML={{ __html: text }}
            />
            <button onClick={toggleExpand} className="btn btn-link p-0 text-decoration-none">
                {isExpanded ? 'Скрыть' : 'Читать полностью'}
            </button>
        </div>
    );
};

export default TextBlock;
