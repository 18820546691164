// Courses.js страница с курсами

import React, {useEffect, useState} from 'react';
import {Box, Typography, Grid, Container} from '@mui/material';
import {useTranslation} from 'react-i18next';
import CourseCard from './components/CourseCard';
import customAxios from "../../../../configs/axiosConfig";
import Skeleton from '@mui/material/Skeleton';
import MyPrograms from './MyPrograms';
import SEO from "../../../../configs/SEO";


const Programs = () => {
    const {t} = useTranslation();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchCourses = async () => {
        setLoading(true)
        try {
            const sessionData = await customAxios.get(`https://api.method.do/api/courses/`);
            setCourses(sessionData.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchCourses();
    }, []);

    return (
        <>
            <SEO
                title="Comprehensive Psychology Programs and Courses - Learn Psychology with Experts"
                description="Unlock your potential with Method's advanced programs and courses. Tailored to meet your needs, our curriculum is designed to help you achieve your goals. Start your journey to success today with Method's expert guidance and comprehensive resources."
                image="https://s3.timeweb.cloud/353db462-31b45b5a-a72b-45e7-89b8-59c51b99c932/images/add_coach.jpg"
            />

            {/* My programs */}
            <MyPrograms mt={0} mb={0} />

            <Container maxWidth="xl" sx={{ py: 3 }}>
                <Typography variant="h4" gutterBottom>
                    {t('Programs')}
                </Typography>

                <Grid container spacing={2}>
                    {loading ? (
                        Array.from(new Array(8)).map((item, index) => (
                            <Grid item xs={12} md={3} key={index}>
                                <Skeleton variant="rectangular" height={240} sx={{borderRadius: 4}}/>
                                <Box sx={{pt: 0.5}}>
                                    <Skeleton/>
                                    <Skeleton width="60%"/>
                                </Box>
                            </Grid>
                        ))
                    ) : (
                        courses.map(course => (
                            <Grid item xs={12} md={3} key={course.id}>
                                <CourseCard course={course} url={`/program/${course.id}`} />
                            </Grid>
                        ))
                    )}
                </Grid>
            </Container>
        </>
    );
}

export default Programs;

