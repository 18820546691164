// PageWrapper.js
import React from 'react';
import Sidebar from 'app/account/components/sidebar';
import {Col, Container, Row} from "react-bootstrap";

const AccountWrap = ({children}) => {

    return (
        <Container className={`h-100`}>
            <Row className={`h-100`}>
                <Sidebar/>
                <Col xs={12} md={8} className={'p-4 m-2'}>
                    {children}
                </Col>
            </Row>
        </Container>
    );
};

export default AccountWrap;
