import React, { useEffect, useState } from 'react';
import customAxios from 'configs/axiosConfig';
import { useAuth } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment-timezone';
import AccountWrap from 'components/wrap/AccountWrap.js';
import { Box, CircularProgress, Typography } from '@mui/material';
import {formatCurrency} from "utils/Currency";

const Orders = () => {
    const { t } = useTranslation();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const { timeZone, locale, localeCode } = useAuth();

    const fetchOrders = async () => {
        setLoading(true);
        try {
            const sessionData = await customAxios.get(`https://api.method.do/api/orders/`);
            setOrders(sessionData.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'product_name', headerName: t('Product Name'), width: 150, renderCell: (params) => <a href={`/order/${params.row.id}`}>{params.value}</a> },
        { field: 'amount', headerName: t('Amount'), width: 110 },
        { field: 'currency', headerName: t('Currency'), width: 110, valueFormatter: ({ value }) => `${formatCurrency(value)}`  },
        { field: 'status', headerName: t('Status'), width: 150, valueFormatter: ({ value }) => t(value) },
        { field: 'created_at', headerName: t('Created At'), valueFormatter: ({ value }) => moment(value).tz(timeZone).format('YYYY-MM-DD HH:mm:ss') },
        {
            field: 'description',
            headerName: t('Description'),
            width: 300,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.5em', maxHeight: '4.5em' }}>
                    {params.value}
                </div>
            ),
        },
    ];

    return (
        <AccountWrap>
            <Box sx={{ height: 600, width: '100%' }}>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Typography variant="h4" gutterBottom>
                            {t('Orders')}
                        </Typography>
                        <DataGrid
                            rows={orders}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            checkboxSelection
                            disableSelectionOnClick
                        />
                    </>
                )}
            </Box>
        </AccountWrap>
    );
};

export default Orders;