import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Avatar,
    Box,
    useMediaQuery,
    Typography,
    Container,
    Divider,
    ListItemIcon
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useAuth} from 'contexts/AuthContext.js';
import {useTranslation} from 'react-i18next';

import MenuIcon from '@mui/icons-material/Menu';
import ChatIcon from '@mui/icons-material/Chat';
import EventNoteIcon from '@mui/icons-material/EventNote';
import HistoryIcon from '@mui/icons-material/History';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';
import PaymentIcon from '@mui/icons-material/Payment';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import LogoutIcon from '@mui/icons-material/Logout';

import logo from 'static/logo/logo-black.svg';
import noAvatar from '../../static/images/noavatar.jpeg';
import MobileMenu from "./MobileMenu";
import Settings from "../../utils/Settings";

const Navbar = () => {
    const {user, logout, isAuthenticated, isAppSubdomain} = useAuth();
    const {t} = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);


    const UserMenu = () => (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            sx={{
                backGroundColor: 'white',
            }}
        >
            <MenuItem component={Link} to="/chat" onClick={handleClose}>
                <ListItemIcon>
                    <ChatIcon fontSize="small"/>
                </ListItemIcon>
                {t("Messages")}
            </MenuItem>
            <MenuItem component={Link} to="/consultations" onClick={handleClose}>
                <ListItemIcon>
                    <EventNoteIcon fontSize="small"/>
                </ListItemIcon>
                {t("My consultations")}
            </MenuItem>
            <MenuItem component={Link} to="/history" onClick={handleClose}>
                <ListItemIcon>
                    <HistoryIcon fontSize="small"/>
                </ListItemIcon>
                {t("Consultation history")}
            </MenuItem>
            <MenuItem component={Link} to="/orders" onClick={handleClose}>
                <ListItemIcon>
                    <ShoppingCartIcon fontSize="small"/>
                </ListItemIcon>
                {t("My orders")}
            </MenuItem>
            <Divider/>
            <MenuItem component={Link} to="/profile/" onClick={handleClose}>
                <ListItemIcon>
                    <SettingsIcon fontSize="small"/>
                </ListItemIcon>
                {t("Profile settings")}
            </MenuItem>
            <MenuItem component={Link} to="/accountpayment" onClick={handleClose}>
                <ListItemIcon>
                    <PaymentIcon fontSize="small"/>
                </ListItemIcon>
                {t("Payment settings")}
            </MenuItem>
            <Divider/>
            <MenuItem component={Link} to="https://expert.method.do/home/" onClick={handleClose}>
                <ListItemIcon>
                    <SwapHorizIcon fontSize="small"/>
                </ListItemIcon>
                {t("Login as expert")}
            </MenuItem>
            <Divider/>
            <MenuItem onClick={() => {
                handleClose();
                logout();
            }}>
                <ListItemIcon>
                    <LogoutIcon fontSize="small"/>
                </ListItemIcon>
                {t("Log out")}
            </MenuItem>
        </Menu>
    );

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const NavLink = ({to, children}) => (
        <Box component={Link} to={to} sx={{color: 'inherit', textDecoration: 'none', p: 1}}>
            {children}
        </Box>
    );

    return (
        <AppBar position="static" color="white" sx={{borderBottom: 1, borderColor: 'border.main'}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Link to={isAppSubdomain ? "/home" : "/"}>
                        <img src={logo} width="170" alt="method.do logo"/>
                    </Link>

                    {!isMobile && (
                        <Box sx={{display: 'flex', ml: 2}}>
                            {isAppSubdomain && isAuthenticated ? (
                                <>
                                    <NavLink to="/home" component='div'>{t("Home")}</NavLink>
                                    <NavLink to="/consultations">{t("My consultations")}</NavLink>
                                    <NavLink to="/matching">{t("Find a coach")}</NavLink>
                                    <NavLink to="/programs">{t("Programs")}</NavLink>
                                    <NavLink to="/tests">{t("Tests")}</NavLink>
                                </>
                            ) : (
                                <>
                                    <NavLink to={isAppSubdomain ? "https://method.do/" : "/"}>{t("Home")}</NavLink>
                                    <NavLink to={isAppSubdomain ? "https://method.do/forcoach" : "/forcoach"}>{t("For coaches")}</NavLink>
                                    <NavLink to={isAppSubdomain ? "/matching" : "https://app.method.do/matching"}>{t("Find a coach")}</NavLink>
                                    <NavLink to={isAppSubdomain ? "/programs" : "https://app.method.do/programs"}>{t("Programs")}</NavLink>
                                </>
                            )}
                        </Box>
                    )}

                    <Box sx={{alignItems: 'end', display: 'flex', ml: 'auto'}}>
                        {isAuthenticated ? (
                            <>
                                <Box onClick={handleMenu}
                                     sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>

                                    <Avatar alt={t("Profile Photo")} src={user?.profile_photo || noAvatar}/>
                                    {!isMobile && (
                                        <Box sx={{ml: 1}}>
                                            <Typography variant="body2" color="text.secondary">
                                                {t("Hello,")}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {user?.first_name}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                                <UserMenu/>
                            </>
                        ) : (
                            <>
                                {!isMobile && (
                                    <Button color="inherit" component={Link}
                                            to={isAppSubdomain ? "/login" : "https://app.method.do/login"} sx={{mr: 1}}>
                                        {t("Login")}
                                    </Button>
                                )}
                                {!isMobile && (
                                    <Button variant="contained" color="primary" component={Link} to="/quiz">
                                        {t("Find a coach")}
                                    </Button>
                                )}
                            </>
                        )}
                    </Box>


                    {isMobile && (
                        <MobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}
                                    isAuthenticated={isAuthenticated}
                                    toggleMenu={toggleMenu} isMobil={isMobile}/>
                    )}


                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Navbar;