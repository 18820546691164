import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import {styled} from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import moment from "moment-timezone";
import {useAuth} from "../../../../contexts/AuthContext";


const ChatHeader = ({contact, loading}) => {
    const {t} = useTranslation();
    const {timeZone, localeCode} = useAuth();

    const formatMessageTime = (datetime, timeZone, localeCode) => {
        console.log('datetime:', datetime);
        if (!datetime) {
            return t('Recently');
        }
        // Установка локали и часового пояса
        moment.locale(localeCode);

        const messageTime = moment(datetime);
        const now = moment().tz(timeZone);

        // Проверка, является ли дата сообщения текущим днем
        if (messageTime.isSame(now, 'day')) {
            // Форматирование времени как 'HH:mm' если сегодня
            return messageTime.format('HH:mm');
        } else {
            // Форматирование как день недели (например, 'Пн', 'Вт') если другой день
            return messageTime.format('dd');
        }
    };

    function stringAvatar(name) {
        console.log('name:', name);
        const parts = name?.split(' '); // Разделить имя на части
        let initials = '';

        // Добавить первую букву первого слова, если оно существует
        if (parts[0]) {
            initials += parts[0][0];
        }

        // Добавить первую букву второго слова, если оно существует
        if (parts[1]) {
            initials += parts[1][0];
        }

        return {
            sx: {
                bgcolor: 'primary.main',
            },
            children: initials
        };
    }

    const StyledBadge = styled(Badge)(({theme}) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));

    return (
        <div className="navbar card-header d-flex align-items-center justify-content-between w-100 p-sm-4 p-3">
            <div className="d-flex align-items-center pe-3">
                <button type="button" className="navbar-toggler d-lg-none me-3" data-bs-toggle="offcanvas"
                        data-bs-target="#contactsList" aria-controls="contactsList"
                        aria-label={t('Toggle contacts list')}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                {loading ? (
                    <>
                        <Skeleton variant="circular" width={48} height={48} sx={{ marginRight: 1 }} />
                        <Skeleton variant="text" width={200} height={40} />
                    </>
                ) : (
                    <>
                        {contact?.is_online ? (
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot"
                            >
                                <Avatar src={contact?.profile_photo} {...stringAvatar(contact?.username)}
                                        sx={{ width: 48, height: 48 }} />
                            </StyledBadge>
                        ) : (
                            <Avatar src={contact?.profile_photo} {...stringAvatar(contact?.username)}
                                    sx={{ width: 48, height: 48 }} />
                        )}
                        <div className="d-flex flex-column ms-2 ps-2">
                            <h6 className="mb-0">{contact?.username}</h6>
                            {!contact?.is_online && (
                                <span
                                    className="fs-sm text-muted">{t('Last seen')}: {formatMessageTime(contact?.last_seen)}</span>
                            )}
                        </div>
                    </>
                )}

            </div>
            {/* <div className="d-flex">
                <div className="dropdown me-sm-2">
                    <button type="button" className="btn btn-outline-primary px-2 px-sm-3" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                        <i className="bx bx-dots-horizontal-rounded fs-xl mx-1 mx-sm-0 me-xl-2"></i>
                        <span className="d-none d-xl-inline">{t('More')}</span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end my-1">
                        <Link href="#" className="dropdown-item">{t('View profile')}</Link>
                        <Link href="#" className="dropdown-item">{t('Disable notifications')}</Link>
                        <Link href="#" className="dropdown-item">{t('Edit contact')}</Link>
                        <Link href="#" className="dropdown-item">{t('Delete contact')}</Link>
                        <Link href="#" className="dropdown-item">{t('Delete chat')}</Link>
                        <Link href="#" className="dropdown-item">{t('Clear history')}</Link>
                        <Link href="#" className="dropdown-item">{t('Block user')}</Link>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default ChatHeader;
