import React, {useState, useEffect, useContext} from 'react';
import moment from 'moment-timezone';
import {
    Box,
    Typography,
    Grid,
    Button,
    Alert,
    Card,
    Divider,
    CardContent,
    IconButton
} from '@mui/material';
import {DateCalendar} from '@mui/x-date-pickers/DateCalendar';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {useAuth} from 'contexts/AuthContext';
import {PickersDay} from '@mui/x-date-pickers/PickersDay';
import {useTranslation} from "react-i18next";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {formatCurrency} from "utils/Currency";
import {FunctionContext} from "contexts/FunctionContext";
import {useNavigate} from "react-router-dom";

const AvailabilityCalendar = ({availabilities, appointments, coach = null}) => {
    const {t} = useTranslation();
    const {timeZone, locale, localeCode, formatTimeZone, isAuthenticated, AuthModalComponent, setPopupAuthModal} = useAuth();
    const [selectedDate, setSelectedDate] = useState(moment().tz(timeZone));
    const [availablePackages, setAvailablePackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [timeSlots, setTimeSlots] = useState([]);
    const [availableDates, setAvailableDates] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);
    const [sessionCost, setSessionCost] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [appointmentDuration, setAppointmentDuration] = useState(null);
    const [errors, setErrors] = useState(null);
    const {updateNewAppointment} = useContext(FunctionContext);
    const navigate = useNavigate();
    moment.locale(localeCode);

    useEffect(() => {
        const packages = availabilities.flatMap(a => a.packages);
        const uniquePackages = Array.from(new Set(packages.map(p => p.id)))
            .map(id => packages.find(p => p.id === id));
        setAvailablePackages(uniquePackages);
        if (uniquePackages.length > 0) {
            setSelectedPackage(uniquePackages[0]);
        }
    }, [availabilities]);

    useEffect(() => {
        if (selectedPackage) {
            setSessionCost(selectedPackage.oneTimePrice);
            setCurrency(selectedPackage.currency);
            setAppointmentDuration(selectedPackage.appointmentDuration);
        }
    }, [selectedPackage]);

    useEffect(() => {
        if (availabilities && selectedPackage) {
            const availableDays = availabilities
                .filter(a => a.packages.some(p => p.id === selectedPackage.id))
                .map(a => moment.tz(a.startTime, timeZone).startOf('day').format('YYYY-MM-DD'));
            setAvailableDates([...new Set(availableDays)]);
        }
    }, [availabilities, timeZone, selectedPackage]);

    useEffect(() => {
        if (selectedDate && selectedPackage && availabilities) {
            const slots = generateTimeSlots(selectedDate, availabilities, selectedPackage, appointments, timeZone);
            setTimeSlots(slots);
            setSelectedSlot(null);
        }
    }, [selectedDate, selectedPackage, availabilities, appointments, timeZone]);

    const CustomPickersDay = (props) => {
        const {day, outsideCurrentMonth, ...other} = props;
        const formattedDate = day.format('YYYY-MM-DD');
        const isActive = availableDates.includes(formattedDate);

        return (
            <PickersDay
                {...other}
                outsideCurrentMonth={outsideCurrentMonth}
                day={day}
                sx={{
                    ...(isActive && {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        },
                    }),
                }}
            />
        );
    };

    const generateTimeSlots = (date, availabilities, selectedPackage, appointments, tz) => {
        const dayAvailabilities = availabilities.filter(a =>
            moment.tz(a.startTime, tz).isSame(date, 'day') &&
            a.packages.some(p => p.id === selectedPackage.id)
        );

        let slots = [];
        dayAvailabilities.forEach(availability => {
            const start = moment.tz(availability.startTime, tz);
            const end = moment.tz(availability.endTime, tz);
            const slotDuration = 15; // 15 minutes slots

            let currentSlot = start.clone().startOf('hour');
            while (currentSlot.isBefore(end)) {
                if (currentSlot.isSameOrAfter(start)) {
                    const slotEnd = currentSlot.clone().add(selectedPackage.appointmentDuration, 'minutes');
                    const isOverlapping = appointments.some(app =>
                        (moment.tz(app.datetime_start, tz).isBetween(currentSlot, slotEnd, null, '[]') ||
                            moment.tz(app.datetime_end, tz).isBetween(currentSlot, slotEnd, null, '[]') ||
                            (moment.tz(app.datetime_start, tz).isSameOrBefore(currentSlot) &&
                                moment.tz(app.datetime_end, tz).isSameOrAfter(slotEnd)))
                    );
                    const isWithinAvailability = slotEnd.isSameOrBefore(end);

                    slots.push({
                        time: currentSlot.format('HH:mm'),
                        isAvailable: !isOverlapping && isWithinAvailability,
                        availability_id: availability.availability_id
                    });
                }
                currentSlot.add(slotDuration, 'minutes');
            }
        });

        return slots.sort((a, b) => moment(a.time, 'HH:mm').diff(moment(b.time, 'HH:mm')));
    };

    const handleSlotSelection = (slot) => {
        if (slot.isAvailable) {
            setSelectedSlot(slot.time);
        }
    };

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        swipeToSlide: true,
        variableWidth: true,
    };

    const renderDayScroller = () => {
        const days = [];
        for (let i = 0; i < 30; i++) {
            const day = moment().tz(timeZone).add(i, 'days');
            const formattedDate = day.format('YYYY-MM-DD');
            const isActive = availableDates.includes(formattedDate);
            if (isActive) {
                days.push(
                    <Box
                        sx={{marginRight: '5px'}}
                    >
                        <Box
                            key={formattedDate}
                            onClick={() => setSelectedDate(day)}
                            sx={{
                                cursor: 'pointer',
                                padding: 1,
                                backgroundColor: selectedDate.isSame(day, 'day') ? 'primary.main' : isActive ? 'white' : 'background.paper',
                                color: selectedDate.isSame(day, 'day') ? '#fff' : 'text.primary',
                                borderRadius: 1,
                                textAlign: 'center',
                                width: '75px',
                                border: isActive ? '1px solid' : '1px solid',
                                borderColor: 'border.main',
                            }}
                        >
                            <Typography variant="body2">{day.format('D MMM')}</Typography>
                            <Typography
                                variant="caption"
                                sx={{color: selectedDate.isSame(day, 'day') ? '#fff' : 'text.secondary'}}
                            >
                                {day.format('ddd')}
                            </Typography>
                        </Box>
                    </Box>
                );
            }
        }
        return days;
    };

    const handleBooking = () => {
        console.log(`Booking for ${selectedDate} at ${selectedSlot} in ${timeZone}`);

        if (!selectedSlot || !selectedDate) {
            setErrors('Please select a time slot');
            return;
        }

        if (!isAuthenticated) {
            setPopupAuthModal(true);
            return;
        }

        let timeZoneDate = null;

        if (selectedSlot === 'no_good_time') {
            console.log('no_good_time раздел');
            timeZoneDate = 'no_good_time';
        } else {
            console.log('selectedTime');
            const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
            timeZoneDate = moment.tz(`${formattedDate} ${selectedSlot}`, 'YYYY-MM-DD HH:mm', timeZone).toISOString();
            console.log('timeZoneDate', timeZoneDate);
        }

        updateNewAppointment({
            coach_id: coach.id,
            selected_time: timeZoneDate
        });

        localStorage.setItem('newAppointment', JSON.stringify({
            coach_id: coach.id,
            selected_time: timeZoneDate
        }));

        navigate('/newappoinment?coach_id=' + coach.id + '&selected_time=' + timeZoneDate);

    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={localeCode}>
            <Card sx={{background: 'white', borderRadius: '0 0 16px 16px', mb: 2}}>
                <CardContent>

                    <Box textAlign="center">
                        <Typography variant="h5" gutterBottom>
                            {sessionCost ? Math.floor(sessionCost) + " " + formatCurrency(currency) : ""}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {appointmentDuration} {t('minutes consultation')}
                        </Typography>
                    </Box>

                    {/*}
                    <Box>
                        <Typography variant="subtitle1" gutterBottom>
                            {selectedPackage?.appointmentDuration} minutes
                        </Typography>

                        <Box mb={2}>
                            <Select
                                value={selectedPackage?.id || ''}
                                onChange={(e) => setSelectedPackage(availablePackages.find(p => p.id === e.target.value))}
                            >
                                {availablePackages.map((pkg) => (
                                    <MenuItem key={pkg.id} value={pkg.id}>
                                        {pkg.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                    */}

                    <Divider sx={{mt: 2, mb: 4}}/>

                    <Box mb={2} sx={{display: 'flex', alignItems: 'center'}}>
                        <IconButton onClick={() => setShowCalendar(!showCalendar)}>
                            <CalendarTodayIcon/>
                        </IconButton>
                        <Box sx={{flexGrow: 1, overflowX: 'hidden'}}>
                            <Slider {...sliderSettings}>
                                {renderDayScroller()}
                            </Slider>
                        </Box>
                    </Box>

                    {showCalendar && (
                        <Box mb={2}>
                            <DateCalendar
                                value={selectedDate}
                                onChange={(newDate) => setSelectedDate(newDate)}
                                disablePast
                                slots={{
                                    day: CustomPickersDay,
                                }}
                                slotProps={{
                                    day: {
                                        availableDates,
                                    },
                                }}
                                sx={{
                                    '& .MuiPickersCalendarHeader-root': {
                                        mx: {xs: 0, sm: 2}
                                    }
                                }}
                            />
                        </Box>
                    )}

                    <Box>
                        <Grid container spacing={1}>
                            {timeSlots.length === 0 && (
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" textAlign="center">
                                        {t("No Slots Available")}
                                    </Typography>
                                </Grid>
                            )}
                            {timeSlots.map((slot) => (
                                <Grid item xs={3} md={3} key={slot.time}>
                                    <Button
                                        variant={selectedSlot === slot.time ? "contained" : "outlined"}
                                        color={slot.isAvailable ? "primary" : "secondary"}
                                        fullWidth
                                        onClick={() => handleSlotSelection(slot)}
                                        disabled={!slot.isAvailable}
                                        sx={{
                                            borderRadius: 2,
                                            padding: 1,
                                            }}
                                    >
                                        {slot.time}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Box sx={{mt: 2}}>
                        <Button
                            variant={selectedSlot === 'no_good_time' ? "contained" : "outlined"}
                            color="primary"
                            fullWidth
                            sx={{
                                borderRadius: 2,
                                padding: 1,
                            }}
                            onClick={() => setSelectedSlot('no_good_time')}
                        >
                            {t("There's no good time for me")}
                        </Button>
                    </Box>

                    {selectedSlot && (
                        <Box mt={2}>
                            <Typography variant="body1" gutterBottom textAlign="center" sx={{mb: 2}}>
                                {selectedSlot === 'no_good_time' ? (
                                    <Box> </Box>
                                ) : (
                                    t("Selected") + ": " + selectedDate.format('D MMMM YYYY') + " at " + selectedSlot
                                )}
                            </Typography>
                        </Box>
                    )}
                </CardContent>
            </Card>

            <Box>
                <Typography variant="body2" sx={{color: 'text.secondary', mb: 2}} textAlign="center" gutterBottom>
                    {formatTimeZone(timeZone)}
                </Typography>
            </Box>

            {errors && (
                <Alert severity="error" sx={{mb: 2}}>
                    {errors}
                </Alert>
            )}

            <Box
                sx={{mb: 4}}
                >
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleBooking}
                    sx={{mb: 2}}
                >
                    {selectedSlot === 'no_good_time' ? "Записаться и договорится позже" : "Записаться"}

                </Button>
            </Box>

            <AuthModalComponent coach={coach} />

        </LocalizationProvider>
    );
};

export default AvailabilityCalendar;