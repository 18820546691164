import React, {useEffect, useState} from 'react';
import customAxios from 'configs/axiosConfig';
import {useAuth} from 'contexts/AuthContext';
import {useTranslation} from 'react-i18next';
import moment from "moment-timezone";
import AccountWrap from 'components/wrap/AccountWrap.js';
import Loading from 'components/wrap/Loading';
import {Badge} from "react-bootstrap";
import {formatCurrency} from "utils/Currency";
import {Link} from "react-router-dom";

const History = () => {
    const {t} = useTranslation();
    const [appointments, setAppointments] = useState([]);
    const [loading, setloading] = useState(false);
    const { timeZone, locale, localeCode } = useAuth();


    useEffect(() => {
        const fetchConsultations = async () => {
            setloading(true);
            try {
                const response = await customAxios.get('https://api.method.do/api/user/historyconsultations/');
                setAppointments(response.data);
                console.log(response.data);
            } catch (error) {
                console.error(t('Failed to load history') + ':', error);
            } finally {
                setloading(false);
            }
        };
        fetchConsultations();
    }, []);


    return (
        <AccountWrap>
            <div>
                <h3>{t('Consultation history')} </h3>
                {loading ? (
                    <Loading message={t('Loading history...')}/>
                ) : (
                    appointments.length === 0 ? (
                        <p>{t('No history')}</p>
                    ) : (
                        appointments.map((appointment) => (
                            <div key={appointment.id}
                                 className="card d-sm-flex flex-sm-row align-items-sm-center justify-content-between border-0 shadow-sm p-3 p-md-4 mb-4">
                                <div className="d-flex align-items-center pe-sm-3">
                                    <div className="ps-3 ps-sm-4">
                                        <h6 className="mb-2">
                                            { moment.tz(appointment.appointment_date, timeZone).locale(localeCode).format('d MMMM в HH:mm') }

                                            <span className="text-secondary fw-normal">
                                                { moment.tz(appointment.appointment_date, timeZone).locale(localeCode).format('dddd') }
                                            </span>
                                            <Badge bg="secondary" className="ms-2">{appointment.duration} мин.</Badge>

                                        </h6>
                                        <div className="fs-sm pb-2">
                                            {t('Cost')} {appointment.order.amount}{' '}
                                            {formatCurrency(appointment.order.currency)}

                                            <Badge bg="info" className="ms-2">
                                                {t(appointment.order.status)}
                                            </Badge>

                                            <span className="ms-2">
                                                <Link to={`/order/${appointment.order.id}/`}>Открыть заказ</Link>
                                            </span>
                                        </div>
                                        <div className="fs-sm">
                                            {t('Specialist')}:{' '}<Link to={`/coach/${appointment.expert_coach_id}/`}>{`${appointment?.expert?.first_name} ${appointment?.expert?.last_name}`}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )
                )}


                <button className="btn btn-primary" onClick={() => (window.location.href = '/matching')}>
                    {t('Find a specialist')}
                </button>
            </div>
        </AccountWrap>
    );
}

export default History;
