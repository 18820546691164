import React, { useState, useEffect } from 'react';
import AccountWrap from 'components/wrap/AccountWrap.js';

import WorldPayments from './components/worldPayments';
import RussianPayments from './components/russianPayments';
import { useTranslation } from 'react-i18next';
import customAxios from "../../../configs/axiosConfig";
import CardItem from "./components/CardItem";
import {loadStripe} from "@stripe/stripe-js";
import {Button} from "react-bootstrap";
import Loading from "../../../components/wrap/Loading";


const AccountPayment = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [cards, setCards] = useState([]);
    const [error, setError] = useState(null);

    const loadCards = async () => {
        setLoading(true)
        try {
            const response = await customAxios.get('https://api.method.do/api/cards/getcards/');
            setCards(response.data);
        } catch (error) {
            console.error('Ошибка при загрузке карт: ', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadCards();
    }, []);

    const handleAddPaymentStripe = async () => {
        setLoading(true)
        try {
            const response = await customAxios.get('https://api.method.do/api/stripe/create_stripe_session/');
            const data = response.data;

            if (data.session_id) {
                const stripe = await loadStripe('pk_test_51NHR4uF9ZpQ73T15gwIFX6AjvfNgIfDO3WzkhHivWwEcwXByJosqiP3Pier9S62RQBooC4yPCzy8VfgvFvSyG7SJ00GvJzsu8O');
                const {error} = await stripe.redirectToCheckout({
                    sessionId: data.session_id
                });

                if (error) {
                    console.error('Ошибка при переходе к оплате: ', error);
                }
            }
        } catch (error) {
            console.error('Ошибка при создании сессии Stripe: ', error);
        } finally {
            setLoading(false);
        }
    };

    const BindCardIM = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await customAxios.get('https://api.method.do/api/intellectmoney/bind_card/');

            if (response.data.error) {
                setError(response.data.error);
            } else if (response.data.url) {
                window.location.href = response.data.url;
            } else {
                console.log('Appointment created successfully');
            }
        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data.error) {
                setError(error.response?.data?.message || error.message);
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <AccountWrap>
            <h1 className="h2 pt-xl-1 pb-2 pb-lg-3">{t('Cards for payment')}</h1>
            {loading && <Loading/>}
            {error && <div className="alert alert-danger">{error}</div>}
            {cards.length > 0 ? (
                <div className="card-list">
                    {cards.map(card => (
                        <CardItem key={card.id} card={card}/>
                    ))}
                </div>
            ) : (
                <p>{t('You have no attached cards.')}</p>
            )}
            <div className="py-2">
                <Button className="btn btn-secondary ps-4" onClick={BindCardIM} disabled={loading}>
                    <i className="bx bx-plus fs-xl me-2"></i> {t('Add card (Russia)')}
                </Button>
                <button type="button" className="btn btn-secondary ps-4 ms-3" onClick={handleAddPaymentStripe} disabled={loading}>
                    <i className="bx bx-plus fs-xl me-2"></i> {t('Add card (World)')}
                </button>
            </div>

        </AccountWrap>
    );
}

export default AccountPayment;
