import React, {useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    Avatar,
    Box,
    Divider,
    Skeleton,
    Alert
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import customAxios from "configs/axiosConfig";
import {useAuth} from "contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import history from 'configs/history';
import DialogEmailVerification from "./components/DialogEmailVerification";

const AuthModal = ({open, handleClose, coach = null, nextPage = null,}) => {
    const {t} = useTranslation();
    const [isLogin, setIsLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [credentials, setCredentials] = useState({});
    const [type, setType] = useState('email');
    const [error, setError] = useState(null);
    const {login, isAuthenticated} = useAuth();
    const navigate = useNavigate();
    const [showEmailVerification, setShowEmailVerification] = useState(false);

    const handleContinue = () => {
        if (nextPage) {
            handleClose();
            navigate(nextPage || 'https://app.method.do/home/');
            return;
        }
        handleClose();
    };

    const toggleMode = () => {
        setIsLogin(!isLogin);
    };

    const handleGoogleLogin = () => {
        const redirectUri = encodeURIComponent(nextPage || window.location.href);
        window.open(`https://api.method.do/api/google/login/?redirect_uri=${redirectUri}`, '_blank');
    };

    const handleLogin = async () => {
        setError(null);

        // Проверить корректность email На корректность
        if (!email || !email.includes('@') || !email.includes('.')) {
            setError(t('Please enter a valid email'));
            return;
        }

        setLoading(true);
        try {
            const response = await customAxios.post('https://api.method.do/api/user/login/', {
                type: 'email',
                email,
                password,
            });

            if (response.data.status === 'admin') {
                console.log('Admin login');
                login({
                    userData: response.data.user,
                    access_token: response.data.access_token,
                    refresh_token: response.data.refresh_token,
                    is_authenticated: true
                });
                handleContinue();
                return;
            }

            if (response.status === 200) {
                console.log(t('Successful login'));
                setShowEmailVerification(true);
            }

        } catch (error) {
            console.error(t("Error logging in:"), error);
            setError(t('Login error. Please try again.'));
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    if (isAuthenticated) {
        handleContinue();
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) { // KeyCode 13 is Enter
            handleLogin();
        }
    };

    const prevStep = () => {
        setShowEmailVerification(false);
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
            <DialogTitle sx={{mt: 3}}>
                {!isLogin && coach ? (
                    <Box display="flex" flexDirection="column" alignItems="center">

                        <Avatar
                            src={coach.profile_photo && !coach.profile_photo.includes('noavatar.jpeg')
                                ? coach.profile_photo.startsWith('https://method.do/images')
                                    ? `https://api.${coach.profile_photo.slice(8)}`
                                    : `https://api.method.do/images/${coach.profile_photo}`
                                : coach.profile_photo
                            }
                            sx={{width: 80, height: 80, mb: 2}}
                        />

                        <Typography variant="h5" align="center">
                            {t('Sign up to start')}
                        </Typography>
                        <Typography variant="body2" align="center" color="text.secondary">
                            {t('Only one step left to book your session with')} {coach?.expert.first_name} {coach?.expert.last_name}
                        </Typography>
                    </Box>
                ) : (
                    <Typography variant="h5" align="center" sx={{mb: 2, mt: 2}}>
                        {isLogin ? t('Log in') : t('Sign up to start')}
                    </Typography>
                )}
                {error && <Alert severity="error">{error}</Alert>}

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'border.main',
                    })}
                >
                    <CloseIcon/>
                </IconButton>

                {showEmailVerification && (
                    <IconButton
                        aria-label="prev"
                        onClick={prevStep}
                        sx={(theme) => ({
                            position: 'absolute',
                            left: 8,
                            top: 8,
                            color: 'border.main',
                        })}
                    >
                        <ArrowBackIosIcon/>
                    </IconButton>
                )}

            </DialogTitle>

            {!showEmailVerification && (
                <>
                    <DialogContent>
                        <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<GoogleIcon/>}
                            sx={{mb: 1}}
                            onClick={handleGoogleLogin}
                        >
                            {t('Continue with Google')}
                        </Button>

                        <Divider
                            sx={{my: 2}}>{isLogin ? t('or log in with email') : t('or sign up with email')}</Divider>

                        <TextField
                            fullWidth
                            label={t('Email address')}
                            variant="outlined"
                            margin="normal"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={loading}
                            onKeyDown={handleKeyDown}
                        />
                        {isLogin && (
                            <TextField
                                fullWidth
                                label={t('Password')}
                                type="password"
                                variant="outlined"
                                margin="normal"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                disabled={loading}
                                onKeyDown={handleKeyDown}
                            />
                        )}
                    </DialogContent>
                    <DialogActions sx={{flexDirection: 'column', alignItems: 'stretch', px: 3, pb: 3}}>
                        <LoadingButton variant="contained" color="primary" fullWidth onClick={handleLogin}
                                       loading={loading}
                                       disabled={loading}>
                            {isLogin ? t('Log in') : t('Continue')}
                        </LoadingButton>
                        <Typography variant="caption" align="center" sx={{mt: 2}}>
                            {t('By clicking Continue or')} <Link
                            onClick={toggleMode}>{t('Sign up')}</Link>, {t('you agree to')}
                            <Link to='/offer' sx={{mx: 1}} target="_blank">{t('method.do Terms of Use')}</Link>,
                            {t('including Subscription')} <Link to='/privacy' sx={{mx: 1}}
                                                                target="_blank">{t('Terms and Privacy Policy')}</Link>
                        </Typography>

                        <Typography variant="body2" align="center" sx={{mt: 2}}>
                            {isLogin ? t("Don't have an account?") : t('Already have a method.do account?')}{' '}

                            <Button color="primary" onClick={toggleMode}>
                                {isLogin ? t('Sign up') : t('Log in')}
                            </Button>

                        </Typography>
                    </DialogActions>
                </>
            )}

            {showEmailVerification && (
                <DialogEmailVerification email={email} nextPage={nextPage} handleClose={handleClose}/>
            )}

        </Dialog>
    );

}

export default AuthModal;