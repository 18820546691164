import React from 'react';
import {differenceInYears, parseISO} from 'date-fns';
import SwiperPhotos from './SwiperPhotos';
import SafeHtmlDisplay from "../../../../../configs/safeHtml";
import {useTranslation} from 'react-i18next';
import {
    Box,
    Typography,
    Chip,
} from '@mui/material';

function CoachInfo({coach, loading, setLoading}) {
    const {t} = useTranslation();

    // Функция для проверки и рендеринга массивов
    const renderArray = (array, renderItem) => {
        return Array.isArray(array) ? array.map(renderItem) : null;
    };

    const educationCounts = coach.education_data.reduce(
        (acc, education) => {
            if (education.higherEducation) {
                acc.higher++;
            } else {
                acc.additional++;
            }
            return acc;
        },
        {higher: 0, additional: 0}
    );

    const calculateAge = (birthDateString) => {
        if (!birthDateString) return '';

        const birthDate = parseISO(birthDateString);
        const today = new Date();
        const age = differenceInYears(today, birthDate);

        let ageWord;
        if (age % 10 === 1 && age % 100 !== 11) {
            ageWord = t('year');
        } else if (age % 10 >= 2 && age % 10 <= 4 && (age % 100 < 10 || age % 100 >= 20)) {
            ageWord = t('years');
        } else {
            ageWord = t('years');
        }

        return `${age} ${ageWord}`;
    };

    return (
        <>
            <style>
                {`
                p {
                    margin-bottom: 0;
                }
                `}
            </style>
            <Box className="bg-white rounded overflow-hidden px-4 py-3 mb-3">

                <Typography variant="h3" gutterBottom>
                    {coach.expert.first_name} {coach.expert.last_name}
                </Typography>

                <Box>
                    {coach.expert.date_of_birth && (
                        <Box>
                            {calculateAge(coach.expert.date_of_birth)}
                        </Box>
                    )}
                    {coach.expert.city && (
                        <Box>
                            {coach.expert.city}
                        </Box>
                    )}
                </Box>

                <Box>
                    <span className="info needs-translation" data-type="marital_status" data-key={coach.marital_status}>
                        {t(coach.marital_status)}
                    </span>
                </Box>

                {coach.verified && (
                    <Box sx={{mb: 2}}>
                        <i className="bx bxs-check-shield text-primary"></i>
                        <span className="ps-1">{t('Specialist verified method.do')}</span>
                    </Box>
                )}

                <Box sx={{mb: 3}}>
                    <SafeHtmlDisplay htmlContent={coach.your_style}/>
                </Box>

                {coach.coach_images_data?.length > 0 && (
                    <SwiperPhotos photos={coach.coach_images_data} gallery={'gallery1'}/>
                )}

            </Box>

            <Box className="bg-white rounded overflow-hidden px-4 py-3 mb-3">
                <Typography variant="h3" gutterBottom>{t('What I work with')}</Typography>

                {coach.howWork_about &&
                    <Box sx={{mb: 3}}>
                        <SafeHtmlDisplay htmlContent={coach.howWork_about}/>
                    </Box>
                }
                {coach.languages && (
                    <Box sx={{mb: 3}}>
                        <Typography variant="h6" gutterBottom>{t('Languages I work in')}:</Typography>
                        <Box>
                            {coach.languages && coach.languages.map((language, index) => (
                                <Chip
                                    key={index}
                                    label={<span>{t(language)}</span>}
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    sx={{mr: 1, mb: 1}}
                                />
                            ))}
                        </Box>
                    </Box>
                )}

                {Array.isArray(coach.coachType) && (
                    <Box sx={{mb: 3}}>
                        <Typography variant="h6" gutterBottom>{t('Coaching directions')}:</Typography>
                        <Box>
                            {coach.coachType && coach.coachType.map((coachType, index) => (
                                <Chip
                                    key={index}
                                    label={<span>{t(coachType)}</span>}
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    sx={{mr: 1, mb: 1}}
                                />
                            ))}
                        </Box>
                    </Box>
                )}

                {coach.industry && Array.isArray(coach.industry) && coach.industry.length > 0 && (
                    <Box sx={{mb: 3}}>
                        <Typography variant="h6" gutterBottom>{t('Industries I work in')}:</Typography>
                        <Box>
                            {coach.industry && coach.industry.map((industry, index) => (
                                <Chip
                                    key={index}
                                    label={<span>{t(industry)}</span>}
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    sx={{mr: 1, mb: 1}}
                                />
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>

            <Box className="bg-white rounded overflow-hidden px-4 py-3 mb-3">

                <Typography variant="h3" gutterBottom>{t('Work methods')}</Typography>

                {coach.methodWork_about &&
                    <Box sx={{mb: 3}}><SafeHtmlDisplay htmlContent={coach.methodWork_about}/></Box>}

                {coach.directions && Array.isArray(coach.directions) && coach.directions.length > 0 && coach.directions.length !== 1 && coach.directions[0] !== "next" && (
                    <Box sx={{mb: 3}}>
                        <Typography variant="h6" gutterBottom>{t('Work methods')}:</Typography>
                        {renderArray(coach.directions, (direction, index) => (
                            <Chip
                                key={index}
                                label={<span>{t(direction)}</span>}
                                size="small"
                                color="primary"
                                variant="outlined"
                                sx={{mr: 1, mb: 1}}
                            />
                        ))}
                    </Box>
                )}

                {coach.coachQualification && (
                    <Box sx={{mb: 3}}>
                        <Typography variant="h6" gutterBottom>{t('ICF certification level')}</Typography>
                        <Typography variant="h6" sx={{mb: 3}}>{t(coach.coachQualification)}</Typography>
                    </Box>
                )}
            </Box>

            {coach.about_me && (
                <Box className="bg-white rounded overflow-hidden px-4 py-3 mb-3">
                    <Typography variant="h3" gutterBottom>{t('Important to know about me')}</Typography>
                    <Box sx={{mb: 3}}><SafeHtmlDisplay htmlContent={coach.about_me}/></Box>
                </Box>
            )}

            {coach.education_data && Array.isArray(coach.education_data) && coach.education_data.length > 0 && (
                <Box className="bg-white rounded overflow-hidden px-4 py-3 mb-3">
                    <Typography variant="h3" gutterBottom>
                        {t('Education')}
                        {educationCounts.higher > 0 && (
                            <span className="fs-sm ps-3 text-secondary">{educationCounts.higher} {t('higher')}</span>
                        )}
                        {educationCounts.additional > 0 && (
                            <span
                                className="fs-sm ps-3 text-secondary">{educationCounts.additional} {t('additional')}</span>
                        )}
                    </Typography>

                    {coach.education_data.map((education, index) => (
                        <div key={index} className="row mb-3">
                            <div className="col-12 col-md-2 col-lg-1">
                                {education.education_images && education.education_images.length > 0 && (
                                    <div className="overflow-hidden rounded align-middle"
                                         style={{maxHeight: '80px', maxWidth: '80px'}}>
                                        {education.education_images.map((image, imageIndex) => (
                                            <a key={imageIndex} href={`https://api.${image.image.slice(8)}`}
                                               data-fancybox={`edu-${index}`}>
                                                <img src={`https://api.${image.image.slice(8)}`} alt=""
                                                     className="align-middle"/>
                                            </a>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-md-10 col-lg-11">
                                <div className="fw-medium">
                                    {education.program},
                                    <span
                                        className={'text-secondary ms-2'}>{education.startYear} – {education.endYear}</span>
                                </div>
                                <div className="fs-sm">{education.institution}<span
                                    className={'fs-sm text-secondary'}>{education.higherEducation ? `, ${t('higher')}` : ''}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </Box>
            )}

            {coach.coach_certificate_images_data?.length > 0 && (
                <Box className="bg-white rounded overflow-hidden px-4 py-3 mb-3">
                    <Typography variant="h3" gutterBottom>{t('Certificates and awards')}</Typography>
                    <SwiperPhotos photos={coach.coach_certificate_images_data} gallery={'gallery2'}/>
                </Box>
            )}
        </>
    );
}

export default CoachInfo;
