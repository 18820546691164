import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


function renderAnswerOption( option, index, answerType, step, answers, handleChange, t ) {
    const inputId = `question-${step}-option-${index}`;
    const currentAnswers = answers[step] || [];
    const isSelected = answerType === 'radio' ? answers[step]?.answer === option : (Array.isArray(answers[step]?.answer) ? answers[step]?.answer.includes(option) : false);

    switch (answerType) {
        case 'radio':
            return (
                <div key={index}>
                    <Form.Check className="d-flex align-items-center mb-2 ps-0">
                        <Form.Check.Input
                            id={inputId}
                            type={answerType}
                            name={`question-${step}`}
                            value={option}
                            onChange={(e) => handleChange(e, answerType)}
                            checked={isSelected}
                            className={`my-auto position-absolute ms-n2 rounded-2 border-0 ${isSelected ? 'border-primary bg-primary' : 'bg-body-secondary'} `}
                            style={{width: '20px', height: '20px'}}
                        />
                        <Form.Check.Label
                            htmlFor={inputId}
                            className={`answer-option border border-2 fw-500 px-5 py-4 rounded-2 w-100 fs-6 ${isSelected ? 'border-primary' : 'border-secondary'}`}
                            style={{cursor: 'pointer', flexGrow: 1}}
                        >
                            {t(option)}
                        </Form.Check.Label>
                    </Form.Check>
                </div>
            )
        case 'checkbox':
            return (
                <div key={index}>
                    <Form.Check className="d-flex align-items-center mb-2 ps-0">
                        <Form.Check.Input
                            id={inputId}
                            type={answerType}
                            name={`question-${step}`}
                            value={option}
                            onChange={(e) => handleChange(e, answerType)}
                            checked={isSelected}
                            className={`my-auto position-absolute ms-n2 rounded-2 border-0 ${isSelected ? 'border-primary bg-primary' : 'bg-body-secondary'} `}
                            style={{width: '20px', height: '20px'}}
                        />
                        <Form.Check.Label
                            htmlFor={inputId}
                            className={`answer-option border border-2 fw-500 px-5 py-4 rounded-2 w-100 fs-6 ${isSelected ? 'border-primary' : 'border-secondary'}`}
                            style={{cursor: 'pointer', flexGrow: 1}}
                        >
                            {t(option)}
                        </Form.Check.Label>
                    </Form.Check>
                </div>
            );
        case 'checkbox-category':
            return (
                <div key={index}>
                    <div className="fw-bold pb-3 fs-5">{t(option.title)}</div>

                    <Row>
                        {option.items.map((subOption, subIndex) => (
                            <Col key={subIndex} xs={12} md={6}>
                                <Form.Check className="mb-2">
                                    <Form.Check.Input
                                        id={`sub-option-${index}-${subIndex}`}
                                        type="checkbox"
                                        name={`question-${step}-${index}`}
                                        value={subOption}
                                        onChange={(e) => handleChange(e, answerType)}
                                        checked={Array.isArray(answers[step]?.answer) && answers[step]?.answer.includes(subOption)}
                                    />
                                    <Form.Check.Label
                                        htmlFor={`sub-option-${index}-${subIndex}`}
                                        style={{cursor: 'pointer', flexGrow: 1}}
                                    >
                                        {t(subOption)}
                                    </Form.Check.Label>
                                </Form.Check>
                            </Col>
                        ))}
                    </Row>
                    <hr className={'my-3'}/>
                </div>
            );

        default:
            // Рендер других типов ввода
            return (
                <div key={index}>
                    <input
                        type="text"
                        value={answers[step] || ''}
                        onChange={(e) => handleChange(e, 'text')}
                        className='form-control'
                    />
                </div>
            );
    }
}

const QuestionComponent = ({step, totalSteps, questionData, answers, onAnswerChange, nextStep}) => {
    const {questionText, answerType, answerOptions} = questionData;
    const { t } = useTranslation();

    const handleChange = (e, optionType) => {
        const value = e.target.value;
        const checked = e.target.checked;

        if (optionType === 'checkbox') {
            onAnswerChange(step, value, checked); // Передаем value и checked
        } else if (optionType === 'checkbox-category') {
            const currentAnswers = answers[step]?.answer || [];
            const newAnswers = checked ? [...currentAnswers, value] : currentAnswers.filter((answer) => answer !== value);
            onAnswerChange(step, newAnswers);
        } else {
            onAnswerChange(step, value);
            if (optionType === 'radio') {
                setTimeout(() => nextStep(), 200);
            }
        }
    };


    return (
        <div className="question-container">
            <div className="fs-6 text-secondary">
                {t("Question {{step}} of {{totalSteps}}", { step: step, totalSteps: totalSteps })}
            </div>
            <div className="question-text fs-2 fw-semibold">{t(questionText)}</div>
            <div className="answer-options mt-4 mb-4">
                {answerOptions.map((option, index) => renderAnswerOption(option, index, answerType, step, answers, handleChange, t ))}
            </div>
        </div>
    );
};

export default QuestionComponent;
