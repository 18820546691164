import React, {useState} from 'react';
import customAxios from '../../../configs/axiosConfig.js';
import {useNavigate} from 'react-router-dom';
import Loading from 'components/wrap/Loading';
import {Button, Container, Row} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import GoogleLogin from './components/GoogleLogin';
import MailLogin from './components/MailLogin';
import {useAuth} from "../../../contexts/AuthContext";
import SEO from "../../../configs/SEO";

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [activeTab, setActiveTab] = useState('#tab1');
    const [emailValid, setEmailValid] = useState(true);
    const [phoneValid, setPhoneValid] = useState(true);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const {login} = useAuth();

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
        setPhoneValid(/^\+?[0-9]{10,15}$/.test(e.target.value));
    }

    const handleLogin = async (credentials, type) => {
        try {
            if (!email || !email.includes('@') || !email.includes('.')) {
                setError(t('Please enter a valid email'));
                setEmailValid(false);
                return;
            }

            setLoading(true);
            const response = await customAxios.post('https://api.method.do/api/user/login/', credentials);

            if (response.data.status === 'admin') {
                console.log('Admin login');
                login({
                    userData: response.data.user,
                    access_token: response.data.access_token,
                    refresh_token: response.data.refresh_token,
                    is_authenticated: true
                });
                navigate('/home');
                return;
            }

            if (response.status === 200) {
                console.log(t('Successful login'));
                localStorage.setItem(type, credentials[type]);
                navigate(type === 'email' ? '/email_code_verification' : '/phone_code_verification');
            }

        } catch (error) {
            console.error(t("Error logging in:"), error);
            setError(t('Login error. Please try again.'));
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = true;

        const credentials = activeTab === '#tab1' ? {email} : {phone};
        const type = activeTab === '#tab1' ? 'email' : 'phone';

        handleLogin(credentials, type);
    };

    return (
        <>
            <SEO
                title="Log in or register"
                description="Enter your email address, and we will send a verification code to it."
            />
            <main className="page-wrapper pb-5" style={{position: 'relative'}}>
                <section className="h-100 pt-5 pb-4">
                    <Container className={'d-flex justify-content-center'}>
                        <div className="w-100 align-self-end pt-1 pt-md-4 pb-4" style={{maxWidth: '500px'}}>
                            <h3>{t("Log in or register")}</h3>
                            <p>{t("Enter your email address, and we will send a verification code to it.")}</p>
                            <div className={'position-relative'}>
                                {loading && <Loading/>}
                                {/*<ul className="nav nav-tabs-alt" role="tablist">
                                <li className="nav-item w-50" role="presentation">
                                    <a className="nav-link active" href="#tab1" data-bs-toggle="tab" role="tab"
                                       aria-controls="preview2" aria-selected="true">
                                        {t("Log in with email")}
                                    </a>
                                </li>
                                <li className="nav-item w-50" role="presentation">
                                    <a className="nav-link" href="#tab2" data-bs-toggle="tab" role="tab"
                                       aria-controls="html2"
                                       aria-selected="false" tabIndex="-1">
                                        <i className="bx bx-phone fs-base opacity-70 me-2"></i> Войти по телефону </a>
                                </li>
                            </ul>*/}
                                <form className="needs-validation mb-2" id="login" onSubmit={handleSubmit} noValidate>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <div className="tab-content">
                                        <div
                                            className={`tab-pane fade mb-3 ${activeTab === '#tab1' ? 'show active' : ''}`}
                                            id="tab1">
                                            <label htmlFor="email"
                                                   className="form-label fs-base">{t("Enter email")}</label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                className={`form-control form-control-lg ${!emailValid ? 'is-invalid' : ''}`}
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                                placeholder="example@gmail.com"
                                            />
                                            {!emailValid && <div
                                                className="invalid-feedback">{t("Please enter a valid email address!")}</div>}
                                        </div>
                                        {/*<div className={`tab-pane fade mb-3 ${activeTab === '#tab2' ? 'show active' : ''}`} id="tab2">
                                        <label htmlFor="phone" className="form-label fs-base">Введите номер телефона</label>
                                        <input
                                            type="tel"
                                            id="phone"
                                            name="phone"
                                            className={`form-control form-control-lg ${!phoneValid ? 'is-invalid' : ''}`}
                                            value={phone}
                                            onChange={handlePhoneChange}
                                            required
                                            placeholder="+79055757576"
                                        />
                                        {!phoneValid && <div className="invalid-feedback">Введите корректный номер телефона!</div>}
                                    </div> */}
                                    </div>
                                    <Button type={"submit"} className="btn-primary btn-lg w-100 mt-2"
                                            disabled={loading}>
                                        {t("Log in")}
                                    </Button>
                                </form>
                            </div>
                            <hr className="my-4"/>
                            <h6 className="text-center mb-4">{t("Or log in using services")}</h6>
                            <Row>
                                <GoogleLogin/>
                                <MailLogin/>
                            </Row>
                            <p className="mt-4 px-3 fs-xs">
                                {t("By entering your email address, you agree to the Terms of Service, consent to the processing of personal data, and agree to receive passwords and other information and service messages.")}
                            </p>
                            <div className="py-2 text-center">
                                <Link to={'https://expert.method.do/login/'}>{t("Expert login")}</Link>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
        </>
    );
};

export default Login;
