import React from 'react';
import {useTranslation} from 'react-i18next';

const styles = `

@keyframes moveGradient {
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
}

.loading-indicator {
  position: absolute;
  width: 100%; 
  min-height: 100px;
  top: 0;
  left: 0;
  z-index: 101;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.loading-indicator-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(97, 131, 162, 0.15);
  background-image: linear-gradient(to right, rgb(190 173 173 / 10%) 0%, rgb(255 255 255 / 90%) 50%, rgb(172 172 172 / 10%) 100%);
  background-size: 200% 200%;
  animation: moveGradient 3s ease-in-out infinite alternate;
  z-index: 101;
  box-sizing: border-box;
}

.loading-indicator-content {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 100;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-text {
    color: #333;
    font-size: 1em;
    text-align: center;
    display: inline-block;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: transparent;
}
.page-spinner {
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
    margin-bottom: -0.10rem;
    vertical-align: text-bottom;
    border: .15em solid #b4b7c9;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner .75s linear infinite;
    animation: spinner .75s linear infinite;
    margin-right: 0.5rem;
}

@-webkit-keyframes spinner {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
`;

const Loading = ({message, zIndex, classes}) => {
    const {t} = useTranslation();
    return (
        <div className={`loading-indicator h-100 d-flex justify-content-center align-items-center rounded overflow-hidden`} style={{ zIndex }}>
            <div className={`loading-indicator-content`} >
                <style>{styles}</style>
                <div className="loading-text">
                    <span className="page-spinner"></span>
                    {message ? t(message) : t("Loading...")}
                </div>
            </div>
            <div className={`loading-indicator-background ${classes}`} ></div>
        </div>
    );
};


export default Loading;

