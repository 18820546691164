//CardBrand.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const CardBrand = ({ type, width }) => {
    const { t } = useTranslation();
    let imageUrl = '/images/system/bankcard.svg';
    let imageAlt = 'Bank card';

    switch (type) {
        case 'mastercard':
            imageUrl = '/images/system/mastercard.svg';
            imageAlt = 'Mastercard';
            break;
        case 'visa':
            imageUrl = '/images/system/visa.svg';
            imageAlt = 'Visa';
            break;
        case 'MIR ADVANCED':
            imageUrl = '/images/system/mir.svg';
            imageAlt = 'MIR Russia';
            break;

        default:
            break;
    }

    return <img src={imageUrl} width={width} alt={imageAlt} />;
}

export default CardBrand;