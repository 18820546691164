import React, {useState, useEffect, useRef} from 'react';
import CustomAxios from '../../../configs/axiosConfig.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {Form, Row, Col, Container, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

import { useAuth } from 'contexts/AuthContext.js';


const EmailCodeVerification = () => {
    const {t} = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [code, setCode] = useState(new Array(6).fill(''));
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const inputsRef = useRef([]);
    const {user, login} = useAuth();

    const email = searchParams.get('email') || localStorage.getItem('email');
    const phone = searchParams.get('phone');
    const name = searchParams.get('name');



    const sendCode = () => {
        if (timeLeft > 0) {
            console.log('Resend code is not allowed yet');
            return;
        }
        setLoading(true);
        setError(null);
        setCode(new Array(6).fill(''));

        CustomAxios.post('https://api.method.do/api/user/send_verification_code/', JSON.stringify({email}), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                setTimeLeft(63); // Устанавливаем таймер на 60 секунд
                setSuccess(t('Verification code sent successfully'));
            })
            .catch((error) => {
                if (error.response && error.response.status === 429) {
                    // Если сервер вернул ошибку 429, устанавливаем время ожидания из ответа
                    console.error('Error sending verification code:', error.response.data);
                    setError(t('Failed to send verification code. Please wait.') + ` (${error.response.data.time_left + 3}s)`);
                    setSuccess(null);
                    setTimeLeft(error.response.data.time_left + 3);
                } else {
                    console.error('Error sending verification code:', error);
                    setSuccess(null);
                    setError(t('Failed to send verification code'));
                }
            })
            .finally(() => {
                setSuccess(null);
                setLoading(false);
            });
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text').slice(0, 6); // Ограничиваем длину вставляемых данных шестью символами
        const newCode = pasteData.split('').map((char, index) => !isNaN(char) ? char : '');

        setCode(prev => {
            // Заполняем новыми данными столько элементов массива, сколько символов было вставлено
            const updatedCode = [...prev];
            for (let i = 0; i < newCode.length; i++) {
                updatedCode[i] = newCode[i];
            }
            return updatedCode;
        });

        // Фокусируемся на последнем инпуте, который был заполнен данными из буфера обмена
        if (inputsRef.current[newCode.length - 1]) {
            inputsRef.current[newCode.length - 1].focus();
        }
    };

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        const prevValue = code[index];

        if (value === '' && prevValue !== '') {
            let newCode = [...code];
            newCode[index] = '';
            setCode(newCode);

            if (index > 0 && inputsRef.current[index - 1]) {
                inputsRef.current[index - 1].focus();
            }
        } else if (!isNaN(value) && value.length <= 1) {
            let newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            if (value !== '' && index < code.length - 1) {
                inputsRef.current[index + 1].focus();
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        const fullCode = code.join('');


        if (!fullCode) {
            setError(t('Please enter the code'));
            setLoading(false);
            return;
        }

        if (!/^[0-9]{6}$/.test(fullCode)) {
            setError(t('The code must be 6 digits'));
            setLoading(false);
            return;
        }

        CustomAxios.post('https://api.method.do/api/user/code_verification/', JSON.stringify({email, code: fullCode, phone, name}), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {

                if (response.status === 200) {
                    login({
                        userData: response.data,
                        access_token: response.data.access_token,
                        refresh_token: response.data.refresh_token,
                        is_authenticated: true

                    });
                    navigate('/home');
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(t('Verification failed. Please try again') + `: ${t(error.response.data.error)}`);
                } else {
                    setLoading(false);
                    setError(t('Verification failed. Please try again'));
                }
            }).finally(
            setLoading(false)
        )
    };

    // Используем useEffect для управления таймером
    useEffect(() => {
        if (timeLeft > 0) {
            const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timerId);
        }
    }, [timeLeft]);

    // Автоматическая отправка кода при монтировании компонента
    useEffect(() => {
        sendCode();
    }, []);

    return (
        <>
            <Container className={'d-flex justify-content-center py-5'} >
                <div className="w-100 align-self-center text-center pt-1 pt-md-4 pb-4" style={{maxWidth: '500px'}}>
                    <h3>{t("Account verification")}</h3>
                    <p>{t("Enter the verification code sent to your email")}:{' '}<b>{email}</b></p>
                    <Form onPaste={handlePaste} className={'needs-validation mb-2'} onSubmit={handleSubmit} noValidate>
                        {error && <div className="alert alert-danger">{error}</div>}
                        <div className="position-relative mb-4">
                            <Row className="justify-content-center pb-3">
                                <div className="d-flex justify-content-center">
                                    {code.map((item, index) => (
                                        <Col key={index} xs="auto">
                                            <Form.Control
                                                type="text"
                                                maxLength="1"
                                                value={item}
                                                onChange={(e) => handleInputChange(e, index)}
                                                ref={(ref) => inputsRef.current[index] = ref}
                                                autoComplete="off"
                                                className="text-center form-control-lg m-2 px-1"
                                                style={{ width: '50px' }}
                                            />
                                        </Col>
                                    ))}
                                </div>
                            </Row>

                            <Button variant={'link'} className={`mx-auto`} onClick={sendCode} disabled={timeLeft !== 0}>
                                {timeLeft > 0 ? `${t("Resend")} (${timeLeft}s)` : t("Resend")}
                            </Button>
                        </div>
                        <Button type={"submit"} className="btn-primary btn-lg w-100">{t("Verify")}</Button>
                    </Form>
                </div>
            </Container>
        </>    );
}


export default EmailCodeVerification;

