//WelcomePage.js

import React, {useState, useEffect, useContext} from 'react';
import {useAuth} from '../../../../contexts/AuthContext';
import {useTranslation} from 'react-i18next';
import Popup from './components/Popup';
import {TextField, Grid, Box, FormControl, InputLabel, Select} from '@mui/material';
import Input from '@mui/material/Input';
import Autocomplete from '@mui/material/Autocomplete';
import customAxios from "../../../../configs/axiosConfig";
import {useNavigate} from "react-router-dom";
import { FunctionContext } from 'contexts/FunctionContext';

function WhyYouHere() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const {user, updateUser, timeZone, setTimeZone, getTimezoneData} = useAuth();
    const [firstName, setFirstName] = useState(user?.first_name || '');
    const [lastName, setLastName] = useState(user?.last_name || '');
    const [country, setCountry] = useState(user?.country || '');
    const [newTimeZone, setNewTimeZone] = useState(timeZone);
    const title = 'Please tell us more about yourself';
    const text = 'We will use this information to personalize your experience';
    const {countries} = useContext(FunctionContext);
    const [nextPage, setNextPage] = useState(localStorage.getItem('nextPage'));

    useEffect(() => {
        console.log(country)
    }, [country])

    const fetchData = async () => {
        setLoading(true)
        setError(null);

        if (!firstName || !lastName || !country || !timeZone) {
            setError(t('Please fill in all fields'));
            setLoading(false);
            return;
        }
        try {
            const sessionData = await customAxios.patch(`https://api.method.do/api/user/updateuser/`, {
                first_name: firstName,
                last_name: lastName,
                country: country,
                timezone: timeZone
            });
            if (sessionData.status === 200) {
                updateUser(sessionData.data);
                setTimeZone(newTimeZone);
                if (!sessionData.data.first_name || !sessionData.data.last_name || !sessionData.data.country) {
                    setError(t('Please fill in all fields'));
                } else {
                    navigate(nextPage || '/home');
                    localStorage.removeItem('nextPage');
                }
            } else {
                console.log('Error fetching data:', sessionData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Popup title={title} text={text} error={error} next='Next' onNext={fetchData} loading={loading}>
            <Grid container spacing={2} sx={{width: '100%', maxWidth: 420}}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            {t('First Name')}
                        </InputLabel>
                        <Input
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </FormControl>

                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            {t('Last Name')}
                        </InputLabel>
                        <Input
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        id="country-select-demo"
                        options={countries}
                        autoHighlight
                        getOptionLabel={(option) => t(option.label)}
                        value={countries.find((option) => option.code === country)}
                        onChange={(event, newValue) => { setCountry(newValue?.code) }}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    alt=""
                                />
                                {t(option.label)} ({option.code})
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('Country')}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                }}
                            />
                        )}
                    />

                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        options={getTimezoneData()}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        value={getTimezoneData().find((option) => option.tz === newTimeZone)}
                        onChange={(event, newValue) => {
                            setNewTimeZone(newValue?.tz);
                        }}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('Choose your timezone')}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                }}
                            />
                        )}
                    />

                </Grid>
            </Grid>
        </Popup>
    )
}

export default WhyYouHere;