// ContactList.js
import React, {useState} from 'react';
import {useAuth} from 'contexts/AuthContext';
import ContactItem from './ContactItem';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import {Box, Typography, TextField, IconButton} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Skeleton from '@mui/material/Skeleton';


function ContactList({messages, setSelectedContact, loading}) {
    const {t} = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredContacts = messages.filter(contact =>
        contact.dialogue_with.username && contact.dialogue_with.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const Support = {
        dialogue_with: {
            id: 211,
            username: t('Support'),
            profile_photo: 'https://randomuser.me/api/portraits/men/1.jpg',
            created_at: new Date(),
            updated_at: new Date(),
        },
        last_message_time: new Date(),
        last_message_content: t('How can I help you today?'),
    };

    return (
        <Grid item xs={12} md={4}
              sx={{height: '100%', flexGrow: 1, borderRight: '1px solid #e2e5f1', borderLeft: '1px solid #e2e5f1'}}>
            <Box sx={{
                '.scrollable-content::-webkit-scrollbar': {
                    width: '0',
                    bgcolor: 'transparent',
                },
                '.scrollable-content:hover::-webkit-scrollbar': {
                    width: '4px',
                    bgcolor: 'rgba(0, 0, 0, 0.1)',
                },
                '.scrollable-content::-webkit-scrollbar-thumb': {
                    bgcolor: 'rgba(0, 0, 0, 0.2)',
                },
                '.scrollable-content::-webkit-scrollbar-thumb:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.3)',
                },
            }}>

                <Typography variant="h5" mt={2} mb={2} ml={3}>{t('Messages')}</Typography>

                <TextField
                    variant="outlined"
                    placeholder={t('Search')}
                    size="small"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    sx={{
                        borderRadius: '99px',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '99px',
                        },
                        '& .MuiFormControl-root': {
                            padding: '0.5rem 1rem',
                        },
                        width: '90%',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    InputProps={{
                        endAdornment: (
                            <IconButton position="end">
                                <SearchIcon/>
                            </IconButton>
                        ),
                    }}
                />

                <Box mt={2} sx={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    className: 'scrollable-content',
                    role: 'group',
                    'aria-label': '1 / 1'
                }}>
                    {loading ? (
                        <>
                            {/* выведи 5 штук через массив */}
                            {Array.from({length: 5}).map((_, index) => (
                                <Grid container spacing={2} alignItems="center" py={2} px={3} key={index}>
                                    <Grid item>
                                        <Skeleton variant="circular" width={45} height={45}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Box display="flex" flexDirection="column" alignItems="start">
                                            <Skeleton width="50%" height={20}/>
                                            <Skeleton width="70%" height={15}/>
                                        </Box>
                                    </Grid>
                                </Grid>
                            ))}
                        </>
                    ) : (
                        <>
                            <ContactItem key={Support.dialogue_with.id} contact={Support} setSelectedContact={setSelectedContact}/>
                            {filteredContacts.map(contact => (
                                <ContactItem key={contact.id} contact={contact} setSelectedContact={setSelectedContact}/>
                            ))}
                        </>
                    )}
                </Box>
            </Box>
        </Grid>
    );
};

export default ContactList;

