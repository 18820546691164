import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from '../locales/en/translation.json';
import translationRU from '../locales/ru/translation.json';
import countriesRU from '../locales/ru/countries.json';
import timezoneRU from '../locales/ru/timezone.json';
import quizRU from '../locales/ru/quiz.json';

import profileRU from '../locales/ru/profile.json';
import profileEN from '../locales/en/profile.json';

import seoRU from '../locales/ru/seo.json';
import seoEN from '../locales/en/seo.json';

// Ресурсы перевода
const resources = {
    en: {
        translation: {
        ...translationEN,
        ...profileEN,
        ...seoEN,
        }
    },
    ru: {
        translation: {
            ...translationRU,
            ...countriesRU,
            ...timezoneRU,
            ...profileRU,
            ...quizRU,
            ...seoRU,
        }
    }
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        detection: {
            order: [ 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'subdomain'],
            caches: ['cookie', 'localStorage'],
            cookieMinutes: 43200,
        },
        fallbackLng: 'en',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
