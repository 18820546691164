import React, {useState, useEffect, useCallback} from 'react';
import customAxios from 'configs/axiosConfig.js';
import Loading from 'components/wrap/Loading';
import {useTranslation} from 'react-i18next';

import CoachItem from './CoachItem';
import FilterSidebar from './FilterSidebar';
import SEO from "configs/SEO";

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


const Matching = () => {
    const {t} = useTranslation();
    const [coaches, setCoaches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [nextPage, setNextPage] = useState(null);
    const [hoveredCoach, setHoveredCoach] = useState(null);

    // состояния для фильтров
    const [filters, setFilters] = useState({
        minPrice: '',
        maxPrice: '',
        maritalStatus: [],
        industry: [],
        coachType: [],
        coachQualification: [],
        directions: [],
    });

    const handleFilterChange = (filterName, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: value
        }));
    };


    const fetchFilteredCoaches = async (page = 1) => {
        try {
            const queryParams = new URLSearchParams({
                ...filters,
                page: page
            }).toString();

            const response = await customAxios.get(`https://api.method.do/api/coach/getActiveCoaches/?${queryParams}`);

            // Добавляем новые данные коучей к существующему списку
            if (response.data && Array.isArray(response.data.results)) {
                // Добавляем новые данные коучей к существующему списку
                setCoaches(prevCoaches => [...prevCoaches, ...response.data.results]);
                setHasMore(response.data.next !== null);
                setNextPage(response.data.next);
            } else {
                console.error('response.data.results is not an array:', response.data.results);
                setCoaches([]); // Устанавливаем пустой массив в качестве резервного варианта
            }
        } catch (error) {
            console.error("Ошибка при фильтрации коучей:", error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        setLoading(true);
        fetchFilteredCoaches();
    }, []);

    const applyFilters = async () => {
        setCoaches([]);
        setCurrentPage(1);
        setLoading(true);
        await fetchFilteredCoaches();
    };


    const [isLoading, setIsLoading] = useState(false);
    const loadMoreCoaches = useCallback(async () => {
        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        setIsLoading(true);

        try {
            await fetchFilteredCoaches(nextPage);
        } catch (error) {
            // Обработка ошибок
        }

        setIsLoading(false);
    }, [currentPage, filters]);


    return (
        <>
            <SEO
                title="List of coaches - find a coach online"
                description="Looking for the best coaches? On our platform, we select only top specialists. Business coaching, life coaching, coaching employees of companies to provide you with expert support and personal development. Check out our list of top coaches!"
            />

            <Container>
                <Row>
                    <Col xl={12} className="rounded bg-white px-0">
                        <h2 className="px-3 pt-3">{t("Choose a specialist")}</h2>
                        <p className="px-3">
                            {t("These coaches know how to work with your request, fit the price and your other conditions")}
                            <br/>
                            {t("They're free now - you can start practicing right now")}
                        </p>
                        {loading ? (<Loading message={t("Loading coaches ...")}/>) : null}

                        <div id="coaches-container">
                            {Array.isArray(coaches) && coaches.length > 0 ? (
                                <div className="g-4">
                                    {coaches.map(coach => (
                                        <CoachItem key={coach.id}
                                                   coach={coach}
                                                   isHovered={hoveredCoach === coach.id}
                                                   setHoveredCoach={setHoveredCoach}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <p className={'g-4 ps-3'}><b>{t("No coaches found")}</b></p>
                            )}
                        </div>
                        <div className="text-center pb-4">
                            {hasMore ? (
                                <button type="button" onClick={loadMoreCoaches} className="btn btn-outline-primary mt-4"
                                        disabled={isLoading}>
                                    <i className={`bx ${isLoading ? 'bx-refresh bx-spin' : 'bx-down-arrow-alt'} fs-xl me-2`}></i>
                                    {t("Show more")}
                                </button>
                            ) : null}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Matching;