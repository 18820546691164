import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import CustomAxios from 'configs/axiosConfig';
import ContactList from './chatComponents/ContactList';
import ChatWindow from './chatComponents/ChatWindow';
import { Box, Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2



function Chat() {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const { timeZone } = useAuth();

    useEffect(() => {
        setLoading(true);
        CustomAxios.get('https://api.method.do/messages/')
            .then(response => {
                setMessages(response.data);
                setLoading(false);
            });
    }, []);

    return (
        <Container maxWidth="xl" sx={{ flexGrow: 1, height: '100%'}}>
            <Grid container display="flex" sx={{ height: '100%' }}>
                <ContactList messages={messages} setSelectedContact={setSelectedContact} loading={loading} />
                <ChatWindow selectedContact={selectedContact} />
            </Grid>
        </Container>
    );
}

export default Chat;
