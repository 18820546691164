// ResultsAnimation.js - компонент анимации результатов теста
import React, {useState, useEffect} from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Loading from 'components/wrap/Loading';

const ResultsAnimation = ({steps, answers, setAnimationFinished}) => {
    const [loading, setLoading] = useState(false);
    const [progressValues, setProgressValues] = useState(steps.map(() => 0));
    const [currentStepIndex, setCurrentStepIndex] = useState(null);


    // Функция для обновления прогресса для каждого шага
    const updateProgress = async () => {
        for (let i = 0; i < steps.length; i++) {
            setCurrentStepIndex(i);
            await new Promise(resolve => {
                const interval = setInterval(() => {
                    setProgressValues(currentValues => {
                        const newValues = [...currentValues];
                        if (newValues[i] < 100) {
                            newValues[i] = Math.min(newValues[i] + 1, 100);
                        } else {
                            clearInterval(interval);
                            resolve();
                        }
                        return newValues;
                    });
                }, 40);
            });
        }
        setCurrentStepIndex(null);
        setTimeout(() => {
            setAnimationFinished(true);
        },300);
    };

    useEffect(() => {
        updateProgress();
    }, []);

    return (

        <div>

                <div className="mb-3">
                    <div className="fs-3 mb-3">Анализируем ваши ответы...</div>
                    {steps.map((step, index) => (
                    <div key={index} className='mb-3'>
                        <div className={index <= currentStepIndex ? '' : 'text-border'}>{step.title}</div>
                        <LinearProgress variant="determinate" value={progressValues[index]} sx={{
                            height: '15px',
                            borderRadius: '5px',
                            backgroundColor: '#eeeeee',
                            '& .MuiLinearProgress-barColorPrimary': {
                                backgroundColor: '#abdeff',
                            },
                            display: 'block',
                        }}/>
                    </div>
                    ))}
                </div>


            {loading && <Loading/>}

        </div>
    );
};
export default ResultsAnimation;
