import React from 'react';
import {Typography, Grid, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Divider, IconButton, LinearProgress, Box, Card, CardMedia, Skeleton} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {AccessTime, Close, Group, LockOutlined, MenuBook} from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import moment from 'moment';
import 'moment-duration-format';

const LessonItem = ({progress, setMenuOpen, text, index, lesson, onSelect, isSelected}) => {
    const {t} = useTranslation();

    const onClickLesson = () => {
        onSelect(progress);
        setMenuOpen(false);
    };


    return (
        <ListItemButton
            alignItems="center"
            sx={{
                py: 1,
                px: 3,
                display: 'flex',
                alignItems: 'center',
                borderLeft: isSelected ? '4px solid #1976d2' : '4px solid transparent',
                backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.04)' : 'transparent'
            }}
            onClick={onClickLesson}
        >
            <ListItemIcon sx={{mt: 0}}>
                <Box
                    component="img"
                    sx={{width: 80, height: 80, borderRadius: 2}}
                    alt={lesson?.title || `${t('Lesson')} ${index + 1}`}
                    src={lesson?.imagePreview || `https://via.placeholder.com/80x80?text=${t('Lesson')}+${index + 1}`}
                />
            </ListItemIcon>
            <ListItemText
                primary={text}
                secondary={lesson?.duration && moment.duration(lesson.duration, 'seconds').format('h [час] m [минут] s [секунд]')}
                primaryTypographyProps={{variant: 'body1', fontWeight: 'medium'}}
                sx={{ marginLeft: 2, my: 0 }}
            />
            {!progress?.has_access && <LockOutlined sx={{ml: 2}}/>}
            {progress?.is_finished && <DoneIcon sx={{ml: 2}}/>}


        </ListItemButton>
    );
};


const LessonsList = ({loading, menuOpen, setMenuOpen, hasAccess, currentLesson, setCurrentLesson, userCourseProgress, userLessonsProgress}) => {
    const {t} = useTranslation();
    const handleDrawerClose = () => {
        setMenuOpen(false);
    };
    const handleLessonSelect = (lesson) => {
        setCurrentLesson(lesson);
    };
    const resetLessonSelect = (lesson) => {
        setCurrentLesson(null);
        setMenuOpen(false);
    };
    const getLessonText = (count) => {
        const titles = ['урок', 'урока', 'уроков'];
        const cases = [2, 0, 1, 1, 1, 2];
        return titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]];
    };
    const calculateTotalDuration = (course) => {
        let totalDuration = 0;
        let lessons = course?.lessons || [];

        lessons.forEach((lesson) => {
            if (lesson.duration) {
                totalDuration += moment.duration(lesson.duration).asSeconds();
            }
        });

        return totalDuration;
    };
    const countCompletedLessons = () => {
        return userLessonsProgress.filter(lesson => lesson.is_finished).length;
    };
    const calculateCompletionPercentage = () => {
        if (!userCourseProgress || !userCourseProgress.course || !userCourseProgress.course.lessons.length) {
            return 0;
        }
        const completedLessons = countCompletedLessons();
        const totalLessons = userCourseProgress.course.lessons.length;
        return (completedLessons / totalLessons) * 100;
    };

    return (

                <Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                        px: 3,
                        pt: 3
                    }}>
                        {loading ? (
                            <Skeleton variant="text" width={150} height={40}/>
                        ) : (
                            <Typography variant="h5" component="div">
                                {t('Program')}
                            </Typography>
                        )}
                        <IconButton onClick={handleDrawerClose}>
                            <Close/>
                        </IconButton>
                    </Box>

                    <Box sx={{display: 'flex', alignItems: 'center', marginBottom: 2, px: 3}}>
                        {loading ? (
                            <Skeleton variant="rectangular" width={120} height={80} sx={{mr: 2}}/>
                        ) : (
                            <Card sx={{width: 143, height: 80, mr: 2, overflow: 'hidden', cursor: 'pointer'}} onClick={resetLessonSelect}>
                                <CardMedia
                                    component="img"
                                    height="80"
                                    image={userCourseProgress.course?.imagePreview}
                                    alt="Program thumbnail"
                                />
                            </Card>
                        )}
                        <Box>
                            {loading ? (
                                <>
                                    <Skeleton variant="text" width={100} height={30}/>
                                    <Skeleton variant="text" width={80} height={20}/>
                                </>
                            ) : (
                                <>
                                    <Box sx={{cursor: 'pointer'}} onClick={resetLessonSelect}>
                                        <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                                            {userCourseProgress.course?.title}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {userCourseProgress.course?.expert.first_name} {userCourseProgress.course?.expert.last_name}
                                        </Typography>
                                    </Box>
                                </>
                            )}
                            <Box>
                                {loading ? (
                                    <>
                                        <Skeleton variant="text" width={60} height={20} sx={{mr: 2}}/>
                                        <Skeleton variant="text" width={60} height={20} sx={{mr: 2}}/>
                                    </>
                                ) : (
                                    <>
                                        <Box sx={{display: 'flex', mt: 1, alignItems: 'center', cursor: 'pointer'}}
                                             onClick={resetLessonSelect}>
                                            <Box sx={{display: 'flex', alignItems: 'center', mr: 2}}>
                                                <MenuBook sx={{fontSize: 16, mr: 0.5}}/>
                                                <Typography variant="body2">
                                                    {userCourseProgress.course?.lessons.length} {getLessonText(userCourseProgress.course?.lessons.length)}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display: 'flex', alignItems: 'center', mr: 2}}>
                                                <AccessTime sx={{fontSize: 16, mr: 0.5}}/>
                                                <Typography variant="body2">
                                                    {moment.duration(calculateTotalDuration(userCourseProgress.course), 'seconds').format('h [час] m [минут] s [секунд]')}
                                                </Typography>
                                            </Box>
                                            {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Group sx={{ fontSize: 16, mr: 0.5 }} />
                                                <Typography variant="body2">
                                                    Участвуют 1,741
                                                </Typography>
                                            </Box> */}
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{px: 3}}>
                        {loading ? (
                            <>
                                <Skeleton variant="text" width={180} height={20}/>
                                <Skeleton variant="text" width={120} height={20}/>
                            </>
                        ) : (
                            <>
                                <LinearProgress variant="determinate" value={calculateCompletionPercentage()} sx={{mb: 1}}/>
                                <Typography variant="body2" sx={{mb: 2}}>
                                    {`${countCompletedLessons()} ${t('out of')} ${userCourseProgress.course?.lessons.length} ${t('lessons completed')}`}
                                </Typography>
                            </>
                        )}
                    </Box>

                    <Divider sx={{my: 2}}/>

                    <Box sx={{px: 3}}>
                        {loading ? (
                            <>
                                <Skeleton variant="text" width={120} height={30}/>
                                <Skeleton variant="text" width={100} height={20}/>
                            </>
                        ) : (
                            <>
                                <Typography variant="h6" sx={{mb: 0}}>{t('Lessons')}</Typography>
                                <Typography variant="body2" sx={{mb: 1}}>
                                    {`${countCompletedLessons()} ${t('out of')} ${userCourseProgress.course?.lessons.length} ${t('lessons completed')}`}
                                </Typography>
                            </>
                        )}
                    </Box>

                    <List>
                        {loading ? (
                            Array.from(new Array(5)).map((_, index) => (
                                <ListItemButton key={index}>
                                    <ListItemIcon sx={{mt: 0, ml: 2}}>
                                        <Skeleton variant="rectangular" width={80} height={80}/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Skeleton variant="text" width={150}/>}
                                        secondary={<Skeleton variant="text" width={100}/>}
                                        sx={{
                                            marginLeft: 2,
                                            my: 0,
                                        }}
                                    />
                                </ListItemButton>
                            ))
                        ) : (
                            userLessonsProgress.map((progress, index) => (
                                <LessonItem
                                    progress={progress}
                                    setMenuOpen={setMenuOpen}
                                    text={progress.lesson.title}
                                    index={index}
                                    lesson={progress.lesson}
                                    onSelect={handleLessonSelect}
                                    isSelected={currentLesson?.id === progress.lesson.id}
                                    key={progress.lesson.id}
                                />
                            ))
                        )}
                    </List>

                    <Divider sx={{my: 2}}/>
                </Box>
    );
};

export default LessonsList;
