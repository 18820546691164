// GoogleLogin.jsx
import React from 'react';

const GoogleLogin = () => {
    const googleLoginUrl = 'https://api.method.do/api/google/login/';


    return (
        <div className="col mb-3">
            <a
                href={googleLoginUrl}
                className="btn btn-icon btn-secondary btn-google btn-lg w-100"
            >
                <i className="bx bxl-google fs-xl me-2"></i> Google </a>
        </div>
    );
}

export default GoogleLogin;