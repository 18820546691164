import React, {useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {
    Box,
    Typography,
    Chip,
    Button,
    Grid,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import {TranslationClass} from 'utils/getTranslation';
import TextBlock from 'utils/TextBlock.js';
import {formatCurrency} from "utils/Currency";
import {useAuth} from "contexts/AuthContext";

function CoachItem({coach, isLast, main = false, setHoveredCoach = null, isHovered = false}) {
    const {t} = useTranslation();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const {isAppSubdomain} = useAuth();


    useEffect(() => {
        TranslationClass('.translation');
    }, []);

    return (
        <>
            <Box sx={{mb: 2}}>
                <Grid container spacing={0}>

                    <Grid item
                          xs={12}
                          sm={9}
                          sx={{
                              p: 3,
                              border: 1,
                              borderRadius: '0.5rem',
                              boxShadow: 'none',
                              borderColor: isHovered ? 'primary.main' : 'border.main',
                          }}
                    >

                        <Grid
                            container
                            spacing={0}
                            onMouseEnter={() => {
                                if (!main) {
                                    setHoveredCoach(coach.id);
                                }
                            }}
                        >

                            <Grid item xs={12} sm={3}>
                                <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                                    <Box
                                        component={RouterLink}
                                        to={isAppSubdomain ? `/coach/${coach.id}/` : `https://app.method.do/coach/${coach.id}/`}
                                        sx={{
                                            width: '100%',
                                            paddingTop: '100%',
                                            position: 'relative',
                                            borderRadius: 2,
                                            overflow: 'hidden',
                                            mb: 2,
                                        }}
                                    >
                                        <img
                                            src={
                                                coach.profile_photo && !coach.profile_photo.includes('noavatar.jpeg')
                                                    ? coach.profile_photo.startsWith('https://method.do/images')
                                                        ? `https://api.${coach.profile_photo.slice(8)}`
                                                        : `https://api.method.do/images/${coach.profile_photo}`
                                                    : coach.profile_photo
                                            }
                                            alt={`${coach.expert.first_name} ${coach.expert.last_name}`}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    </Box>
                                    {coach.verified && (
                                        <Box display="flex" alignItems="center" sx={{ mb: {xs: 3, sm: 0} }}>
                                            <VerifiedIcon color="primary" sx={{mr: 1}}/>
                                            <Typography variant="body2" sx={{textAlign: isXs ? "center" : "left"}}>
                                                {t("Specialist has been verified")}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} sx={{px: {xs: 0, sm: 2}, textAlign: isXs ? "center" : "left"}}>
                                    <Typography
                                        variant="h5"
                                        component={RouterLink}
                                        to={isAppSubdomain ? `/coach/${coach.id}/` : `https://app.method.do/coach/${coach.id}/`}
                                        sx={{textDecoration: 'none', color: 'text.primary'}}
                                    >
                                    {coach.expert.first_name} {coach.expert.last_name}
                                </Typography>
                                <Box my={1}>
                                    <Typography variant="body1" sx={{textAlign: isXs ? "center" : "left"}}>
                                        <TextBlock text={coach.about_me}/>
                                    </Typography>
                                </Box>
                                {coach.сoachType && coach.сoachType.length > 0 && (
                                    <Box mb={2}>
                                        <Typography variant="subtitle2" gutterBottom>{t("Topics")}:</Typography>
                                        <Box display="flex" flexWrap="wrap" gap={1}>
                                            {coach.сoachType.map((coachType, index) => (
                                                <Chip
                                                    key={index}
                                                    label={<span>{t(coachType)}</span>}
                                                    size="small"
                                                    color="primary"
                                                    variant="outlined"
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                )}
                                {coach.directions && Array.isArray(coach.directions) && coach.directions.length > 0 && coach.directions.length !== 1 && coach.directions[0] !== "next" && (
                                    <Box mb={2}>
                                        <Typography variant="subtitle2" gutterBottom>{t("Methods")}:</Typography>
                                        <Box display="flex" flexWrap="wrap" gap={1}>
                                            {coach.directions.map((direction, index) => (
                                                <Chip
                                                    key={index}
                                                    label={<span>{t(direction)}</span>}
                                                    size="small"
                                                    color="primary"
                                                    variant="outlined"
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                )}
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">

                                    <Typography variant="h4" textAlign="right" sx={{textAlign: isXs ? "center" : "right"}}>
                                        {coach.session_cost} {formatCurrency(coach.session_cost_currency)}
                                    </Typography>
                                    <Typography variant="body2" sx={{textAlign: isXs ? "center" : "right", mb: {xs: 2, sm: 'auto'} }}>
                                        {t("per session")}
                                    </Typography>

                                    {!main && (
                                        <Box mt="auto">
                                            <Button
                                                component={RouterLink}
                                                to={`/coach/${coach.id}/`}
                                                variant="contained"
                                                color="primary"
                                                size={isXs ? "small" : "medium"}
                                                fullWidth
                                                sx={{mb: 1}}
                                            >
                                                {t("Choose a specialist")}
                                            </Button>
                                            <Button
                                                component={RouterLink}
                                                variant="outlined"
                                                color="secondary"
                                                size={isXs ? "small" : "medium"}
                                                fullWidth
                                            >
                                                {t("Send a message")}
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>

                        </Grid>

                    </Grid>


                </Grid>
            </Box>
        </>
    );
}

export default CoachItem;