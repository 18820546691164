import React, { useState } from "react";
import customAxios from 'configs/axiosConfig';
import Loading from 'components/wrap/Loading';
import NotificationsUpdate from "./notificationsupdate";
import { useTranslation } from 'react-i18next';

const Notifications = ({ user, setUser }) => {
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();

    const unlinkTelegram = async () => {
        if (window.confirm(t("Are you sure you want to disconnect your Telegram account?"))) {
            try {
                setLoading(true);
                const response = await customAxios.post('https://method.do/api/telegram/unlink/');

                if (response.data.status === 'success') {
                    setSuccessMessage(t("Telegram account successfully disconnected."));
                    const updatedUser = {
                        ...user,
                        is_telegram_linked: false,
                        telegram_chat_id: '',
                        telegram_photo_url: '',
                        telegram_token: '',
                        telegram_username: '',
                    };
                    setUser(updatedUser);
                    localStorage.setItem('userData', JSON.stringify(updatedUser));
                } else {
                    setErrorMessage(t("Error:") + ' ' + response.data.message);
                }

            } catch (error) {
                setErrorMessage(t("An error occurred while disconnecting the Telegram account."));
                console.error(error);
            }
            setLoading(false);
        }
    };

    return (
        <>
            <h2 className="h5 text-primary my-4">{t("Event notifications")}</h2>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            {successMessage && <div className="alert alert-success">{successMessage}</div>}

            {user.is_telegram_linked ? (
                loading ? (
                    <Loading/>
                ) : (
                    <div>
                        <div className="btn btn-outline-primary btn-lg">
                            <i className='bx bxl-telegram me-2'></i>{t("Telegram account linked:") + ' ' + user.telegram_username}
                        </div>
                        <div className="btn btn-outline-danger ms-3" onClick={unlinkTelegram}>{t("Delete")}</div>
                    </div>
                )
            ) : (
                <a className="btn btn-outline-primary btn-lg"
                   href={`https://t.me/MethodDoRobot?start=${user.telegram_token}`} target="_blank"
                   rel="noreferrer">
                    <i className='bx bxl-telegram me-2'></i>{t("Connect Telegram")}
                </a>
            )}

            <NotificationsUpdate user={user} setUser={setUser} />
        </>
    );
}

export default Notifications;
