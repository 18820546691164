//UserMessage.js
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment-timezone';
import {useAuth} from 'contexts/AuthContext';
import noAvatar from "../../../../static/images/noavatar.jpeg";
import Image from "react-bootstrap/Image";

const UserMessage = ({message, websocket}) => {
    const {t} = useTranslation();
    const {timeZone} = useAuth();
    if (!message) {
        return null;
    }

    if (!message.is_read && websocket.current && websocket.current.readyState === WebSocket.OPEN) {
        websocket.current.send(JSON.stringify({
            action: 'mark_as_read',
            message_id: message.id
        }));
    }


    return (
        <div className="d-flex align-items-start mb-3">
            <Image
                src={message?.sender.profile_photo || noAvatar}
                className="rounded-circle"
                style={{
                    width: '48px',
                    height: '48px',
                    objectFit: 'cover'
                }}
                alt={t("Profile Photo")}
            />
            <div className="ps-2 ms-1" style={{maxWidth: '348px'}}>
                <div className="bg-secondary p-3 mb-1" style={{
                    borderTopRightRadius: '.5rem',
                    borderBottomRightRadius: '.5rem',
                    borderBottomLeftRadius: '.5rem'
                }}>
                    {message.content}
                </div>
                <div className="fs-sm text-muted">
                    {moment(message.timestamp).tz(timeZone).format('HH:mm a')}
                </div>
            </div>
        </div>
    );
};

export default UserMessage;
