import React, {useEffect, useState, useContext, useRef} from 'react';
import ZoomVideo from '@zoom/videosdk';
import customAxios from "../../../../configs/axiosConfig";
import uitoolkit from '@zoom/videosdk-ui-toolkit'
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css'
import {useTranslation} from 'react-i18next';
import {Box, Button, Typography} from '@mui/material';


const ZoomMeetingNew = () => {
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();
    const [isPreviewActive, setIsPreviewActive] = useState(false);


    const previewContainerRef = useRef(null);

    const initPreview = async () => {
        if (previewContainerRef.current && !isPreviewActive) {
            uitoolkit.openPreview(previewContainerRef.current);
            setIsPreviewActive(true);
        }
    };

    const closePreview = async () => {
        if (previewContainerRef.current && isPreviewActive) {
            uitoolkit.closePreview(previewContainerRef.current);
            setIsPreviewActive(false);
        }
    };


    return (
        <div>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mx: 'auto',
                p: 3,
                minHeight: 600,
            }}>

                <div id="previewContainer" ref={previewContainerRef}></div>
                {!isPreviewActive && (
                    <>
                        <Typography level="h4" component="h1" sx={{textAlign: 'center', mb: 1}}>
                            {t('This is where you can test the camera and sound')} <br/>
                            {t('Please make sure you have a working camera and microphone')} <br/>
                            {t('You can also check the quality of your internet connection')}
                        </Typography>

                        <Button
                            loading={loading}
                            onClick={initPreview}
                            disabled={isPreviewActive}
                            size="lg"
                            variant="contained"
                        >
                            {t('Start the test')}
                        </Button>

                        <Typography level="caption" sx={{textAlign: 'center'}} mt={3}>
                            {t('This is not transmitted or stored on the server, but is only available to you')}
                        </Typography>
                    </>
                )}


                {isPreviewActive && (
                    <Button
                        loading={loading}
                        onClick={closePreview}
                        size="lg"
                        variant="contained"
                    >
                        {t('Close the test')}
                    </Button>
                )}

            </Box>

        </div>
    );
};

export default ZoomMeetingNew;
