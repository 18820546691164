import React, {useState} from 'react';
import {Form, InputGroup, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import CustomAxios from 'configs/axiosConfig';
import Loading from 'components/wrap/Loading';

const PromoCodeInput = ( SessionCost, Currency, setDiscount, setPromoCode ) => {
    const {t} = useTranslation();
    const [showPromoInput, setShowPromoInput] = useState(false);
    const [promoCode, setPromoCodeState] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);


    const handleApplyPromoCode = async () => {
        if (!promoCode.trim()) {
            setMessage(t('Enter promo code'));
            return;
        }

        try {
            setLoading(true);
            const response = await CustomAxios.get(`https://api.method.do/api/order/checkpromocode/?code=${promoCode}`);
            if (response.data.valid) {
                if (response.data.type === 'percent') {
                    setMessage(t('Promo code accepted! Discount: ') + response.data.discount + '%');
                    setDiscount(SessionCost * response.data.discount / 100);
                } else if (response.data.type === 'amount') {
                    if (response.data.currency !== Currency) {
                        setMessage(t('Promo code discount currency does not match the order currency'));
                    } else {
                        setMessage(t('Promo code accepted! Discount: ') + response.data.discount + ' ' + response.data.currency);
                        setDiscount(response.data.discount);
                    }
                }
                setPromoCode(promoCode);
            } else {
                setMessage(t(response.data.message));
            }
        } catch (error) {
            setMessage(t('Error occurred while applying promo code'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`mb-3`}>
            {!showPromoInput && (
                <div onClick={() => setShowPromoInput(true)} className="mb-3 text-secondary" style={{cursor: 'pointer'}}>
                    {t('Have a promo code?')}
                </div>
            )}
            {message && <div className="mt-2 mb-2 text-danger">{message}</div>}
            {showPromoInput && (
                <Form.Group className={`mb-3`}>
                    {loading && <Loading/>}
                    <InputGroup>
                        <Form.Control
                            type="text"
                            placeholder={t('Promo code')}
                            value={promoCode}
                            onChange={(e) => setPromoCodeState(e.target.value)}
                            className={'border-end-0'}
                        />
                        <Button variant="outline-secondary" className={'rounded-end-2  border-start-0'}
                                onClick={handleApplyPromoCode}>
                            {t('Apply')}
                        </Button>
                    </InputGroup>
                </Form.Group>
            )}
        </div>
    );
};

export default PromoCodeInput;
