import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, ButtonGroup} from 'react-bootstrap';

const LanguageSwitcher = ({color = 'secondary'}) => {
    const {i18n} = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);

    };

    // Функция для проверки активного языка
    const isActive = (language) => {
        return i18n.language === language;
    };

    return (
        <>
            <div className='nav-pills nav-pills-falcon'>
                <ButtonGroup>
                    <Button
                        variant={`outline-${color}`}
                        className={`btn-sm ${isActive('en') ? 'active' : `btn-outline-${color}` } `}
                        onClick={() => changeLanguage('en')}
                    >
                        English
                    </Button>
                    <Button
                        variant={`outline-${color}`}
                        className={`btn-sm ${isActive('ru') ? 'active' : `btn-outline-${color}` } `}
                        onClick={() => changeLanguage('ru')}
                    >
                        Русский
                    </Button>
                </ButtonGroup>
            </div>
        </>
    );
};

export default LanguageSwitcher;
