import React, {useEffect, useState} from 'react';
import {Typography, IconButton, Container, Box, AppBar, Toolbar, Grid, Button} from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {useTranslation} from "react-i18next";
import {Link} from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Skeleton from "@mui/material/Skeleton";
import VideoPlayer from "../../../../../lead/pages/course/components/VideoPlayer";
import {useNavigate} from 'react-router-dom';
import customAxios from "configs/axiosConfig";
import LoadingButton from '@mui/lab/LoadingButton';
import SafeHtmlDisplay from "configs/safeHtml"


const Lesson = ({loading, fetchCourseData, menuOpen, setMenuOpen, currentLesson, setCurrentLesson, userCourseProgress, userLessonsProgress }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loadingCompleted, setLoadingCompleted] = useState(false);

    const handleDrawerOpen = () => {
        setMenuOpen(true);
    };

    const handlePreviousLesson = () => {
        if (currentLesson?.lesson) {
            const currentIndex = currentLesson?.lesson ? userCourseProgress.course.lessons.findIndex(lesson => lesson.id === currentLesson.lesson.id) : -1;
            console.log('currentIndex', currentIndex);

            if (currentIndex > 0) {
                setCurrentLesson(userLessonsProgress[currentIndex - 1]);
            }
        }
    };

    const handleNextLesson = () => {
        if (currentLesson?.lesson) {
            const currentIndex = currentLesson?.lesson ? userCourseProgress.course.lessons.findIndex(lesson => lesson.id === currentLesson.lesson.id) : -1;
            console.log('currentIndex', currentIndex);

            if (currentIndex < userCourseProgress?.course.lessons.length - 1) {
                setCurrentLesson(userLessonsProgress[currentIndex + 1]);
            }
        }
    };

    const GetAccess = () => {
        if (userCourseProgress?.has_access === false) {
            navigate(`/program/${userCourseProgress?.course.id}`);
        }
    }

    const markLessonComplete = async (lessonId) => {
        setLoadingCompleted(true);
        try {
            const response = await customAxios.post(`https://api.method.do/api/lessons/${lessonId}/complete/`);
            console.log(response.data);
        } catch (error) {
            console.error('There was an error marking the lesson as complete:', error);
        } finally {
            setLoadingCompleted(false);
            fetchCourseData();
        }
    };

    const currentIndex = (userCourseProgress?.course?.lessons && currentLesson?.lesson) ? userCourseProgress.course.lessons.findIndex(lesson => lesson.id === currentLesson.lesson.id) : -1;

    useEffect(() => {
        console.log("Current index:", currentIndex);
        console.log("Current lesson:", currentLesson);
    }, [currentIndex, currentLesson]);

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <AppBar position="static" color="white" sx={{boxShadow: 'none', top: 0}}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" component={Link} to={`../program/${userCourseProgress?.course.id}`} sx={{textDecoration: 'none', color: 'inherit'}}>
                        <ArrowBackIosIcon/>
                    </IconButton>

                    {loading ? (
                        <Skeleton variant="rectangular" width={200} height={20} sx={{ml: 2}}/>
                    ) : (
                        <Typography variant="h6" component={Link} to={`../program/${userCourseProgress?.course.id}`} sx={{textDecoration: 'none', color: 'inherit'}}>
                            {userCourseProgress?.course?.title}
                        </Typography>
                    )}

                    {!menuOpen && (
                        <Button

                            size="small"
                            color="secondary"
                            startIcon={<MenuBookIcon/>}
                            onClick={handleDrawerOpen}
                            sx={{
                                marginLeft: 'auto',
                                px: {xs: 1, sm: 2},
                            }}
                        >
                            {t('Lessons')}
                        </Button>
                    )}
                </Toolbar>
            </AppBar>

            {/* Если нет активного урока */}
            {!currentLesson && currentIndex === -1 ? (
                <Container sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h4" textAlign="center" pb={2}>
                        {t('Select a lesson')}
                    </Typography>
                </Container>
            ) : (
                <Container sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    {/* Основной контент урока */}
                    {!currentLesson?.has_access && currentIndex >= 0 ? (
                        <Container sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant="h4" textAlign="center" pb={2}>
                                {t('Lesson unavailable')}
                            </Typography>
                            {!userCourseProgress?.has_access ? (
                                <Button variant="contained" color="primary" onClick={() => GetAccess()}>
                                    {t('Get access')}
                                </Button>
                            ) : (
                                <Typography variant="body1">
                                    {t('Complete the previous lesson')}
                                </Typography>
                            )}
                        </Container>
                    ) : (
                        <Box sx={{ p: { xs: 1, md: 5 }, flex: 1 }}>
                            {loading ? (
                                <Skeleton variant="rectangular" width={'100%'} height={40} sx={{ mt: 2 }} />
                            ) : (
                                <Typography variant="h5" textAlign="center" pb={2}>
                                    {currentLesson?.lesson?.title || t('Lesson')}
                                </Typography>
                            )}

                            {loading ? (
                                <Skeleton variant="rectangular" width="100%">
                                    <div style={{ paddingTop: '57%' }} sx={{ mt: 2 }} />
                                </Skeleton>
                            ) : (
                                currentLesson && <VideoPlayer videoUrl={currentLesson?.lesson?.video_url} />
                            )}

                            {loading ? (
                                <Skeleton variant="rectangular" width={'100%'} height={100} sx={{ mt: 2 }} />
                            ) : (
                                <Typography variant="body1">
                                    <SafeHtmlDisplay htmlContent={currentLesson?.lesson?.description} />
                                </Typography>
                            )}

                            {!currentLesson?.is_finished && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 'auto', py: 2 }}>
                                    <LoadingButton
                                        variant="contained"
                                        color="secondary"
                                        sx={{ mx: 'auto' }}
                                        onClick={() => markLessonComplete(currentLesson.lesson.id)}
                                        loading={loadingCompleted}
                                    >
                                        {t('Finish the lesson')}
                                    </LoadingButton>
                                </Box>
                            )}
                        </Box>
                    )}
                </Container>
            )}

            <AppBar position="static" color="white" sx={{boxShadow: 'none', bottom: 0, top: 'auto', borderTop: '1px solid rgba(0, 0, 0, 0.12)'}}>
                <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button
                        color="secondary"
                        onClick={handlePreviousLesson}
                        disabled={currentIndex === 0}
                        startIcon={<ArrowBackIosIcon/>}
                    >
                        {t('Previous Lesson')}
                    </Button>

                    <Button
                        color="secondary"
                        onClick={handlePreviousLesson}
                        disabled
                    >
                        {t('Lesson')} {currentIndex + 1} {' / '} {userCourseProgress?.course?.lessons.length}
                    </Button>

                    <Button
                        color="secondary"
                        onClick={handleNextLesson}
                        disabled={currentIndex === userCourseProgress?.course?.lessons.length - 1}
                        endIcon={<ArrowForwardIosIcon/>}
                    >
                        {t('Next Lesson')}
                    </Button>
                </Toolbar>
            </AppBar>

        </Box>
    );
};

export default Lesson;
