import React, {useState, useEffect, useRef} from 'react';
import CustomAxios from '../../../configs/axiosConfig.js';
import {useNavigate, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useAuth} from 'contexts/AuthContext.js';

import {
    Box,
    Button,
    TextField,
    Alert,
    DialogContent,
    CircularProgress,
    DialogActions,
    Typography,
} from '@mui/material';

const EmailCodeVerification = ({email, nextPage = null, handleClose}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [code, setCode] = useState(new Array(6).fill(''));
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const inputsRef = useRef([]);
    const {user, login} = useAuth();
    const location = useLocation();
    const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;

    if (!nextPage) {
        nextPage = currentUrl;
        localStorage.setItem('nextPage', nextPage);
    }

    const sendCode = () => {
        if (timeLeft > 0) {
            console.log('Resend code is not allowed yet');
            return;
        }
        setLoading(true);
        setError(null);
        setCode(new Array(6).fill(''));

        CustomAxios.post('https://api.method.do/api/user/send_verification_code/', JSON.stringify({email}), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                setTimeLeft(63); // Устанавливаем таймер на 60 секунд
                setSuccess(t('Verification code sent successfully'));
            })
            .catch((error) => {
                if (error.response && error.response.status === 429) {
                    console.error('Error sending verification code:', error.response.data);
                    setError(t('Failed to send verification code. Please wait.') + ` (${error.response.data.time_left + 3}s)`);
                    setSuccess(null);
                    setTimeLeft(error.response.data.time_left + 3);
                } else {
                    console.error('Error sending verification code:', error);
                    setSuccess(null);
                    setError(t('Failed to send verification code'));
                }
            })
            .finally(() => {
                setSuccess(null);
                setLoading(false);
            });
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text').slice(0, 6); // Ограничиваем длину вставляемых данных шестью символами
        const newCode = pasteData.split('').map((char, index) => !isNaN(char) ? char : '');

        setCode(prev => {
            const updatedCode = [...prev];
            for (let i = 0; i < newCode.length; i++) {
                updatedCode[i] = newCode[i];
            }
            return updatedCode;
        });

        if (inputsRef.current[newCode.length - 1]) {
            inputsRef.current[newCode.length - 1].focus();
        }
    };

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        const prevValue = code[index];

        if (value === '' && prevValue !== '') {
            let newCode = [...code];
            newCode[index] = '';
            setCode(newCode);

            if (index > 0 && inputsRef.current[index - 1]) {
                inputsRef.current[index - 1].focus();
            }
        } else if (!isNaN(value) && value.length <= 1) {
            let newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            if (value !== '' && index < code.length - 1) {
                inputsRef.current[index + 1].focus();
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setError(null);

        const fullCode = code.join('');

        if (!fullCode) {
            setError(t('Please enter the code'));
            return;
        }

        if (!/^[0-9]{6}$/.test(fullCode)) {
            setError(t('The code must be 6 digits'));
            return;
        }
        setLoading(true);

        CustomAxios.post('https://api.method.do/api/user/code_verification/', JSON.stringify({email, code: fullCode}), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    login({
                        userData: response.data,
                        access_token: response.data.access_token,
                        refresh_token: response.data.refresh_token,
                        is_authenticated: true
                    });
                    if (nextPage) {
                        localStorage.setItem('nextPage', nextPage);
                        handleClose();
                        navigate(nextPage);
                    }
                    handleClose();
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setError(t('Verification failed. Please try again') + `: ${t(error.response.data.error)}`);
                } else {
                    setError(t('Verification failed. Please try again'));
                }
            }).finally(
            setLoading(false)
        );
    };

    useEffect(() => {
        if (timeLeft > 0) {
            const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timerId);
        }
    }, [timeLeft]);

    useEffect(() => {
        sendCode();
    }, []);

    return (
        <>
            <DialogContent sx={{pb: 0}}>
                <Typography variant="body2" sx={{textAlign: 'center'}} gutterBottom>
                    {t("Enter the code sent to")} {email}
                </Typography>
                <form onPaste={handlePaste} onSubmit={handleSubmit} noValidate>
                    {error && <Alert severity="error" sx={{mb: 2}}>{t(error)}</Alert>}
                    {success && <Alert severity="success" sx={{mb: 2}}>{t(success)}</Alert>}
                    <Box my={2}>
                        <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                            {code.map((item, index) => (
                                <TextField
                                    key={index}
                                    type="text"
                                    value={item}
                                    onChange={(e) => handleInputChange(e, index)}
                                    inputRef={(ref) => inputsRef.current[index] = ref}
                                    autoComplete="off"
                                    inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: '50px' }}
                                />
                            ))}
                        </Box>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions sx={{flexDirection: 'column', alignItems: 'stretch', px: 3, pb: 3}}>
                <Box textAlign="center" mb={2}>
                    <Button onClick={sendCode} disabled={timeLeft !== 0} variant="outlined">
                        {timeLeft > 0 ? `${t("Resend")} (${timeLeft}s)` : t("Resend")}
                    </Button>
                </Box>

                <Button type="submit" variant="contained" color="primary" fullWidth onClick={handleSubmit} disabled={loading}>
                    {loading ? <CircularProgress size={24}/> : t("Verify")}
                </Button>
            </DialogActions>
        </>
    )
        ;
};

export default EmailCodeVerification;
