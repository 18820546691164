import React, { useState, useEffect, useRef } from 'react'
import Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.min.css';
import { dictionaries } from 'utils/getTranslation';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';


function FilterSidebar({ filters, handleFilterChange, coaches, applyFilters }) {
    const { t } = useTranslation();
    const maritalStatusRef = useRef(null);
    const industryRef = useRef(null);
    const coachTypeRef = useRef(null);
    const coachQualificationRef = useRef(null);
    const directionsRef = useRef(null);
    const [priceRange, setPriceRange] = useState([0, 10000]);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(10000);

    useEffect(() => {
        if (coaches && coaches.length > 0) {
            const prices = coaches.map(coach => coach.session_cost);
            const minCost = Math.min(...prices);
            const maxCost = Math.max(...prices);
            setPriceRange([minCost, maxCost]);
        }
    }, [coaches]);

    const handleMinPriceInputChange = (e) => {
        const newMin = Math.max(Number(e.target.value), 0);
        setPriceRange([newMin, priceRange[1]]);
        handleFilterChange('minPrice', newMin);
    };

    const handleMaxPriceInputChange = (e) => {
        const newMax = Math.min(Number(e.target.value), 20000);
        setPriceRange([priceRange[0], newMax]);
        handleFilterChange('maxPrice', newMax);
    };

    const handlePriceRangeChange = (event, newValue) => {
        setPriceRange(newValue);
        handleFilterChange('minPrice', newValue[0]);
        handleFilterChange('maxPrice', newValue[1]);
    };



    useEffect(() => {
        const choicesMaritalStatus = new Choices(maritalStatusRef.current, {
            searchEnabled: true,
            removeItemButton: true,
            allowHTML: true,
        });
        const choicesIndustry = new Choices(industryRef.current, {
            searchEnabled: true,
            removeItemButton: true,
            allowHTML: true,

        });
        const choicesCoachType = new Choices(coachTypeRef.current, {
            searchEnabled: true,
            removeItemButton: true,
            allowHTML: true,

        });
        const choicesCoachQualification = new Choices(coachQualificationRef.current, {
            searchEnabled: true,
            removeItemButton: true,
            allowHTML: true,

        });
        const choicesDirections = new Choices(directionsRef.current, {
            searchEnabled: true,
            removeItemButton: true,
            allowHTML: true,
        });

        // Очищаем память
        return () => {
            choicesMaritalStatus.destroy();
            choicesIndustry.destroy();
            choicesCoachType.destroy();
            choicesCoachQualification.destroy();
            choicesDirections.destroy();
        };
    }, []);

    const handleMaritalStatusChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        handleFilterChange('maritalStatus', selectedOptions);
    };

    const handleIndustryChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        handleFilterChange('industry', selectedOptions);
    };

    const handleCoachTypeChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        handleFilterChange('coachType', selectedOptions);
    };

    const handleCoachQualificationChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        handleFilterChange('coachQualification', selectedOptions);
    };

    const handleDirectionsChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        handleFilterChange('directions', selectedOptions);
    };

    const handleResetFilter = () => {
        setPriceRange([minPrice, maxPrice]);
        handleFilterChange('minPrice', minPrice);
        handleFilterChange('maxPrice', maxPrice);
        handleFilterChange('maritalStatus', []);
        handleFilterChange('industry', []);
        handleFilterChange('coachType', []);
        handleFilterChange('coachQualification', []);
        handleFilterChange('directions', []);
    };


    return (
        <form id="filter-form">
            <div className="card card-body p-3 mb-4">
                <label>{t("Consultation cost:")}</label>
                <div className="range-slider pb-4">
                    <Box>
                        <Slider
                            value={priceRange}
                            onChange={handlePriceRangeChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            min={filters.minPrice}
                            max={filters.maxPrice}
                        />
                    </Box>
                    <div className="d-flex align-items-center">
                        <div className="w-50">
                            <div className="input-group">
                                <span className="input-group-text px-2">
                                    <i className="bx bx-ruble mx-1"></i>
                                </span>
                                <input
                                    type="number"
                                    value={priceRange[0]}
                                    onChange={handleMinPriceInputChange}
                                    className="form-control form-control-sm range-slider-value-min"
                                    name="min_price"
                                />
                            </div>
                        </div>
                        <div className="mx-1 px-2 fs-xs">—</div>
                        <div className="w-50">
                            <div className="input-group">
                                <span className="input-group-text px-2">
                                    <i className="bx bx-ruble mx-1"></i>
                                </span>
                                <input
                                    type="number"
                                    value={priceRange[1]}
                                    onChange={handleMaxPriceInputChange}
                                    className="form-control form-control-sm range-slider-value-max"
                                    name="max_price"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <label htmlFor="marital_status" className="pb-1">{t("Marital status:")}</label>
                <select
                    ref={maritalStatusRef}
                    name="marital_status[]"
                    id="marital_status"
                    className="form-control js-choice"
                    multiple
                    value={filters.maritalStatus}
                    onChange={handleMaritalStatusChange}
                >
                    {dictionaries.marital_status_dict && Object.entries(dictionaries.marital_status_dict).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                    ))}
                </select>

                <label htmlFor="industry" className="pb-1">{t("Industry:")}</label>
                <select
                    ref={industryRef}
                    name="industry[]"
                    id="industry"
                    className="form-control js-choice"
                    multiple
                    value={filters.industry}
                    onChange={handleIndustryChange}
                >
                    {Object.entries(dictionaries.industry_dict).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                    ))}
                </select>

                <label htmlFor="coachType" className="pb-1">{t("Coaching type:")}</label>
                <select
                    ref={coachTypeRef}
                    name="coachType"
                    id="coachType"
                    className="form-control js-choice"
                    multiple
                    value={filters.coachType}
                    onChange={handleCoachTypeChange}
                >
                    {Object.entries(dictionaries.coachType_dict).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                    ))}
                </select>

                <label htmlFor="coachQualification" className="pb-1">{t("Qualification:")}</label>
                <select
                    ref={coachQualificationRef}
                    name="coachQualification[]"
                    id="coachQualification"
                    className="form-control js-choice"
                    multiple
                    value={filters.coachQualification}
                    onChange={handleCoachQualificationChange}
                >
                    {Object.entries(dictionaries.coachQualification_dict).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                    ))}
                </select>

                <label htmlFor="directions" className="pb-1">{t("Directions:")}</label>
                <select
                    ref={directionsRef}
                    name="directions[]"
                    id="directions"
                    className="form-control js-choice"
                    multiple
                    value={filters.directions}
                    onChange={handleDirectionsChange}
                >
                    {Object.entries(dictionaries.directions_dict).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                    ))}
                </select>

                <div className="btn btn-primary mx-auto" onClick={applyFilters}>
                    {t("Apply filter")}
                </div>
            </div>
        </form>
    );
}

export default FilterSidebar;
