import React from 'react';
import FaqItem from './FaqItem';

const FaqList = ({ faqs }) => {
    return (
        <div className="accordion" id="faq">
            {faqs.map((faq, index) => (
                <FaqItem key={index} faq={faq} />
            ))}
        </div>
    );
};

export default FaqList;
