import React, {useState, useEffect} from 'react';
import {useAuth} from 'contexts/AuthContext';
import {useTranslation} from 'react-i18next';
import {Modal, Button} from 'react-bootstrap';
import moment from 'moment';
import {TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const TimeZoneSelector = ({ButtonClassName}) => {
    const {timeZone = '', setTimeZone} = useAuth();
    const [newTimeZone, setNewTimeZone] = useState(timeZone);
    const {getTimezoneData, formatTimeZone} = useAuth();
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [currentTime, setCurrentTime] = useState(() => {
        if (moment.tz.zone(timeZone)) {
            return moment().tz(timeZone).format('HH:mm:ss');
        } else {
            return moment().format('HH:mm:ss');
        }
    });

    const saveTimeZone = () => {
        setTimeZone(newTimeZone);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (moment.tz.zone(timeZone)) {
                setCurrentTime(moment().tz(timeZone).format('HH:mm:ss'));
            } else {
                setCurrentTime(moment().format('HH:mm:ss'));
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [timeZone]);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <Button variant="outline-secondary" onClick={handleOpenModal} className={`btn-sm ${ButtonClassName}`}>
                {formatTimeZone(timeZone) || t("Select Time Zone")}
            </Button>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton className="px-x1 border-bottom-0 pb-0">
                    <Modal.Title as="h5">{t("Select Time Zone")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Autocomplete
                        options={getTimezoneData()}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        value={getTimezoneData().find((option) => option.tz === newTimeZone)}
                        onChange={(event, newValue) => {
                            setNewTimeZone(newValue?.tz);
                        }}
                        sx={{pb: 2}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                }}
                            />
                        )}
                    />

                    <div>
                        {t("Current Time")}: {currentTime} <br/>
                        {t("Current Time Zone")}: {formatTimeZone(timeZone)}
                    </div>
                </Modal.Body>

                <Modal.Footer className={'border-0 pt-0'}>
                    <Button pb={2} onClick={saveTimeZone} variant="primary">
                        {t("Save")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TimeZoneSelector;