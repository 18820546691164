import React from "react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from "swiper/react";


const SwiperPhotos = ({photos, gallery}) => {
    return (

        <Swiper
            spaceBetween={5}
            slidesPerView={"auto"}
            pagination={{clickable: true}}
            freeMode={true}
        >
            {photos.map((image, index) => (
                <SwiperSlide key={index} style={{ width: 'auto', height: '130px' }}>
                    <a href={`https://api.${image.image.slice(8)}`} data-fancybox={gallery}>
                        <img src={`https://api.${image.image.slice(8)}`} alt="" className="rounded overflow-hidden"
                             style={{maxHeight: '130px'}}/>
                    </a>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

export default SwiperPhotos;