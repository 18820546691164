import React from 'react';


const BackgroundCircles = () => {
    const colors = [
        'rgb(254, 235, 84)', 'rgb(247, 191, 92)', 'rgb(243, 126, 79)',
        'rgb(228, 79, 103)', 'rgb(250, 51, 105)', 'rgb(167, 36, 104)',
        'rgb(112, 45, 107)', 'rgb(45, 187, 233)',
        'rgb(71, 164, 221)', 'rgb(51, 119, 184)',
        'rgb(95,125,193)', 'rgb(33, 91, 119)',
        'rgb(48, 193, 149)'
    ];

    const circleStyles = (index, total) => {
        const radius = 100; // радиус размещения центров кругов
        const angle = (index / total) * Math.PI * 3; // угол для каждого круга
        const x = radius * Math.cos(angle); // X координата
        const y = radius * Math.sin(angle); // Y координата

        return {
            width: `${30 + index * 3}%`,
            height: `${30 + index * 3}%`,
            backgroundColor: colors[index % colors.length],
            top: `40%`,
            left: `40%`,
            borderRadius: '50%',
            transform: `translate(${x}%, ${y}%)`,
            position: 'absolute',
            filter: 'blur(150px)',
        };
    };

    return (
        <>
            <div style={{position: 'fixed', width: '100vw', height: '100vh', overflow: 'hidden', zIndex: -1}}>
                {Array.from({length: 16}).map((_, index) => (
                    <div
                        key={index}
                        style={circleStyles(index, 16)}
                    ></div>
                ))}
            </div>
        </>
    );
};

export default BackgroundCircles;

