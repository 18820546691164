import React, {useState, useEffect} from 'react';
import customAxios from 'configs/axiosConfig';
import Loading from 'components/wrap/Loading';
import { useTranslation } from 'react-i18next';

const NotificationsUpdate = ({user, setUser}) => {
    const { t } = useTranslation();
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [notifications, setNotifications] = useState({
        activity: user.emailActivity || true,
        news: user.emailNews || true,
    });
    const [loading, setLoading] = useState({
        activity: false,
        news: false,
    });


    const handleNotificationChange = async (type) => {

        setLoading(prev => ({ ...prev, [type]: true }));
        const newValue = !notifications[type];

        try {
            const response = await customAxios.post('https://method.do/api/notifications/update/', {
                email: user.email,
                [`${type}Notifications`]: newValue,
            });

            if (response.data.status === 'success') {
                setNotifications(prev => ({ ...prev, [type]: newValue }));
                setSuccessMessage(t('Notification settings updated.'));
                setUser(prev => ({ ...prev, [`${type}Notifications`]: newValue }));
            } else {
                setErrorMessage(t('Error:') + ' ' + response.data.message);
            }
        } catch (error) {
            setErrorMessage(t('An error occurred while updating notification settings.'));
            console.error(error);
        }

        setLoading(prev => ({...prev, [type]: false}));
    };

    useEffect(() => {
        setNotifications({
            activity: user.emailActivity || false,
            news: user.emailNews || false,
        });
    }, [user.emailActivity, user.emailNews]);



    return (
        <>
            <form className="ps-md-3 ps-lg-0 pt-md-4 pb-md-4">
                <div className="row align-items-end pb-3 mb-2 mb-sm-4">
                    <div className="col-lg-6 col-sm-7">
                        <label htmlFor="notifications-email" className="form-label fs-base">{t("Email for notifications")}</label>
                        <input
                            id="notifications-email"
                            className="form-control form-control-lg"
                            value={user.email}
                            readOnly
                        />
                    </div>
                </div>

                {errorMessage && <div className="alert alert-danger mb-3">{errorMessage}</div>}
                {successMessage && <div className="alert alert-success mb-3">{successMessage}</div>}

                {loading.activity ? (
                    <Loading message={t("Updating settings...")}/>
                ) : (
                    <div className="form-check form-switch form-switch-success d-flex mb-4">
                        <input
                            type="checkbox"
                            id="activity"
                            className="form-check-input flex-shrink-0"
                            checked={notifications.activity}
                            onChange={() => handleNotificationChange('activity')}
                        />
                        <label className="form-check-label ps-3">
                            <span className="h6 d-block mb-2">{t("Notify about changes in consultations")}</span>
                            <span className="fs-sm text-muted">{t("Receive notifications about new messages, schedule changes, and other events related to your consultations.")}</span>
                        </label>
                    </div>
                )}

                {loading.news ? (
                    <Loading message={t("Updating settings...")}/>
                ) : (
                    <div className="form-check form-switch form-switch-success d-flex mb-4">
                        <input
                            type="checkbox"
                            id="news"
                            className="form-check-input flex-shrink-0"
                            checked={notifications.news}
                            onChange={() => handleNotificationChange('news')}
                        />
                        <label className="form-check-label ps-3">
                            <span className="h6 d-block mb-2">{t("Receive news from method.do")}</span>
                            <span className="fs-sm text-muted">{t("Receive news about new features, updates, and other events related to your account.")}</span>
                        </label>
                    </div>
                )}

            </form>
            <hr></hr>
        </>    )
}

export default NotificationsUpdate;