import moment from 'moment-timezone';


// В файле, например, helpers.js
export function formatCurrency(currency) {
    switch (currency) {
        case 'RUB':
            return '₽';
        case 'USD':
        case 'US':
            return '$';
        default:
            return currency;
    }
}
