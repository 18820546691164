import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    Typography,
    Button,
    Card,
    CardMedia,
    Tabs,
    Tab,
    IconButton,
    Container,
    ListItemIcon,
    ListItemText, ListItemButton
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useTranslation} from "react-i18next";
import {Link, useParams} from 'react-router-dom';
import customAxios from "configs/axiosConfig";
import {MenuBook, AccessTime, Group, LockOutlined} from '@mui/icons-material';
import moment from "moment/moment";
import Skeleton from "@mui/material/Skeleton";
import ReactPlayer from 'react-player';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {formatCurrency} from "utils/Currency";
import {FunctionContext} from 'contexts/FunctionContext';
import {useNavigate} from 'react-router-dom';
import SEO from "../../../../configs/SEO";

const Program = () => {
    const [value, setValue] = useState('description');
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [course, setCourse] = useState(null);
    const [userCourseProgress, setUserCourseProgress] = useState(null);
    const [userLessonsProgress, setUserLessonsProgress] = useState([]);
    const [hasAccess, setHasAccess] = useState(false);
    const courseId = useParams().id;
    const navigate = useNavigate();

    const {updateProduct} = useContext(FunctionContext);


    const fetchCourseData = async () => {
        setLoading(true);
        try {
            const response = await customAxios.get(`https://api.method.do/api/course/${courseId}/access/`);
            const {course, user_course_progress, user_lessons_progress, has_access} = response.data;

            setCourse(course);
            setHasAccess(has_access);

            if (has_access) {
                setUserCourseProgress(user_course_progress);
                setUserLessonsProgress(user_lessons_progress);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchCourseData();
    }, []);

    const getLessonText = (count) => {
        const titles = ['урок', 'урока', 'уроков'];
        const cases = [2, 0, 1, 1, 1, 2];
        return titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]];
    };
    const calculateTotalDuration = (course) => {
        let totalDuration = 0;
        let lessons = course?.lessons || [];

        lessons.forEach((lesson) => {
            if (lesson.duration) {
                totalDuration += moment.duration(lesson.duration).asSeconds();
            }
        });

        return totalDuration;
    };

    const GetAccess = (courseId) => {
        updateProduct({type: 'Course', id: courseId});
        navigate('/checkout');
        return;
    };


    return (
        <>
            <SEO
                title={course?.title}
                description=""
                image="https://s3.timeweb.cloud/353db462-31b45b5a-a72b-45e7-89b8-59c51b99c932/images/add_coach.jpg"
            />

            <Container sx={{px: {xs: 0, md: 2}}}>
                {/* Go back */}
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    px: 2,
                    zIndex: 50,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    borderRadius: 4,
                    mt: 1,
                    ml: 1,
                }}>
                    <IconButton component={Link} to="../programs" sx={{color: 'white', px: 1}}>
                        <ArrowBackIosIcon/>
                    </IconButton>
                    <Typography variant="body1" component={Link} to="../programs"
                                sx={{textDecoration: 'none', color: 'white'}}>
                        {t('Back to programs')}
                    </Typography>
                </Box>

                {/* Program Image and Title */}
                <Box sx={{position: 'relative', mb: 2, borderRadius: 6, overflow: 'hidden'}}>
                    {loading ? (
                        <Skeleton variant="rectangular" height={200} sx={{borderRadius: 6}}/>
                    ) : (
                        <CardMedia
                            component="img"
                            height="200"
                            image={course?.imagePreview}
                            alt="Ocean waves"
                            sx={{objectFit: 'contain', objectPosition: 'center'}}
                        />
                    )}
                    {!loading && course?.videoPreview && (
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <ReactPlayer
                                url={course?.videoTrailer}
                                controls
                                playing
                                width="100%"
                                height="100%"
                                config={{
                                    wistia: {
                                        options: {
                                            videoFoam: true,
                                            controlsVisibleOnLoad: true,
                                            fullscreenButton: true,
                                            playbar: false,
                                            volumeControl: true,
                                            qualityControl: true,
                                            captionsButton: true,
                                            autoplay: true,
                                        }
                                    }
                                }}
                                light={
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: '#000',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <video
                                            src={course?.videoPreview}
                                            width="100%"
                                            height="100%"
                                            style={{position: 'absolute', top: 0, left: 0}}
                                            muted
                                            loop
                                            autoPlay
                                        />
                                        <IconButton
                                            sx={{
                                                position: 'absolute',
                                                color: 'white',
                                                backgroundColor: 'rgba(0, 124, 203, 0.8)',
                                                borderRadius: '12px',
                                                padding: '10px 20px',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 89, 145, 0.8)',
                                                }
                                            }}
                                        >
                                            <PlayArrowIcon
                                                sx={{fontSize: '48px'}}
                                            />
                                        </IconButton>
                                    </Box>
                                }
                                onReady={() => {
                                    console.log('Player is ready');
                                }}
                                onEnded={() => {
                                    console.log('Video ended');
                                }}
                            />
                        </Box>
                    )}
                </Box>

                {/* Program Info Card */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', sm: 'row'},
                    alignItems: {xs: 'flex-start', sm: 'center'},
                    justifyContent: 'space-between',
                    px: {xs: 2, md: 2},
                    mb: 2,
                    mt: 2,
                }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        {loading ? (
                            <Skeleton variant="rectangular" width={120} height={80} sx={{mr: 2, borderRadius: 1}}/>
                        ) : (
                            <Card sx={{
                                width: 143,
                                height: 80,
                                mr: 2,
                                overflow: 'hidden',
                                display: {xs: 'none', sm: 'block'}
                            }}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={course?.imagePreview}
                                    alt={course?.title}
                                />
                            </Card>
                        )}
                        <Box>
                            <Typography variant="h5" sx={{fontWeight: 'bold'}}>
                                {loading ? <Skeleton width={180}/> : course?.title}
                            </Typography>
                            <Typography variant="subtitle2">
                                {loading ? <Skeleton
                                    width={140}/> : `${course?.expert.first_name} ${course?.expert.last_name}`}
                            </Typography>
                            <Box sx={{display: 'flex', mt: 1, alignItems: 'center'}}>
                                <Box sx={{display: 'flex', alignItems: 'center', mr: 2}}>
                                    <MenuBook sx={{fontSize: 16, mr: 0.5}}/>
                                    <Typography variant="body2">
                                        {loading ? <Skeleton
                                            width={40}/> : `${course?.lessons.length} ${getLessonText(course?.lessons.length)}`}
                                    </Typography>
                                </Box>
                                <Box sx={{display: 'flex', alignItems: 'center', mr: 2}}>
                                    <AccessTime sx={{fontSize: 16, mr: 0.5}}/>
                                    <Typography variant="body2">
                                        {loading ? <Skeleton
                                            width={60}/> : moment.duration(calculateTotalDuration(course), 'seconds').format('h [час] m [минут] s [секунд]')}
                                    </Typography>
                                </Box>
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                    <Group sx={{fontSize: 16, mr: 0.5}}/>
                                    <Typography variant="body2">
                                        {loading ? <Skeleton width={50}/> : `Участвуют ${course?.participants_count}`}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {loading ? (
                        <Skeleton variant="rectangular" width={100} height={40} sx={{borderRadius: 1}}/>
                    ) : (
                        hasAccess ? (
                            <Box sx={{
                                mt: {xs: 2, sm: 0},
                                mb: {xs: 2, sm: 0},
                                width: {xs: '100%', sm: 'auto'},
                                textAlign: 'center',
                            }}>
                                <Button
                                    variant="contained"
                                    to={`/study/${course?.id}`}
                                    component={Link}
                                    sx={{
                                        textTransform: 'none',
                                        width: {xs: '100%', sm: 'auto'},
                                    }}
                                >
                                    {t('Start learning')}
                                </Button>
                                <Typography variant="body2" sx={{mt: 1}}>
                                    {t('You have access to this course')}
                                </Typography>
                            </Box>
                        ) : (
                            <Box>
                                <Button
                                    variant="contained"
                                    onClick={() => GetAccess(course.id)} sx={{textTransform: 'none'}}
                                    sx={{
                                        textTransform: 'none',
                                        width: {xs: '100%', sm: 'auto'}
                                    }}
                                >
                                    {t('Get access')}
                                </Button>
                                <Typography variant="body2" sx={{mt: 1, textAlign: 'center'}}>
                                    {course?.amount ? (
                                        <>
                                            {Math.floor(course.amount)} {formatCurrency(course.currency)}
                                        </>
                                    ) : (
                                        t('Free')
                                    )}
                                </Typography>
                            </Box>
                        )
                    )}
                </Box>

                {/* Tabs */}
                <TabContext value={value}>
                    <Tabs value={value} sx={{borderBottom: 1, borderColor: 'divider'}}
                          onChange={(event, newValue) => {
                              setValue(newValue);
                          }}>
                        <Tab label="Описание" value="description"/>
                        <Tab label="Уроки" value="lessons"/>
                        <Tab label="Ресурсы" value="3"/>
                    </Tabs>

                    {/* TabPanel */}
                    {loading ? <Skeleton variant="rectangular" height={200} sx={{marginTop: 2}}/> : (
                        <>
                            <TabPanel value="description">
                                <Typography variant="body1" dangerouslySetInnerHTML={{__html: course?.description}}/>
                            </TabPanel>

                            <TabPanel value="lessons">
                                <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                                    {course?.lessons.map((lesson) => (
                                        <ListItemButton
                                            alignItems="center"
                                            sx={{
                                                py: 1,
                                                px: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            <ListItemIcon sx={{mt: 0}}>
                                                <Box
                                                    component="img"
                                                    sx={{width: 80, height: 80, borderRadius: 2}}
                                                    alt={lesson?.title || `${t('Lesson')} ${lesson.sort}`}
                                                    src={lesson?.imagePreview || `https://via.placeholder.com/80x80?text=${t('Lesson')}+${lesson.sort}`}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={lesson.title}
                                                secondary={lesson?.duration && moment.duration(lesson.duration, 'seconds').format('h [час] m [минут] s [секунд]')}
                                                primaryTypographyProps={{variant: 'body1', fontWeight: 'medium'}}
                                                sx={{marginLeft: 2, my: 0}}
                                            />
                                            {!lesson?.has_access && <LockOutlined sx={{ml: 2}}/>}


                                        </ListItemButton>
                                    ))}
                                </Box>
                            </TabPanel>

                            <TabPanel value="3">

                            </TabPanel>
                        </>
                    )}
                </TabContext>

            </Container>
        </>
    )
        ;
};

export default Program;