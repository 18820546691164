import React from 'react';
import {
    Drawer, List, ListItemButton, IconButton, Box, Divider, Typography, Button, ListItemIcon, MenuItem
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';
import {useAuth} from 'contexts/AuthContext';
import {Link} from "react-router-dom";
import LanguageCurrencySelector from "utils/LanguageCurrencySelector";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

const MobileMenu = ({isMenuOpen, setIsMenuOpen, isAuthenticated, toggleMenu, isMobile}) => {
    const {t} = useTranslation();
    const {timeZone, logout, isAppSubdomain} = useAuth();

    const handleLinkClick = () => {
        setIsMenuOpen(false);
    };

    return (
        <Box sx={{ml: 2}}>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleMenu}>
                <MenuIcon/>
            </IconButton>

            <Drawer
                anchor="right"
                open={isMenuOpen}
                onClose={toggleMenu}
                ModalProps={{onBackdropClick: toggleMenu}}
                sx={{'& .MuiDrawer-paper': {width: '90%'}}}
            >
                <Box sx={{display: 'flex', justifyContent: 'flex-end', padding: 1}}>

                    <LanguageCurrencySelector/>

                    <IconButton onClick={toggleMenu}>
                        <CloseIcon/>
                    </IconButton>
                </Box>

                <Divider/>

                <List
                    sx={{marginTop: 2, '& .MuiListItemButton-root': {fontSize: '20px', padding: '8px 16px 8px 32px'}}}>
                    {isAppSubdomain ? (
                        <>
                            <ListItemButton onClick={handleLinkClick}>
                                <Link to={isAppSubdomain ? "https://method.do/home" : "/home"} className="nav-link">{t("Home")}</Link>
                            </ListItemButton>
                            <ListItemButton onClick={handleLinkClick}>
                                <Link to="/matching" className="nav-link">{t("Find a coach")}</Link>
                            </ListItemButton>
                            <ListItemButton onClick={handleLinkClick}>
                                <Link to="/tests" className="nav-link">{t("Tests")}</Link>
                            </ListItemButton>
                            <ListItemButton onClick={handleLinkClick}>
                                <Link to="/programs" className="nav-link">{t("Programs")}</Link>
                            </ListItemButton>
                        </>
                    ) : (
                        <>
                            <ListItemButton onClick={handleLinkClick}>
                                <Link to="/" className="nav-link">{t("Home")}</Link>
                            </ListItemButton>
                            <ListItemButton onClick={handleLinkClick}>
                                <Link to="/forcoach" className="nav-link">{t("For coaches")}</Link>
                            </ListItemButton>
                        </>
                    )}
                    {isAuthenticated ? (
                        <>
                            <ListItemButton onClick={handleLinkClick}>
                                <Link to="/consultations" className="nav-link">{t("My consultations")}</Link>
                            </ListItemButton>
                            <ListItemButton onClick={handleLinkClick}>
                                <Link to="/profile" className="nav-link">{t("Profile settings")}</Link>
                            </ListItemButton>
                            <ListItemButton onClick={handleLinkClick}>
                                <Link className="nav-link" onClick={logout}>
                                    <i className="bx bx-exit fs-lg me-2 "></i>{t("Log out")}
                                </Link>
                            </ListItemButton>
                        </>
                    ) : (
                        <ListItemButton onClick={handleLinkClick}>
                            <a href="https://app.method.do/login" className="nav-link">
                                <i className="bx bx-exit fs-lg me-2 "></i>{t("Login")}
                            </a>
                        </ListItemButton>
                    )}
                    <ListItemButton onClick={handleLinkClick}>
                        <Link to="/quiz" className="btn btn-primary my-3 my-lg-0">
                            <i className="bx bx-user fs-lg me-2 text-white"></i> {t("Find a coach")}
                        </Link>
                    </ListItemButton>

                    <Divider/>

                    <ListItemButton onClick={handleLinkClick} sx={{mt: 2}}>
                        <Link to="https://expert.method.do/home/" className="nav-link">
                            <SwapHorizIcon fontSize="small" sx={{mr: 1}}/> {t("Login as expert")}
                        </Link>
                    </ListItemButton>

                </List>
            </Drawer>
        </Box>
    );
};

export default MobileMenu;
