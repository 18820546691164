import React from 'react';
import logo from 'static/logo/logo-black.svg';
import LanguageSwitcher from "../../utils/LanguageSwitcher";
import TimeZoneSelector from "../../utils/TimeZoneSelector";
import {useTranslation} from 'react-i18next';
import moment from "moment-timezone";
import {useAuth} from 'contexts/AuthContext';
import {Container, Box, Typography, Link, Grid, Divider} from '@mui/material';

const Footer = () => {
    const {t} = useTranslation();
    const {timeZone} = useAuth();

    const TimeDisplay = ({time}) => {
        const {timeZone} = useAuth();

        const moscowTime = moment.tz(time, "HH:mm:ss", "Europe/Moscow");
        const localTime = moscowTime.clone().tz(timeZone).format('HH:mm');

        return (
            <span>{localTime}</span>
        );
    };

    return (
        <Container maxWidth="false" disableGutters >
            <Divider sx={{mb: 4}}/>
            <Container maxWidth="xl">

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={4}>
                        <Box sx={{mb: 2}}>
                            <img src={logo} width="200" alt={t("Method.do logo")}/>
                        </Box>
                        <Typography variant="body2">
                            {t("Method.do - an online service for quick and convenient search and selection of a coach. Our service will help you find experienced coaches who will help you solve any problem and achieve your goals. We provide the opportunity to choose coaches based on various criteria such as experience, education, specialization, and more.")}
                        </Typography>
                        <Box mt={2}>
                            <Link href="/privacy" target="_blank" color="textPrimary" underline="hover" sx={{mr: 1}}>
                                {t("Privacy Policy")}
                            </Link>
                            {t('and')}
                            <Link href="/offer" target="_blank" color="textPrimary" underline="hover" sx={{ml: 1}}>
                                {t("Terms of Service")}
                            </Link>
                        </Box>

                    </Grid>

                    <Grid item xs={12} sm={2}>
                    </Grid>

                    <Grid item xs={12} sm={3} align={{xs: "right", md: "center"}}>
                        <Typography variant="h6" gutterBottom>
                            <Link href="#useful-links" color="textPrimary" underline="hover">
                                {t("Useful Links")}
                            </Link>
                        </Typography>
                        <Box component="nav" aria-label="useful links">
                            <ul style={{listStyle: 'none', padding: 0}}>
                                <li>
                                    <Link href="https://method.do/" color="textPrimary" underline="hover">
                                        {t("Home")}
                                    </Link>
                                </li>
                                <li>
                                    <Link href="https://method.do/forcoach" color="textPrimary"
                                          underline="hover">
                                        {t("For coaches")}
                                    </Link>
                                </li>
                                <li>
                                    <Link href="https://hh.ru/employer/5054360" target="_blank"
                                          color="textPrimary" underline="hover">
                                        {t("Careers")}
                                    </Link>
                                </li>
                            </ul>

                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={3} align={{xs: "right", md: "center"}}>
                        <Typography variant="h6" gutterBottom>{t("Contact Us")}</Typography>
                        <Typography variant="body2">
                            <Link href="mailto:info@method.do" color="inherit" underline="hover">
                                {t("info@method.do")}
                            </Link>
                        </Typography>
                        <Typography variant="body2"><b>+7 495 266-65-34</b></Typography>
                        <Typography variant="body2">
                            {t("Mon-Fri")}: <TimeDisplay time="9:00:00"/> - <TimeDisplay time="15:00:00"/>
                        </Typography>
                        <Box mt={2}>
                            <LanguageSwitcher/>
                            <TimeZoneSelector ButtonClassName="mt-2"/>
                        </Box>
                    </Grid>
                </Grid>


                <Typography variant="caption" display="block" textAlign="center" mt={2} pb={2} pb-lg={0}>
                    {t("Sole Proprietor Afonin Alexander Vladimirovich")} <br/>{t("INN 100100587434")}
                </Typography>
            </Container>
        </Container>
    );
};

export default Footer;
