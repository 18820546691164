// QuizContainer.jsx
import React, {useState, useEffect} from 'react';
import QuestionComponent from './components/QuestionComponent';
import ProgressBar from './components/ProgressBar';
import Navigation from './components/Navigation';
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import './components/style.css';
import ResultsAnimation from "./components/ResultsAnimation";
import CustomAxios from "configs/axiosConfig";
import {useTranslation} from "react-i18next";
import AuthModal from "../../../../components/auth/AuthModal";
import {Container} from "@mui/material";


const QuizContainer = ({questions, resultSteps, finishURL}) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [answers, setAnswers] = useState({});
    const totalQuestions = questions.length;
    const [showResults, setShowResults] = useState(false);
    const [animationFinished, setAnimationFinished] = useState(false);
    const [answersSubmitted, setAnswersSubmitted] = useState(false);
    const {t, i18n} = useTranslation();
    const [submitReady, setSubmitReady] = useState(false);
    const currentQuestionData = questions[currentStep - 1];
    const questionType = currentQuestionData.answerType;

    useEffect(() => {
        if (animationFinished && answersSubmitted) {
            window.location.href = finishURL;
        }
    }, [animationFinished, answersSubmitted]);

    const handleAnswerChange = (questionIndex, answer, checked) => {
        const questionData = questions[questionIndex - 1];
        let newAnswers = {...answers};
        console.log('newAnswers', newAnswers);

        if (questionData.answerType === 'checkbox') {
            const existingAnswers = newAnswers[questionIndex]?.answer || [];
            const updatedAnswers = checked
                ? [...existingAnswers, answer]
                : existingAnswers.filter(item => item !== answer);
            newAnswers[questionIndex] = {
                ...newAnswers[questionIndex],
                question: questionData.questionText,
                field: questionData.field,
                answerType: questionData.answerType,
                answer: updatedAnswers
            };
        } else {
            newAnswers[questionIndex] = {
                ...newAnswers[questionIndex],
                question: questionData.questionText,
                answerType: questionData.answerType,
                field: questionData.field,
                answer: answer
            };
        }

        setAnswers(newAnswers);
    };

    const nextStep = () => {
        console.log('currentStep', currentStep);
        console.log('totalQuestions', totalQuestions);

        if (currentStep < totalQuestions) {
            const newStep = currentStep + 1;
            setCurrentStep(newStep);
        } else {
            setSubmitReady(true);
        }
    };

    useEffect(() => {
        if (submitReady && currentStep > totalQuestions) {
            handleSubmit();
            setSubmitReady(false);
        }
    }, [submitReady, currentStep]);

    const prevStep = () => {
        if (currentStep > 1) {
            const newStep = currentStep - 1;
            setCurrentStep(newStep);

            // Обновляем ответы, удаляя все, что после текущего шага
            const updatedAnswers = {...answers};
            for (let i = newStep + 1; i <= totalQuestions; i++) {
                delete updatedAnswers[i];
            }
            setAnswers(updatedAnswers);
        }
    };

    const handleSubmit = async () => {
        setAnimationFinished(false);
        const answersArray = Object.entries(answers).map(([questionIndex, answerData]) => ({
            question_text: answerData.question,
            answer_type: answerData.answerType,
            field: answerData.field,
            answer: JSON.stringify(answerData.answer),
        }));

        setShowResults(true);
        try {
            const response = await CustomAxios.post('https://api.method.do/api/tests/register/', {answers: answersArray});
            if (response.status === 200) {
                console.log('Успешное сохранение ответов');
                setAnswersSubmitted(true);
            }
        } catch (error) {
            setAnswersSubmitted(true);
            console.error("Ошибка при загрузке данных коуча:", error);
        }
    };

    const [registerModalOpen, setRegisterModalOpen] = useState(false);

    const handleCloseModal = () => {
        setRegisterModalOpen(false);
    };

    const handleOpenModal = () => {
        setRegisterModalOpen(true);
    };

    return (

        <Container className='quiz-container' xs={12} md={6}>

            <AuthModal open={registerModalOpen} handleClose={handleCloseModal} />

            {/* Показываем ProgressBar и QuestionComponent только если опрос не завершен */}
            {!showResults ? (<>
                <ProgressBar currentStep={currentStep} totalSteps={totalQuestions}/>

                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={currentStep}
                        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                        classNames='fade'
                    >
                        <QuestionComponent
                            step={currentStep}
                            onAnswerChange={handleAnswerChange}
                            questionData={currentQuestionData}
                            answers={answers}
                            totalSteps={totalQuestions}
                            nextStep={nextStep}
                        />
                    </CSSTransition>
                </SwitchTransition>

                <Navigation
                    nextStep={nextStep}
                    prevStep={prevStep}
                    currentStep={currentStep}
                    totalSteps={totalQuestions}
                    questionType={questionType}
                    answers={answers[currentStep]}
                    handleSubmit={handleSubmit}
                    isCheckboxQuestion={questionType === 'checkbox'}

                />
            </>) : (

                // Когда опрос завершен, показываем анимацию результатов
                <ResultsAnimation steps={resultSteps} answers={answers} setAnimationFinished={setAnimationFinished}/>)}

        </Container>
    );
};

export default QuizContainer;
