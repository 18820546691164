import React from 'react';

const faqData = [
    {
        id: 'collapseOne',
        question: 'Как отменить или перенести запись на консультацию?',
        answer: 'Вы можете отменить или перенести запись на консультацию в личном кабинете, выбрав желаемое время или отменяя запись. Убедитесь, что делаете это заранее, чтобы избежать штрафов или потери средств. Если вы отмените консультацию позднее чем за 12 часов до начала проведения, с вас будет списана полная стоимость консультации.'
    },
    {
        id: 'collapseTwo',
        question: 'Что произойдет после добавления карты?',
        answer: 'После добавления карты она будет сохранена в вашем аккаунте для удобства будущих платежей. Вы сможете выбирать между сохраненными картами при оплате консультаций.'
    },
    {
        id: 'collapseThree',
        question: 'Когда будет списана сумма за консультацию?',
        answer: 'Сумма за консультацию будет списана сразу после подтверждения бронирования времени консультации. Убедитесь, что на вашей карте достаточно средств для проведения операции.'
    },
    {
        id: 'collapseFour',
        question: 'Как удалить или заменить карту?',
        answer: 'Для удаления или замены карты перейдите в раздел "Мои карты" в вашем личном кабинете. Здесь вы можете удалить старую карту и добавить новую информацию о карте. Ваша предыдущая карта будет безопасно удалена из нашей системы.'
    },
    {
        id: 'collapseFive',
        question: 'Безопасно ли использовать карту на сайте?',
        answer: 'Мы используем передовые методы шифрования и безопасности, чтобы гарантировать безопасность ваших платежных данных. Никакие чувствительные данные вашей карты не сохраняются у нас.'
    }
];


const Faq = () => {
    return (
        <div className="accordion" id="accordionExample">
            {faqData.map((faq, index) => (
                <div className="accordion-item border-0 rounded-3 mb-3" key={faq.id}>
                    <h3 className="accordion-header" id={`heading${index}`}>
                        <button
                            className="accordion-button custom-accordion-button shadow-none rounded-3 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${faq.id}`}
                            aria-expanded="false"
                            aria-controls={faq.id}
                        >
                            {faq.question}
                        </button>
                    </h3>
                    <div
                        id={faq.id}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body pt-0">
                            {faq.answer}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Faq;
