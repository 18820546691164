// Code: NewAppoinment.js
import React, { useContext, useEffect, useState } from 'react';
import {Card, Form, Button, Row, Col} from 'react-bootstrap';
import {Avatar} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {FunctionContext} from 'contexts/FunctionContext';


import { useTranslation } from 'react-i18next';
import customAxios from "configs/axiosConfig";
import Loading from 'components/wrap/Loading';
import Faq from './components/Faq';
import moment from 'moment-timezone';
import { useAuth } from 'contexts/AuthContext';
import PromoCodeInput from "components/Orders/PromoCodeInput";
import CardBrand from "app/account/orders/components/CardBrand";
import {formatCurrency} from "utils/Currency";

import PhoneNumberForm from "./components/PhoneNumberForm";

const NewAppoinment = () => {
    const {newAppointment} = useContext(FunctionContext);
    const {t} = useTranslation();
    const { timeZone } = useAuth();
    const {formatTimeZone} = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [coach, setCoach] = useState(null);
    const [cards, setCards] = useState([]);
    const [cardId, setCardId] = useState('');
    const [SessionCost, setSessionCost] = useState(null);
    const [Discount, setDiscount] = useState(null);
    const [AppointmentDuration, setAppointmentDuration] = useState(null);
    const [SessionCostCurrency, setSessionCostCurrency] = useState(null);
    const [promoCode, setPromoCode] = useState(null);
    const [formattedSelectedTime, setFormattedSelectedTime] = useState('');
    const [convertedCost, setConvertedCost] = useState(SessionCost);
    const [selectedCardCurrency, setSelectedCardCurrency] = useState(SessionCostCurrency);
    const [totalCost, setTotalCost] = useState(SessionCost);
    const {currencyRateUSDRUB, currencyRateRUBUSD} = useContext(FunctionContext);
    const navigate = useNavigate();
    const [cardError, setCardError] = useState(null);
    const [userPhone, setUserPhone] = useState(null);

    const calculateCost = () => {
        let initialCost = SessionCost;
        if (SessionCostCurrency === "USD" && selectedCardCurrency === "RUB") {
            initialCost = Math.round(SessionCost * currencyRateRUBUSD);
        } else if (SessionCostCurrency === "RUB" && selectedCardCurrency === "USD") {
            initialCost = Math.round(SessionCost / currencyRateRUBUSD);
        }

        setConvertedCost(initialCost);
        setTotalCost(initialCost - (Discount || 0));
    };

    useEffect(() => {
        calculateCost();
        console.log('cardId:', cardId);
        console.log('selectedCardCurrency:', selectedCardCurrency);
    }, [SessionCost, Discount, SessionCostCurrency, selectedCardCurrency]);

    useEffect(() => {
        if (newAppointment && newAppointment.coach_id) {
            getCoachForOrder();
            getUserPhone();
            const localTime = moment(newAppointment.selected_time).tz(timeZone).format('DD MMMM, dddd, HH:mm');
            setFormattedSelectedTime(localTime);
        }
    }, [newAppointment, timeZone]);

    const getCoachForOrder = async () => {
        setLoading(true);
        try {
            const response = await customAxios.get(`https://api.method.do/api/coach/getcoachfororder/${newAppointment.coach_id}/`, {
                params: {
                    selected_time: newAppointment.selected_time,
                },
            });
            if (response.data.error) {
                setError(response.data.error);
            } else {
                setCoach(response.data.coach);
                setSessionCost(response.data.coach.session_cost);
                setSessionCostCurrency(response.data.coach.session_cost_currency);
                setCards(response.data.cards);
                setAppointmentDuration(response.data.appointment_duration)

                if (response.data.cards.length > 0) {
                    setSelectedCardCurrency(response.data.cards[0].currency);
                    setCardId(response.data.cards[0].card_id);
                } else {
                    setCardId('newRUB');
                    setSelectedCardCurrency('RUB');
                }

            }
        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError(error.response?.data?.message || error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const getUserPhone = async () => {
        try {
            const response = await customAxios.get('https://api.method.do/api/user/getinfo/', {
                params: {
                    phone: true,
                },
            });

            setUserPhone(response.data.phone);

        } catch (error) {
            console.error('Caught an error:', error);
        }
    }

    if (!newAppointment || !newAppointment.coach_id || !newAppointment.selected_time) {
        return (
            <div className={'col-12 col-md-4 mx-auto'}>
                <Card className={'mb-3'}>
                    <Card.Body>
                        <h4>{t('Error')}</h4>
                        <p>{t('Please select a coach and time slot first')}</p>
                    </Card.Body>
                </Card>
            </div>
        );
    }

    const handleCardChange = (cardId, cardCurrency) => {
        setCardError(null);
        setSelectedCardCurrency(cardCurrency);
        setCardId(cardId);
        calculateCost();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            console.log('newAppointment:', newAppointment);
            const response = await customAxios.post('https://api.method.do/api/matching/createappointment/', {
                coach_id: newAppointment.coach_id,
                selected_time: newAppointment.selected_time,
                card_id: cardId,
                currency: selectedCardCurrency,
                promo_code: promoCode,
            });
            if (response.data.status === 201) {
                navigate('/consultations?status=error&message=appointment_already_exists');
                console.log('Appointment already exists');
            }

            if (response.data.error) {
                setError(response.data.error.message || "Неизвестная ошибка"); // Указываем на message или другой строковый ключ
            } else if (response.data.session_url) {
                localStorage.removeItem('newAppointment');
                window.location.href = response.data.session_url;
            } else {
                // Обработка успешного создания заказа без необходимости перенаправления
                localStorage.removeItem('newAppointment');
                navigate('/consultations');
                console.log('Appointment created successfully');
            }
        } catch (error) {
            console.error('Caught an error:', error);
            // Проверяем, есть ли информация об ошибке и что это строка
            if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error.message || "Ошибка при обработке запроса");
            } else {
                setError("Произошла неожиданная ошибка");
            }
        } finally {
            setLoading(false);
        }
    }


    return (
        <div className={'col-12 col-md-4 mx-auto'}>
            {loading && <Loading message={t('Loading...')} />}

            {error && (<div className={'alert alert-danger'} role="alert"><p>{t('Error')}</p>{error}</div>)}

            {coach ? (
                <>
                <Card className={'mb-3'}>
                    <Card.Body>
                        <Card.Title>
                            {cards.length > 0 ? (t( 'Book a consultation' )) : (t( 'Add a card to make an appointment' ))}
                        </Card.Title>
                        <Form>

                            {/* Фотография и информация о сессии */}
                            <div className="d-flex align-items-center mb-3">
                                <div className="me-3">
                                    <Avatar
                                        src={
                                            coach.profile_photo && !coach.profile_photo.includes('noavatar.jpeg')
                                                ? coach.profile_photo.startsWith('https://method.do/images')
                                                    ? `https://api.${coach.profile_photo.slice(8)}`
                                                    : `https://api.method.do/images/${coach.profile_photo}`
                                                : coach.profile_photo
                                        }
                                        alt={`${coach.expert.first_name} ${coach.expert.last_name}`}
                                        className="rounded-circle"
                                        width="48"
                                        height="48"
                                        sx={{objectFit: 'cover'}}
                                    />
                                </div>
                                <div>
                                    <div className="fw-bold">{coach.expert.first_name} {coach.expert.last_name}</div>
                                    {!AppointmentDuration ? (
                                        <div className="text-muted">{t("A specialist will write to you within 24 hours of receiving your application and you can arrange a time for a consultation.")}</div>
                                    ) : (
                                        <div className="text-muted">{formattedSelectedTime} {formatTimeZone(timeZone)}</div>
                                    )}

                                </div>
                            </div>

                            {/* Указать номер телефона пользователя */}
                            {!AppointmentDuration &&
                                <PhoneNumberForm userPhone={userPhone} setUserPhone={setUserPhone}/>
                            }


                            {/* Радиокнопки для выбора карты */}
                            <Form.Group className={`mb-3`}>
                                <Form.Label className={'mb-3'}>{t('Payment card')}</Form.Label>
                                <div className={`${cardError ? "alert alert-danger" : ""}`}>
                                    {cards && cards.map(card => (

                                        <Form.Group key={card.card_id} controlId={`formCardRadio${card.card_id}`}
                                                    className={'position-relative align-items-center'}>
                                            <div
                                                className="end-0 position-absolute top-50"
                                                style={{transform: 'translateY(-50%)'}}
                                            >
                                                <Form.Check
                                                    type="radio"
                                                    id={`formCardRadio${card.card_id}`}
                                                    name="card_id"
                                                    value={card.card_id}
                                                    data-currency="RUB"
                                                    onChange={() => handleCardChange(card.card_id, card.currency)}
                                                    isInvalid={cardError}
                                                />
                                            </div>
                                            <label htmlFor={`formCardRadio${card.card_id}`}
                                                   className="d-flex align-items-center w-100">
                                                <Row className="align-items-center g-0 w-100">
                                                    <Col style={{flex: '0 0 auto', width: '50px'}}
                                                         className={'pe-3'}>
                                                        <CardBrand type={card.card_type} width={50}/>
                                                    </Col>
                                                    <Col style={{flex: '1 1 auto'}} className={'w-auto'}>
                                                        <div className="fw-bold">{card.masked_number} <span
                                                            className={'text-secondary'}>{formatCurrency(card.currency)}</span>
                                                        </div>
                                                        <div
                                                            className="fs-sm text-body">{t('Expiration date')} {card.expiration_date}</div>
                                                    </Col>
                                                </Row>
                                            </label>
                                            <hr className={'my-2'}/>
                                        </Form.Group>

                                    ))}
                                    <Form.Group controlId="newRUB"
                                                className={'position-relative align-items-center'}>
                                        <div
                                            className="end-0 position-absolute top-50"
                                            style={{transform: 'translateY(-50%)'}}
                                        >
                                            <Form.Check
                                                type="radio"
                                                id="newRUB"
                                                name="card_id"
                                                value="newRUB"
                                                data-currency="RUB"
                                                onChange={() => handleCardChange('newRUB', 'RUB')}
                                                isInvalid={cardError}
                                            />
                                        </div>
                                        <label htmlFor="newRUB" className="d-flex align-items-center w-100">
                                            <Row className="align-items-center g-0 w-100">
                                                <Col style={{flex: '0 0 auto', width: '50px'}} className={'pe-3'}>
                                                    <CardBrand type="MIR ADVANCED" width={50}/>
                                                </Col>
                                                <Col style={{flex: '1 1 auto'}} className={'w-auto'}>
                                                    <div
                                                        className="fw-bold">{t('Russian Federation or MIR card')}</div>
                                                    <div className="fs-sm text-body">{t('Add a new card')}</div>
                                                </Col>
                                            </Row>
                                        </label>
                                        <hr className={'my-2'}/>
                                    </Form.Group>

                                    <Form.Group controlId="newUSD"
                                                className={'position-relative align-items-center'}>
                                        <div className="end-0 position-absolute top-50"
                                             style={{transform: 'translateY(-50%)'}}>
                                            <Form.Check
                                                type="radio"
                                                id="newUSD"
                                                name="card_id"
                                                value="newUSD"
                                                data-currency="USD"
                                                onChange={() => handleCardChange('newUSD', 'USD')}
                                                isInvalid={cardError}
                                            />
                                        </div>
                                        <label htmlFor="newUSD" className="d-flex align-items-center w-100">
                                            <Row className="align-items-center g-0 w-100">
                                                <Col style={{flex: '0 0 auto', width: '50px'}} className={'pe-3'}>
                                                    <CardBrand width={50}/>
                                                </Col>
                                                <Col style={{flex: '1 1 auto'}} className={'w-auto'}>
                                                    <div
                                                        className="fw-bold">{t('Other countries or USD card')}</div>
                                                    <div className="fs-sm text-body">{t('Add a new card')}</div>
                                                </Col>
                                            </Row>
                                        </label>
                                    </Form.Group>
                                </div>
                            </Form.Group>


                            {/* Стоимость сессии */}
                            <Form.Group className="mb-3">
                                <Form.Label>{t('Session cost')}, {!AppointmentDuration ? '55' : ` ${AppointmentDuration}`} {t('min')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={`${SessionCost} ${SessionCostCurrency === 'RUB' ? '₽' : '$'}`}
                                    readOnly
                                />
                            </Form.Group>

                            {/* Промокод */}
                            <PromoCodeInput SessionCost={SessionCost} Currency={SessionCostCurrency} setDiscount={setDiscount} setPromoCode={setPromoCode}/>

                            {/* Итоговая стоимость */}
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <div>{t('Total')}</div>
                                <div className="fw-bold">{totalCost} {selectedCardCurrency === "USD" ? '$' : '₽'}</div>
                            </div>

                            {/* Кнопка добавления карты и записи */}
                            <Button variant="primary" type="submit" className="w-100" onClick={handleSubmit} disabled={loading}>
                                {cards.length > 0 ? (t( 'Book a consultation' )) : (t( 'Add a card and book a consultation' ))}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
                <Faq/>
                </>
            ) : null}

        </div>
    );
};

export default NewAppoinment;
