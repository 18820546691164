// app.js
import React, { Suspense, lazy, useEffect } from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import  history  from 'configs/history';
import Loading from 'components/wrap/Loading';
import { useAuth } from 'contexts/AuthContext.js';

// обертка страницы
import PageWrapper from 'components/wrap/PageWrapper';

// Авторизация
import Login from './main/pages/auth/login';
import EmailCodeVerification from './main/pages/auth/emailCodeVerification';
import PhoneCodeVerification from './main/pages/auth/phoneCodeVerification';

// страницы app
import Quiz from './app/pages/tests/quiz';
import Tests from './app/pages/tests/tests';
import Home from './app/pages/home/home';
import Consultations from './app/account/consultations/consultations';
import History from './app/account/history/history';
import Faq from './app/account/faq/faq';
import Profile from './app/account/profile/profile';
import AccountPayment from './app/account/orders/accountpayment';
import Orders from './app/account/orders/Orders';
import Order from './app/account/orders/order';
import NewOrder from './app/pages/newOrder/NewOrder';
import HistoryPayment from './app/account/orders/historypayment';
import PaymentOrder from './app/account/orders/PaymentOrder';
import Chat from './app/pages/messages/chat.js';
import Coach from './app/pages/matching/coachView/coach';
import Matching from './app/pages/matching/matching';
import NotFound from './components/404/NotFoundPage';
import NewAppoinment from "./app/pages/matching/order/NewAppoinment";
import TestZoom from "./app/pages/consultation/components/TestZoom";
import WelcomePage from './main/pages/auth/welcomepages/WelcomePage';
import AboutYou from './main/pages/auth/welcomepages/AboutYou';
import ConsultationSession from './app/pages/consultation/ConsultationSession';

// courses
import Programs from './app/pages/courses/Programs/Programs';
import Program from './app/pages/courses/Program/Program';
import Study from './app/pages/courses/Study/Study';

// системные
import AuthRedirect from "./app/system/AuthRedirect";

// main

const Main = lazy(() => import('main/pages/main/Main'));
const ForCoach = lazy(() => import('main/pages/forcoach/ForCoach'));
const Updates = lazy(() => import('main/pages/Update/Updates'));
const Offer = lazy(() => import('main/pages/offer'));
const Privacy = lazy(() => import('main/pages/privacy'));


// lead
const LeadCourse = lazy(() => import('./lead/pages/course/LeadCourse'));

// user pages
const UserPage = lazy(() => import('client/users/userpage/UserPage'));
const Packages = lazy(() => import('client/users/packages/Packages'));
const PackageDetails = lazy(() => import('client/users/packagedetails/PackageDetails'));

// app pages


const ProtectedRoute = ({ children, isProtected = true, subdomain = true }) => {
    const { isAuthenticated } = useAuth();

    if (subdomain) {
        // Если пользователь не аутентифицирован и маршрут не защищен, перенаправляем на /login
        if (!isAuthenticated && isProtected) {
            return <Navigate to="/login" replace />;
        }
    }
    return children;
};


const createProtectedRoutes = (routes) => {
    return routes.map((route, index) => (
        <Route
            key={index}
            path={route.path}
            element={
                <ProtectedRoute isProtected={route.isProtected} subdomain={route.subdomain}>
                    {route.element}
                </ProtectedRoute>
            }
        />
    ));
};



const App = () => {
    const isAppSubdomain = window.location.hostname.startsWith('app.');
    const isLeadSubdomain = window.location.hostname.startsWith('lead.');

    const Redirect = ({ to }) => {
        let navigate = useNavigate();
        useEffect(() => {
            navigate(to);
        });
        return null;
    };

    const routes = [
        { path: "/", element: <Suspense fallback={<Loading />}><Main/></Suspense>, isProtected: false, subdomain: false },
        { path: "/forcoach", element: <Suspense fallback={<Loading />}><ForCoach/></Suspense>, isProtected: false, subdomain: false },
        { path: "/updates", element: <Suspense fallback={<Loading />}><Updates/></Suspense>, isProtected: false, subdomain: false },
        { path: "/quiz", element: <PageWrapper><Quiz/></PageWrapper>, isProtected: false },
        {path: "/offer", element: <Suspense fallback={<Loading />}><PageWrapper><Offer/></PageWrapper></Suspense>, isProtected: false},
        {path: "/privacy", element: <Suspense fallback={<Loading />}><PageWrapper><Privacy/></PageWrapper></Suspense>, isProtected: false},

        { path: "/p/:username", element: <Suspense fallback={<Loading />}><UserPage /></Suspense>, isProtected: false, subdomain: false},
        { path: "/p/:username/packages", element: <Suspense fallback={<Loading />}><Packages /></Suspense>, isProtected: false, subdomain: false},
        { path: "/p/:username/packages/:id", element: <Suspense fallback={<Loading />}><PackageDetails /></Suspense>, isProtected: false, subdomain: false},

    ];

    const leadRoutes = [
        { path: "/start/:id", element: <LeadCourse />, isProtected: false },
    ];

    const appRoutes = [

        // account showMenu={false} showFooter={false} -- не показывать меню и футер
        // padding={0} -- убрать отступы
        // fluid={true} -- растянуть на всю ширину
        // isProtected: false -- доступные без аутинфикации
        {path: "/offer", element: <Suspense fallback={<Loading />}><PageWrapper><Offer/></PageWrapper></Suspense>},
        {path: "/offer", element: <Suspense fallback={<Loading />}><PageWrapper><Privacy/></PageWrapper></Suspense>},
        {path: "/", element: <Redirect to="/home" />},
        {path: "/home", element: <PageWrapper fluid={true} padding={0}><Home/></PageWrapper>},
        {path: "/accountpayment", element: <PageWrapper padding={0}><AccountPayment/></PageWrapper>},
        {path: "/consultations", element: <PageWrapper padding={0}><Consultations/></PageWrapper>},
        {path: "/faq", element: <PageWrapper padding={0}><Faq/></PageWrapper>},
        {path: "/history", element: <PageWrapper padding={0}><History/></PageWrapper>},
        {path: "/historypayment", element: <PageWrapper padding={0}><HistoryPayment/></PageWrapper>},
        {path: "/profile", element: <PageWrapper padding={0}><Profile/></PageWrapper>},
        {path: "/consultationsession/:sessionID", element: <PageWrapper><ConsultationSession/></PageWrapper>},
        {path: "/test/zoom", element: <PageWrapper><TestZoom/></PageWrapper>},
        {path: "/newappoinment", element: <PageWrapper bgLight={true} fluid={true}><NewAppoinment/></PageWrapper>},
        {path: "/orders", element: <PageWrapper><Orders/></PageWrapper>},
        {path: "/order/:id", element: <PageWrapper><Order/></PageWrapper>},
        {path: "/checkout", element: <PageWrapper><NewOrder/></PageWrapper>},
        {path: "/payment/:id", element: <PageWrapper><PaymentOrder/></PageWrapper>},
        {path: "/tests", element: <PageWrapper><Tests/></PageWrapper>},
        {path: "/chat", element:<PageWrapper padding={0}><Chat/></PageWrapper>},
        {path: "/welcomepage", element: <WelcomePage/>},
        {path: "/aboutyou", element: <AboutYou/>},
        {path: "/study/:id", element: <PageWrapper showMenu={false} showFooter={false} padding={0} fluid={true}><Study/></PageWrapper>},

        // inverseRoutes (доступные без аутинфикации)
        {path: "/matching", element: <PageWrapper fluid={true}><Matching/></PageWrapper>, isProtected: false},
        {path: "/coach/:id", element: <PageWrapper bgLight={true} fluid={true} padding={0} isProtected={false}><Coach/></PageWrapper>, isProtected: false},
        {path: "/quiz", element: <PageWrapper><Quiz/></PageWrapper>, isProtected: false},
        {path: "/programs", element: <PageWrapper fluid={true} padding={0} ><Programs/></PageWrapper>, isProtected: false},
        {path: "/program/:id", element: <PageWrapper showMenu={false} padding={0} fluid={true}><Program/></PageWrapper>, isProtected: false},
        {path: "/login", element: <PageWrapper><Login/></PageWrapper>, isProtected: false},
        {path: "/email_code_verification", element: <PageWrapper><EmailCodeVerification/></PageWrapper>, isProtected: false},
        {path: "/phone_code_verification", element: <PageWrapper><PhoneCodeVerification/></PageWrapper>, isProtected: false},
        {path: "/authredirect", element: <AuthRedirect/>, isProtected: false},

    ];


    // Создание защищенных маршрутов
    const selectedRoutes = isAppSubdomain ? appRoutes : isLeadSubdomain ? leadRoutes : routes;

    const protectedRoutes = createProtectedRoutes(selectedRoutes);

    return (
        <div className="d-flex flex-column min-vh-100">
            <Routes history={history} >
                {protectedRoutes}

                <Route path="*" element={<PageWrapper><NotFound/></PageWrapper>}/>
            </Routes>
        </div>
    );
};

export default App;
