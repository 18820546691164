import React, {useState, useEffect, useContext} from 'react';
import {
    Box,
    Typography,
    Button,
    Divider,
    ListItemIcon,
    ListItemText,
    ListItemButton,
    Alert,
    Radio
} from '@mui/material';
import {CreditCard, LockOutlined, SupportAgent} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {FunctionContext} from 'contexts/FunctionContext';
import customAxios from "configs/axiosConfig";
import PromoCodeInput from "components/Orders/PromoCodeInput";
import CardBrand from "app/account/orders/components/CardBrand";
import {formatCurrency} from "utils/Currency";
import {Col, Form, Row} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import {ListItem} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";


const OrderReviewPage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [cardLoading, setCardLoading] = useState(false);
    const [productLoading, setProductLoading] = useState(false);

    const [error, setError] = useState(null);
    const [cardError, setCardError] = useState(null);

    const [cards, setCards] = useState([]);
    const [cardId, setCardId] = useState('');

    const [Amount, setAmount] = useState(null)
    const [Currency, setCurrency] = useState(null);

    const [totalCost, setTotalCost] = useState(Amount);
    const [selectedCardCurrency, setSelectedCardCurrency] = useState(Currency);

    const [Discount, setDiscount] = useState(null);
    const [promoCode, setPromoCode] = useState(null);
    const [convertedCost, setConvertedCost] = useState(Amount);

    const {currencyRateRUBUSD, product} = useContext(FunctionContext);
    const [item, setItem] = useState(null);


    const getCards = async () => {
        setCardLoading(true);

        try {
            const response = await customAxios.get(`https://api.method.do/api/cards/getcards/`);

            if (response.data.error) {
                setError(response.data.error);
            } else {
                setCards(response.data);

                if (response.data.length > 0) {
                    setSelectedCardCurrency(response.data[0].currency);
                    setCardId(response.data[0].card_id);
                } else {
                    setCardId('newRUB');
                    setSelectedCardCurrency('RUB');
                }

            }
        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError(error.response?.data?.message || error.message);
            }
        } finally {
            setCardLoading(false);
        }
    };
    const getProduct = async () => {
        setProductLoading(true);

        try {
            const response = await customAxios.get(`https://api.method.do/api/order/getproduct/`, {
                params: {
                    product_id: product.id,
                    product_type: product.type
                }
            });

            if (response.data) {
                setItem(response.data);
                setAmount(response.data.amount);
                setCurrency(response.data.currency);
            }

        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError(error.response?.data?.message || error.message);
            }
        } finally {
            setProductLoading(false);
        }
    };

    useEffect(() => {
        getCards();
        getProduct();
    }, []);

    useEffect(() => {
        getProduct();
    }, [product]);

    const calculateCost = () => {
        let initialCost = Amount;
        if (Currency === "USD" && selectedCardCurrency === "RUB") {
            initialCost = Math.round(Amount * currencyRateRUBUSD);
        } else if (Currency === "RUB" && selectedCardCurrency === "USD") {
            initialCost = Math.round(Amount / currencyRateRUBUSD);
        }

        setConvertedCost(initialCost);
        setTotalCost(initialCost - (Discount || 0));
    };

    useEffect(() => {
        calculateCost();
    }, [Amount, Discount, Currency, selectedCardCurrency]);

    const handleCardChange = (cardId, cardCurrency) => {
        setCardError(null);
        setSelectedCardCurrency(cardCurrency);
        setCardId(cardId);
        calculateCost();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        console.log({
            card_id: cardId,
            currency: selectedCardCurrency,
            promo_code: promoCode,
        });

        if (!cardId) {
            setCardError("Выберите карту");
            setLoading(false);
            return;
        }

        try {
            const response = await customAxios.post('https://api.method.do/api/checkout/pay/', {
                product_id: product.id,
                product_type: product.type,
                card_id: cardId,
                currency: selectedCardCurrency,
                promo_code: promoCode,
            });

            if (response.data.status === 201) {
                navigate('/consultations?status=error&message=appointment_already_exists');
            } else if (response.data.error) {
                setError(response.data.error.message || "Неизвестная ошибка");
            } else if (response.data.session_url) {
                window.location.href = response.data.session_url;
            } else {
                navigate('/consultations');
            }
        } catch (error) {
            console.error('Caught an error:', error);
            setError(error.response?.data?.error?.message || "Произошла неожиданная ошибка");
        } finally {
            setLoading(false);
        }
    };


    return (
        <Box sx={{mx: 'auto', maxWidth: 600}}>

            <Typography variant="h5" sx={{mt: 3}}>
                {t("Review order details")}
            </Typography>

            {error && <Alert severity="error" sx={{mt: 1}}>{t(error)}</Alert>}

            {/* Информация о продукте */}
            {productLoading ? (
                <Skeleton
                    sx={{my: 2}}
                    variant="rectangular"
                    width="100%"
                    height={80}
                />
            ) : (
                <>
                    <ListItem
                        alignItems="center"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            px: 0,
                            pt: 2,
                            pb: 3,
                        }}
                    >

                        <ListItemIcon sx={{mt: 0}}>
                            <Box
                                component="img"
                                sx={{height: 80, borderRadius: 2}}
                                alt={item?.title}
                                src={item?.imagePreview}
                            />
                        </ListItemIcon>

                        <ListItemText
                            primary={item?.title}
                            secondary={`Доступ к программе`}
                            primaryTypographyProps={{variant: 'body1', fontWeight: 'medium'}}
                            sx={{marginLeft: 2, my: 0}}
                        />

                        <Typography variant="body1" sx={{ml: 2, my: 0}}>
                            {item?.amount} {formatCurrency(item?.currency)}
                        </Typography>

                    </ListItem>
                </>
            )}


            {/* Выбор карты */}
            <Typography variant="h6" sx={{mb: 2}}>
                {t('Payment card')}
            </Typography>

            <Box sx={{mb: 3}}>
                <Form.Group>
                    <div className={`${cardError ? "alert alert-danger" : ""}`}>

                        {cardError && (<Alert severity="error">{cardError}</Alert>)}

                        {cardLoading ? (
                            Array.from(new Array(3)).map((item, index) => (
                                <Skeleton
                                    key={index}
                                    variant="rectangular"
                                    width="100%"
                                    height={50}
                                    sx={{mb: 1}}
                                />
                            ))
                        ) : (
                            <>
                                {cards && cards.map(card => (
                                    <div key={card.card_id}>
                                        <ListItemButton onClick={() => handleCardChange(card.card_id, card.currency)}>

                                            <ListItemText primary={
                                                <Row className="align-items-center g-0 w-100">
                                                    <Col style={{flex: '0 0 auto', width: '50px'}} className={'pe-3'}>
                                                        <CardBrand type={card.card_type} width={50}/>
                                                    </Col>
                                                    <Col style={{flex: '1 1 auto'}} className={'w-auto'}>
                                                        <div className="fw-bold">
                                                            {card.masked_number}
                                                            <span
                                                                className={'text-secondary'}>{formatCurrency(card.currency)}</span>
                                                        </div>
                                                        <div
                                                            className="fs-sm text-body">{t('Expiration date')} {card.expiration_date}</div>
                                                    </Col>
                                                </Row>
                                            }/>
                                            <ListItemIcon>
                                                <Radio
                                                    checked={cardId === card.card_id}
                                                    value={card.card_id}
                                                    name="card_id"
                                                    inputProps={{'aria-label': card.card_id}}
                                                />
                                            </ListItemIcon>
                                        </ListItemButton>
                                        <Divider/>
                                    </div>
                                ))}
                            </>
                        )}

                        <ListItemButton onClick={() => handleCardChange('newRUB', 'RUB')}>
                            <ListItemText primary={
                                <Row className="align-items-center g-0 w-100">
                                    <Col style={{flex: '0 0 auto', width: '50px'}} className={'pe-3'}>
                                        <CardBrand type="MIR ADVANCED" width={50}/>
                                    </Col>
                                    <Col style={{flex: '1 1 auto'}} className={'w-auto'}>
                                        <div className="fw-bold">{t('Russian Federation or MIR card')}</div>
                                        <div className="fs-sm text-body">{t('Add a new card')}</div>
                                    </Col>
                                </Row>
                            }/>
                            <ListItemIcon>
                                <Radio
                                    checked={cardId === 'newRUB'}
                                    value="newRUB"
                                    name="card_id"
                                    inputProps={{'aria-label': 'newRUB'}}
                                />
                            </ListItemIcon>
                        </ListItemButton>

                        <Divider/>

                        <ListItemButton onClick={() => handleCardChange('newUSD', 'USD')}>
                            <ListItemText primary={
                                <Row className="align-items-center g-0 w-100">
                                    <Col style={{flex: '0 0 auto', width: '50px'}} className={'pe-3'}>
                                        <CardBrand width={50}/>
                                    </Col>
                                    <Col style={{flex: '1 1 auto'}} className={'w-auto'}>
                                        <div className="fw-bold">{t('Other countries or USD card')}</div>
                                        <div className="fs-sm text-body">{t('Add a new card')}</div>
                                    </Col>
                                </Row>
                            }/>
                            <ListItemIcon>
                                <Radio
                                    checked={cardId === 'newUSD'}
                                    value="newUSD"
                                    name="card_id"
                                    inputProps={{'aria-label': 'newUSD'}}
                                />
                            </ListItemIcon>
                        </ListItemButton>
                    </div>
                </Form.Group>
            </Box>


            {/* Промокод */}
            <PromoCodeInput SessionCost={Amount} Currency={Currency}
                            setDiscount={setDiscount} setPromoCode={setPromoCode}/>

            {/* Итоговая стоимость */}
            <Divider/>
            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                <Typography variant="h6">{t("Total")}</Typography>
                <Typography variant="h6">{totalCost} {formatCurrency(selectedCardCurrency)}</Typography>
            </Box>


            {/* Кнопка добавления карты и записи */}
            <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={loading}
                fullWidth
                sx={{mt: 3}}
            >
                {t('Create order')}
            </Button>

            <Box sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    {t('Purchase with confidence')}
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <CreditCard sx={{ mr: 1 }} />
                    <Box>
                        <Typography variant="subtitle1">{t('Refund / Cancellation policy')}</Typography>
                        <Typography variant="body2">{t('Feel safe. 14 days for a Full refund')}</Typography>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <LockOutlined sx={{ mr: 1 }} />
                    <Box>
                        <Typography variant="subtitle1">{t('Privacy and Security')}</Typography>
                        <Typography variant="body2">{t('All Personal Information you submit is Encrypted and Secure')}</Typography>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <SupportAgent sx={{ mr: 1 }} />
                    <Box>
                        <Typography variant="subtitle1">{t('Easy support & Help')}</Typography>
                        <Typography variant="body2">
                            {t('Need help? Our support team is available at any time')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default OrderReviewPage;