//ChatWindow.js
import React, {useState, useEffect, useRef} from 'react';
import ChatHeader from './ChatHeader';
import ChatMessages from './ChatMessages';
import ChatFooter from './ChatFooter';
import {useTranslation} from 'react-i18next';
import CustomAxios from 'configs/axiosConfig';
import Grid from '@mui/material/Unstable_Grid2';
import {useAuth} from 'contexts/AuthContext';
import {useWebSocket} from 'contexts/WebSocketContext';
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";


const ChatWindow = ({selectedContact}) => {
    const {t} = useTranslation();
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const {user} = useAuth();
    const { websocket, currentMessageWS } = useWebSocket();
    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (currentMessageWS && currentMessageWS.action === 'message_read') {
            const updatedMessages = messages.map(msg => {
                if (msg.id === currentMessageWS.message_id) {
                    return {...msg, is_read: true};
                }
                return msg;
            });
            setMessages(updatedMessages);
        }
    }, [currentMessageWS, messages, setMessages]);

    useEffect(() => {
        if (currentMessageWS && currentMessageWS.action === 'new_message') {
            setMessages(prevMessages => [...prevMessages, currentMessageWS]);
        }
    }, [currentMessageWS]);

    useEffect(() => {
        if (selectedContact) {
            console.log('selectedContact:', selectedContact);
            setMessages([]);
            setLoading(true);

            CustomAxios.get(`https://api.method.do/messages/conversation/${selectedContact.id}/`)
                .then(response => {
                    setMessages(response.data.messages);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching messages:', error);
                    setLoading(false);
                });

            if (websocket.current) {
                const message = {
                    action: 'open_chat',
                    contact_id: selectedContact.id,
                };
                websocket.current.send(JSON.stringify(message));
            }
        }
    }, [selectedContact, websocket]);


    return (
        <Grid item xs={12} md={8} sx={{borderRight: '1px solid #e2e5f1'}}>
            <div className="card h-100 border-0 pb-3 d-flex flex-column justify-content-between flex-grow-1 h-100">
                {selectedContact && <ChatHeader contact={selectedContact} loading={loading}/>}

                    {!websocket.current ? (
                        <Box style={{ height: '4px' }}>
                            <LinearProgress color="secondary" />
                            {open && (
                                <Alert
                                    severity="warning"
                                    onClose={() => setOpen(false)}
                                >
                                    {t('Connection to the server failed. Please reload the page.')}
                                </Alert>
                            )}
                        </Box>
                    ) : null}

                {selectedContact && messages &&
                    <ChatMessages messages={messages} setMessages={setMessages} currentUserId={user.id}/>
                }
                {selectedContact && <ChatFooter contact={selectedContact} messages={messages} setMessages={setMessages}
                                                websocket={websocket}/>}
            </div>
        </Grid>
    );
};

export default ChatWindow;
