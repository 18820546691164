import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useAuth } from 'contexts/AuthContext.js';

const WebSocketContext = createContext(null);

export const useWebSocket = () => {
    return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }) => {
    const websocket = useRef(null);
    const [messagesWS, setMessagesWS] = useState([]); // Состояние для хранения сообщений
    const [currentMessageWS, setCurrentMessageWS] = useState(null); // Состояние для хранения последнего сообщения
    const { isAuthenticated } = useAuth();


    useEffect(() => {
        // Подключение к WebSocket
        const token = localStorage.getItem('access_token');

        
        if (token && isAuthenticated) {
            websocket.current = new WebSocket(`wss://api.method.do:8001/ws/channels/chat/?token=${token}`);

            websocket.current.onopen = () => {
                console.log('WebSocket Connected');
            };

            websocket.current.onmessage = (event) => {
                const message = JSON.parse(event.data);
                console.log('Received message:', message);
                setCurrentMessageWS(message);
            };

            websocket.current.onclose = event => {
                console.log('WebSocket Disconnected', event);
            };

            websocket.current.onerror = error => {
                console.error('WebSocket Error', error);
            };

        }


        return () => {
            if (websocket.current) {
                websocket.current.close();
            }
        };
    }, [isAuthenticated]);

    return (
        <WebSocketContext.Provider value={{ websocket, messagesWS, currentMessageWS }}>
            {children}
        </WebSocketContext.Provider>
    );
};
